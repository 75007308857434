﻿
[v-cloak] {
    display: none
}

.pickupmap-viewport {
    height: 650px;
    width: 100%;
    position: relative;

    @media screen and (max-width: $screen-xs-max) {
        height: 100%;
        width: 100%;
    }

    .g-map {
        height: 650px;
        width: 100%;
    }
}

.map-top-margin {
    margin-top: 95px;
}

.select-store {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #000000;
}

.map-overlay {
    height: 614px;
    width: 356px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 18px;
    margin-right: 30px;

    @media screen and (max-width: $screen-xs-max) {
        display: none;
    }
}

.location-mobile-list {
    @media screen and (min-width: $screen-sm-min) {
        display: none;
    }

    margin-bottom: 37px;
}

.address-type {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-top:30px;
}

.address-detail {
    margin-top: 15px;

    .address {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
    }
}

.map{
    margin-top: 90px;
}

.location-item {
    align-items: center;
    display: flex;
    margin-left: 8px;
    margin-right: 7px;
    margin-top: 17px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 12px;
    position: relative;

    &.selected-location {
        border: 1px solid #CCCCCC;
    }

    .location-input {
        height: 32px;
        margin: 0px 7px;
        padding: 0px;
        width: 32px;
    }

    .location-item-container {
        width: 100%;

        .location-title {
            color: #333333;
            font-family: Roboto;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0.2px;
            margin-bottom: 10px;
            text-align: left;

            .location-title-primary {
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                line-height: 16px;

                & > span {
                    &:first-child {
                        overflow: hidden;
                    }

                    &.location-distance {
                        color: #333333;
                        font-family: Roboto;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0.2px;
                        margin-left: 1rem;
                        position: relative;
                        white-space: nowrap;
                    }
                }
            }
        }

        .location-detail {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.1px;
            color: #333333;
        }

        .get-directions {
            margin-left: auto;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.1px;
            color: #3C76C3 !important;
        }

        .time-warning {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #222222;
            text-align: left;
            margin-left: 13px;
        }
    }

    .warn-slot-unavailable {
        @include flex-row;
        background-color: $color-light-yellow;
        height: 76px;
        padding: 12px;

        .warning-icon {
            @include flex-column-center;
            width: 2% !important;
            margin-right: 12px;

            > img {
                @include icon-img-style;
                width: 20px;
                height: 10px;
            }
        }

        .warn-text {
            @include flex-column-center;
            @include span-text-style;
        }
    }    
}
