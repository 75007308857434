.headline-2 {
    color: #222222;
    font-family: "Roboto Condensed";
    font-size: 55px;
    font-weight: bold;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
    &.padding-bottom-0 {
        padding-bottom: 0;
    }
    @media screen and (max-width: $screen-xs-max) {
        color: #333333;
        font-family: ROBOTO CONDENSED;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 34px;
        text-align: center;
    }
}
.top-bar-right .cart.button.small.expand .text {
    color: #FFFFFF;
}
.headline-3 {
    color: #333333;
    font-family: Roboto;
    font-size: 34px;
    font-weight: bold;
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
    @media screen and (max-width: $screen-xs-max) {
        color: #333333;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 22px;
        text-align: center;
    }
}
.no-top-padding {
    padding-top:0% !important;
}
.headline-4 {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    text-align: center;
}
.headline-5 {
    color: #333333;
    font-family: Roboto;
    font-size: 34px;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 42px;
    text-align: center;
    @media screen and (max-width: $screen-xs-max) {
        color: #333333;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 22px;
        text-align: center;
    }
}
.text-email-chkbox {   
    color: #333333;
    font-family: Roboto;
    letter-spacing: 0.1px;
    text-align:left;
    .margin-checkbox{
        margin-left:0px;
    }
}
.text {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
}
.smallText {
    color: #333333;
    font-family: Roboto;
    font-size: 11px;
}
.subtitle {
    color: #333333;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
}
.forgot-password {
    color: #3C76C3;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.2px;
    text-align:left;
}
.body-copy {
    color: #333333;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-top: 48px;
}
.checkout-step {
    @media screen and (min-width: $screen-sm-min) {
        display: none !important;
    }
    margin-bottom: -20px;
    margin-top: 25px;
}
.current-step {
    font-weight: bold;
}
.jobsite-selected-address {
    font-weight: bold;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    margin-bottom: 5px;
}
.mouse-text-bold {
    color: #333333;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 18px;
    font-weight:bold;
}
.mouse-text {
    color: #333333;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 18px;
}
.mouse-text-link {
    color: #008545;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 22px;
    letter-spacing: 0.228571px;    
}
.changed-entered-address {
    color: #C03A3A
}
.changed-suggested-address {
    color: #008545
}
.left-text {
    text-align: left;
    @media screen and (max-width: $screen-xs-max) {
        padding-bottom:20px;
    }
}
.text-list-item {
     margin-left: 13px;
     float: left;
}