@charset 'utf-8';

//@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed:400,700');
@import 'Content/styles/scss/_settings.scss';
@import 'foundation';
// @import 'motion-ui';

// import special libraries
@import 'libraries/css/font-awesome';
@import 'libraries/css/all';
@import 'libraries/css/bootstrap/variables.scss';
@import 'libraries/css/bootstrap/responsive-visibility.scss';
@import 'libraries/css/bootstrap/responsive-utilities.scss';
@import 'libraries/css/bootstrap/nav-divider.scss';
@import 'libraries/css/bootstrap/vendor-prefixes.scss';
@import 'libraries/css/bootstrap/navs.scss';
@import 'libraries/css/bootstrap/dropdowns.scss';


@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
/*@include foundation-tooltip;*/
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;


// The structural organization of the scss files are loosely based
// on http://bradfrost.com/blog/post/atomic-web-design/ where
//
// elements = atoms
// components = molecules
// modules = organisms
// pages = templates/pages

// Base Elements
@import 'elements/links';
@import 'elements/lists';
@import 'elements/headings';
@import 'elements/typography';
@import 'elements/buttons';
@import 'elements/forms';
@import 'elements/badges';
@import 'elements/reveal';
@import 'elements/sbkendoui';

// Components
@import 'components/global-styles';
@import 'components/spinner';
@import 'components/block-grid';
@import 'components/breadcrumb';
@import 'components/pagination';
@import 'components/banners';
@import 'components/responsive-table';
@import 'components/find-equipment';
@import 'components/full-width';
@import 'components/related-product';
@import 'components/callouts';
@import 'components/cart-addition';
@import 'components/prefooter-cta';
@import 'components/tooltips';
@import 'components/utility-bar';
@import 'components/catalog';
@import 'components/location-popup';
@import 'components/commandcenter-branded-pageheader';
@import 'components/commandcenter-account-selector';
@import 'components/jquery-auto-complete';
@import 'components/_shadowbox.scss';
@import 'components/commandcenter/_sbkendogrid.scss';
@import 'components/commandcenter/location-searhbar.scss';
@import 'components/_curbside-banner.scss';
@import 'components/_snackbar.scss';

// Slick slider styles
@import 'components/slick';
@import 'components/slick-theme';
@import 'components/equipment-slider';

// Modules Blocks
@import 'modules/header';
@import 'modules/checkout-header';
@import 'modules/footer';
@import 'modules/mobile-nav';
@import 'modules/daterangepicker';

// Page Specific
@import 'pages/global';
@import 'pages/components';
@import 'pages/home';
@import 'pages/login-wrapper';
@import 'pages/product-category';
@import 'pages/product-detail';
@import 'pages/checkout';
@import 'pages/locations';
@import 'pages/command-center';
@import 'pages/surcharge';
@import 'pages/quote';
@import 'pages/commandcenter';
@import 'pages/my-account';
@import 'pages/credit-application.scss';
@import 'pages/shopping/viewcart.scss';
@import 'pages/shopping/checkout-global-styles.scss';
@import 'pages/shopping/checkout-where.scss';
@import 'pages/shopping/checkout-when.scss';
@import 'pages/shopping/checkout-disney.scss';
@import 'pages/shopping/checkout-duke.scss';
@import 'pages/shopping/checkout-rollover.scss';
@import 'pages/shopping/checkout-complete.scss';
@import 'pages/shopping/toolflex.scss';
@import 'pages/commandcenter/global-styles.scss';
@import 'pages/commandcenter/quickrent.scss';
@import 'pages/commandcenter/equipment-actions.scss';
@import 'pages/commandcenter/liveequipmentmap.scss';
@import 'pages/commandcenter/jobsitesmap.scss';
@import 'pages/commandcenter/account-management.scss';
@import 'pages/commandcenter/dashboard.scss';
@import 'pages/commandcenter/invoices.scss';
@import 'pages/commandcenter/account-activity.scss';
@import 'pages/commandcenter/reports.scss';
@import 'pages/commandcenter/rollovers.scss';
@import 'pages/commandcenter/account-summary.scss';
@import 'pages/commandcenter/myprofile.scss';
@import 'pages/commandcenter/liveequipmentmap-breadcrumbs.scss';
@import 'pages/commandcenter/mylogin-change-password.scss';
@import 'pages/payments';
@import 'pages/browser-not-allowed.scss';

// Redesign
@import 'redesign/month-selector.scss';
@import 'redesign/text.scss';
@import 'redesign/inputs.scss';
@import 'redesign/buttons.scss';

// Features
@import 'Features/Shared/Common.scss';
@import 'Features/Shared/Checkbox.scss';
@import 'Features/Checkout/_CheckoutLayout.scss';
@import 'Features/Shared/loading-placeholder.scss';
@import 'Features/Cart/CartTotals.scss';
@import 'Features/Cart/CartItems.scss';
@import 'Features/Cart/LocationModalSelector.scss';
@import 'Features/Cart/MiniCartSubTotal.scss';
@import 'Features/Cart/MiniCart.scss';
@import 'Features/Checkout/LocationSelector.scss';
@import 'Features/Checkout/YourCartPage.scss';
@import 'Features/Checkout/QuoteReview.scss'; 
@import 'Features/Checkout/CompletePageFooter.scss';
@import 'Features/Checkout/CartFooter.scss';
@import 'Features/Checkout/NewJobsiteModal.scss';
@import 'Features/Checkout/Navigation/checkout-location-selector.scss';
@import 'Features/Shared/LoginModal.scss';
@import 'Features/Shared/JobsiteSelector.scss';

@import 'Features/Checkout/CheckoutRentalDates.scss';
@import 'Features/Checkout/CheckoutPickupAddress.scss';
@import 'Features/Checkout/ReservationCompletePage.scss';
@import 'Features/Checkout/ReservationReview.scss';
@import 'Features/Cart/CartItemsReview.scss';
@import 'Features/Shared/TooltipModal.scss';
@import 'Features/Checkout/CheckoutPayment.scss';
@import 'Features/Checkout/CheckoutVerifyAddressModal.scss';