#findEquipment-autocomplete
{
    margin: 0;
}
#find-equipment-input.isloading
{
    background-image: url('/content/img/preloader.gif');
    background-size: 15px 15px;
    background-position: right center;
    background-position-x: 99%;
    background-repeat: no-repeat;
}
#find-equipment-input-container
{
    width: calc(100% - 180px);
    float: left; 
    padding: 2px 5px;
}
#find-equipment-btn-container
{
    width: 180px;
    float: left;
}
@media only screen and (max-width: 450px) {
    #find-equipment-input-container
    {
        width: 100%;
        float: left; 
        padding: 0;
        margin-bottom:15px;
    }
    #find-equipment-btn-container
    {
        width: 100%;
        text-align:center;
    }
}
.centeralign-to-parent
{
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}
.drilldown .mobile-nav-pane a.button {
    
    background: #007a39;
}
.drilldown .is-drilldown-submenu a {
    padding-left: 2.5rem;
    padding-right: 0px;
}
.drilldown .is-drilldown-submenu .js-drilldown-back a {
    display: inline-block;
    padding-top: 12px;
}
.drilldown .is-drilldown-submenu {
    background: #fafafa;
}

