﻿#ViewCart {
    .ShadowBoxContent {
        padding-bottom: 4px;
    }
    .ShadowBox {
        padding-bottom: 0px;
        @media (max-width: $screen-xs-max) {
            margin-bottom: 8px;
        }
    }
    .Title {
        display: block;
        font-size: 1.6em;
        margin-bottom: 15px;
    }
    .EmptyCart {
        height: 335px;
    }
    .Items {
        .ItemImage {
            float: left;
            margin-right: 10px;
        }
        .ItemDetails {
            margin-bottom: 16px;
            .ItemTitle {
                display: block;
                @media (max-width: $screen-sm-max) {
                    padding-top: 2px;
                }
                font-size: 1.4em;
            }
            .Rates {
                float: left;
                line-height: 150%;
                label {
                    display: inline-block;
                    width: 70px;
                    font-size: 1em;
                    margin-bottom: 0px;
                }
                .Rate {
                    display: inline-block;
                    width: 60px;
                    font-size: 1em;
                    text-align: right;
                }
            }
            .Price {
                float: left;
                width: 270px;
                margin-left: 10px;
                input {
                    float: left;
                    width: 35px;
                    text-align: right;
                    background-color: #e6e6e6;
                    font-size: .9em;
                    border: 1px solid #d0ccc7;
                    margin: 0px 0px 4px 0px;
                    padding: 8px;
                    overflow: visible; /* IE Hack to fix horizontal padding */
                    border-radius: 5px;
                }
                img {
                    float: left;
                    margin-top: 4px;
                    margin-left: 10px;
                }
                label {
                    float: left;
                    margin-left: 10px;
                    margin-top: 8px;
                    display: block;
                    width: 110px;
                    font-size: 1.6em;
                    text-align: right;
                }
            }
            .Quantity {
                input {
                    float: left;
                    width: 56px;
                    text-align: right;
                    font-size: .9em;
                    margin: 0px 8px 0px 0px;
                    padding: 8px;
                    overflow: visible; /* IE Hack to fix horizontal padding */
                }
                img {
                    float: left;
                    margin-top: 0px;
                    margin-left: 10px;
                }
                .QuantityTopContainer {
                    text-align: right;
                    white-space: nowrap;
                    a {
                        float: left;
                    }
                }
                .toolflex-add-button {
                    margin-left: 0px;
                    margin-top: 6px;
                }
            }
        }
    }
    .Buttons {
        margin: 15px 0px 30px 0;
        text-align: center;
        @include breakpoint(medium) {
            text-align: right;
        }
        > a:first-child {
            margin-right: 10px;
        }
        img {
            margin-left: 6px;
            cursor: pointer;
        }
    }
}
