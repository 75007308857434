﻿table#ReportingMain {
    margin-bottom: 0px;
    width:initial;
    tbody {
        border: 0px;
        tr {
            border-bottom: 0px;
        }
        td { 
            padding: 3px;
        }
    }
}
table.BlackRoundCorners {
    tbody {
        border: 0px;
        tr {
            border-bottom: 0px;
        }
        td {
            padding: 3px;
        }
    }
    input[type="text"], select {
        padding: 4px;
        height: initial;
        display: inline-block;
        margin-bottom: 0px;
        font-size: .8em;
    }
}
#ReportingMain {
    table {
        margin-bottom: 0px;
        width: initial;
    }
    table tbody td {
        padding: 3px;
    }
    table tbody {
        border: 0px;
    }
    table tbody tr {
        border-bottom: 0px;
    }
    .activeTab {
        display: inline-block;
        position: relative;
        background-color: #F4F4F4;
        padding-left: 16px;
        padding-right: 16px;
        z-index: 2;
        top: 1px;
        border-radius: 4px 4px 0px 0px;
        border-color: #DFDFDF;
        border-width: 1px;
        border-bottom-style: none;
        border-top-style: solid;
        border-right-style: solid;
        border-left-style: solid;
        font-size: 14px;
        padding-top: 6px;
        padding-bottom: 6px;
        color: #505050;
    }
    input[type="text"] {
        display: inline-block;
    }
    .inactiveTab {
        display: inline-block;
        position: relative;
        background-color: #D6D6D6;
        padding-left: 14px;
        padding-right: 14px;
        z-index: 2;
        top: 1px;
        border-radius: 4px 4px 0px 0px;
        border-color: #CECECE;
        border-width: 1px;
        border-bottom-style: none;
        border-top-style: solid;
        border-right-style: solid;
        border-left-style: solid;
        font-size: 14px;
        padding-top: 4px;
        padding-bottom: 5px;
        color: #868686;
        -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0px -3px 10px -4px inset;
        box-shadow: rgba(0, 0, 0, 0.5) 0px -3px 10px -4px inset;
        cursor: pointer;
    }
    .greyBox {
        background-color: rgb(230, 230, 230);
        border-radius: 6px;
        display: block;
    }
    .ajax__tab_tab {
        @extend .inactiveTab;
        margin-right: 4px;
        outline: none;
    }
    .ajax__tab_active .ajax__tab_tab {
        margin-right: 4px;
        @extend .activeTab;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .ajax__tab_panel {
        margin-bottom: 4px;
        margin-top: 0px;
        border-radius: 0px 4px 4px 4px;
        border-color: #dfdfdf;
        border-style: solid;
        position: relative;
        z-index: 2;
        border-width: 1px;
        background-color: #f4f4f4;
        padding: 12px;
        width: 736px;
        min-height: 341px;
    }
    .ajax__tab_header {
        height: 31px;
    }
    .lblDescription {
        vertical-align: top;
        padding: 10px;
        font-size: 12px;
        @extend .greyBox;
        margin-right: 8px;
        margin-bottom: 14px;
    }
    #lblDescription {
        @extend .lblDescription;
    }
    #lblReportTitle {
        font-size: 17px;
        font-weight: bold;
        color: rgb(111, 111, 111);
    }
    #divSelectReportOptions {
        @extend .greyBox;
        padding: 10px;
        position: absolute;
        font-size: 12px;
    }
    .reportLink {
        color: $brand-green;
        font-weight: bold;
        text-decoration: none;
    }
    .ReportFieldsTableContainer {
        margin-top: 30px;
    }
    .reportFilterName {
        display: inline-block;
        width: 130px;
        text-align: right;
    }
    .reportFilterInputContainer {
        display: inline-block;
    }
    .reportFilterContainer {
        margin-bottom: 8px;
        li {
            display: inline;
        }
        ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;
        }
    }
    td.ReportingMainContent {
        margin-top: 20px;
        display: block;
    }
}
