﻿.surcharge {
    span, p, a, li {
        color: $cc-leftnav-color;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
    }
    &.top-banner-container {
        align-items: center;
        background-color: $surcharge-top-banner-bg-color;
        display: flex;
        padding: 0.7rem 1.5rem;
        position: relative;
        width: 100%;

        &.hidden {
            display: none;
        }

        span, a {
            color: $cc-leftnav-color;
            font-family: "Roboto";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
        }

        a {
            font-weight: 600;
            text-decoration: none;
        }

        .left-container {
            display: flex;

            .text-container {
                display: flex;

                .surcharge-alert-icon-svg {
                    margin-right: 1rem;
                }

                span {
                    margin-right: 1rem;
                }
            }

            a {
                margin-top: 0;
            }
        }

        .surcharge-close-icon-svg {
            margin-left: auto;

            &:hover {
                cursor: pointer;
            }
        }
    }
    &.payment-method-refresh-banner {
        background-color: $surcharge-payment-refresh-method-banner-bg-color;
        padding: 1.5rem;

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            svg {
                margin-right: .5rem;
            }
            span {
                font-size: 18px;
                line-height: 20px;
            }
        }
        p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: .5rem;
        }
        ol {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 0;
        }
    }
    &.digital-wallet-banner-container {
        display: block;
        max-width: 85%;
        margin: 40px auto -20px;

        .payment-method-refresh-banner {
            margin: 0;
        }
    }
    &.payment-method-refresh-modal-container {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;

        &.hidden {
            display: none;
        }
        .modal-backdrop {
            background-color: rgba(0, 0, 0, .5);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
        .modal-container {
            background: white;
            box-shadow: 2px 2px 13px rgba(51, 51, 51, 0.3);
            max-width: 700px;
            padding: 5rem 1rem;
            position: relative;
            width: 95%;

            .surcharge-close-icon-svg {
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 10px;
            }
            .surcharge-modal-alert-icon {
                height: 70px;
                margin: 0 auto;
                width: 100%;
            }
            .title {
                color: #333333;
                font-family: 'Roboto';
                font-size: 1.5rem;
                font-weight: 700;
                margin: 1rem 0;
                text-align: center;
            }
            .content {
                color: #333333;
                font-family: 'Roboto';
                font-size: 1rem;
                text-align: center;
            }
            .actions {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 2rem;
                width: 100%;

                a {
                    transition: all .5s;
                    &:first-of-type {
                        background: #008545;
                        color: #FFFFFF;
                        font-family: 'Roboto Condensed';
                        font-size: 1rem;
                        height: 3rem;
                        line-height: 3rem;
                        max-width: 232px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        width: 50%;

                        &:hover {
                            background: #00502a;
                        }
                    }
                    &:last-of-type {
                        border-bottom: 1px solid transparent;
                        color: #008545;
                        font-size: 1rem;
                        margin-top: 1rem;
                        text-decoration: none;

                        &:hover {
                            color: #00502a;
                            border-bottom: 1px solid #00502a;
                        }
                    }
                }
            }
        }
    }

    // Media queries
    // This specific media was made by media query for payment methods screen
    @media screen and (max-width: $screen-sm-min) {
        &.top-banner-container {
            align-items: flex-start;
            padding: 1rem;

            .left-container {
                flex-direction: column;
            }

            .left-container {
                width: calc(100% - 12px);

                a {
                    align-self: flex-start;
                    display: flex;
                    margin-top: 1rem;
                }

                .text-container {
                    display: block;

                    .surcharge-alert-icon-svg {
                        margin-right: 0.3rem;
                    }

                    span {
                        margin-right: 0;
                    }
                }
            }

            .surcharge-alert-icon-svg {
                margin-bottom: -4px;
                margin-right: 0;
            }

            .surcharge-close-icon-svg {
                margin-top: 4px;
                margin-left: 0;
            }
        }
    }
    @media screen and (min-width: $screen-sm-min) {
        &.payment-method-refresh-modal-container {
            .modal-container {
                padding: 5rem 7rem;
            }
        }
    }
    @media screen and (min-width: 64em) {
        &.digital-wallet-banner-container {
            max-width: 80%;
        }
    }
    @media screen and (min-width: 991px) {
        &.payment-method-refresh-banner {
            margin: 24px;
            border-radius: 7px;
        }
    }
}
