.text-input, .month-input, .jobsite-input, .account-input, .state-input, .cart-text-input {
    border: 1px solid #999999;
    background-color: #FFFFFF;
    color: #333333;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
}

.text-input, .month-input, .jobsite-input, .account-input, .state-input, .cart-text-input, .checkout .button {
    box-sizing: border-box;
    height: 50px;
    font-family: Roboto;
}
.checkout {
    .button {
        background-color: #007a39;
        color: #fff;
        font-size:16px;
        padding:0px;
        width:150px;
    }
}
.input-with-action-wrapper {
    position: relative;
}
.text-input, .state-input {
    margin-bottom: 8px;
}
.text-area-input {
    box-sizing: border-box;
    height: 129px;
    border: 1px solid #999999;
    background-color: #FFFFFF;
    resize: none;
}
.month-input {
    cursor: pointer !important;
}
.jobsite-input {
    cursor: pointer !important;
}
.dropdown-arrow {
    position: absolute;
    top: 22px;
    right: 16px;
    pointer-events: none;
}

.dropdown-arrow-up {
    position: absolute;
    top: 22px;
    right: 16px;
    transform: rotate(180deg) scale(1);
    -ms-transform: rotate(180deg) scale(1);
    pointer-events: none;
}

.jobsite-selector-dropdown {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #CCCCCC !important;
    border: 1px solid #999999;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 2;
    margin-top: -18px;
    overflow-y: auto;
}

.jobsites-container {
    max-height: 455px;
    overflow-y: auto;
}

.jobsites-container-with-scroll {
    max-height: 130px;
    overflow-y: auto;
}

.jobsite-search {
    padding: 14px 13px 10px 13px;
    border-bottom: 1px solid #EEEEEE;
    text-align: left;
}
.jobsite-search-input {
    width: 95%;
    margin-left: 30px;
    margin-bottom: 0px;
    border: 0px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    height: 26px;
    padding: 0px;
}
.jobsite-search-input:focus {
    border: 0px;
    outline: none;
}
.jobsite-searchglass {
    float: left;
    position: absolute;
    top: 19px;
}
.jobsite-active {
    margin: 13px 13px 6px 13px;
    text-align: left;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    font-weight: bold;
}
.jobsite-address {
    margin-left: 20px;
    color: #BBBBBB;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
}
.jobsite-plus {
    float: right;
}
.jobsite-add {
    padding: 13px;
    text-align: left;
    font-weight: bold;
    border-top: 1px solid #CCCCCC;
    cursor: pointer;
}
.jobsite-more {
    text-align: left;
    margin-left: 13px;
    margin-top: 5px;
    color: #008545;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}
.jobsite-more::after {
    content: '';
    width: 6px;
    height: 6px;
    border-bottom: 1px solid #008545;
    border-left: 1px solid #008545;
    position: relative;
    top: -3px;
    display: inline-block;
    -ms-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
    margin-left: 6px;
    cursor: pointer;
}
.jobsite-less {
    text-align: left;
    margin-left: 13px;
    margin-top: 5px;
    color: #008545;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}
.jobsite-less::after {
    content: '';
    width: 6px;
    height: 6px;
    border-bottom: 1px solid #008545;
    border-left: 1px solid #008545;
    position: relative;
    display: inline-block;
    -ms-transform: rotate(135deg) scale(1);
    transform: rotate(135deg) scale(1);
    margin-left: 6px;
    cursor: pointer;
}
.jobsite-item {
    margin-left: 13px;
    text-align: left;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    cursor: pointer;
}
.account-item {
    margin-left: 14px;
    text-align: left;
    color: #222222;
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    cursor: pointer;
}
.account-name {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    font-weight: normal;
}
.account-search {
    padding: 14px 13px 10px 13px;
    border-bottom: 1px solid #EEEEEE;
    text-align: left;
}
.account-search-input {
    width: 90%;
    margin-left: 30px;
    margin-bottom: 0px;
    border: 0px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    height: 26px;
    padding: 0px;
}
.account-search-input:focus {
    border: 0px;
    outline: none;
}
.account-searchglass {
    float: left;
    position: absolute;
    top: 19px;
}
.account-search-section {
    margin: 13px 13px 6px 13px;
    text-align: left;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    font-weight: bold;
}
.account-selector-dropdown {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #CCCCCC !important;
    border: 1px solid #999999;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 2;
    margin-top: -18px;
    padding-bottom: 10px;

    &.punchout-account-selector {
        margin-top: -18px !important;
        max-height: 170px;
        overflow-y: auto;
    }
}
.dropdown-leftopen {
    left: auto !important;
    right: 0 !important;
    margin-top: 40px !important;
    width: 325px !important;
    padding: 21px 24px 19px 12px !important;
    margin-right: -15px !important;
}
.state-selector-dropdown {
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #CCCCCC !important;
    border: 1px solid #999999;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 2;
    margin-top: -18px;
    max-height: 250px;
    overflow-y: scroll;
}
.state-input {
    caret-color: transparent;
    cursor: pointer !important;
}
.state-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #333333;
    opacity: 1; /* Firefox */
}
.state-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #333333;
}
.state-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #333333;
}
.state-header {
    font-weight: bold;
    text-align: left;
    margin: 8px;
}
.state-item {
    text-align: left;
    margin-left: 8px;
    margin-top: 2px;
    cursor: pointer;
}
.radio-input {
    display: block;
    position: relative;
    padding-left: 42px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 23px;
    padding-top: 6px;
    margin-right: 30px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        width: 32px;
        background-color: #FFFFFF;
        border-radius: 50%;
        border: 1px solid #999999;
    }
    input:checked ~ .checkmark {
        background-color: #FFFFFF;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    .checkmark:after {
        top: 7px;
        left: 7px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #008545;
    }
}
.cart-text-input {
    padding: 1%;
}
.txt-padding {
    padding-right: 1%;
    padding-left: 1%;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C03A3A;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
}
.cartchk {
    background: green !important;
    color: red !important;
}
.box input::-webkit-input-placeholder {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
}
/* Options wrapper (toggled by isOpen) */
.options {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 3;
    width: 100%;
    margin-top: -10px;
    background: #fff;
    border: 1px solid #DDDDDD;
    max-height: 284px;
    overflow-y: scroll;
    text-indent: 10px;
    box-shadow: -10px 0 20px 5px rgba(0,0,0,0.08);
    padding-top:5px;
}
.wide-options {
    width: 150px !important;
    margin-top: 5px !important;
    border: 1px solid #DDDDDD !important;
}
.scrollbar {
    float: left;
    max-height: 300px;
    overflow-y: scroll;
    height:auto;
}
#style-time::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    background-color: #F5F5F5;
}
#style-time::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
#style-time::-webkit-scrollbar-thumb {
    background-color: #b3acac;
}
.scrollable {
    max-height:100vh !important;
    overflow-y:hidden;
    border:hidden !important;
    z-index: 179 !important;
    width:150px !important;
    left:5px !important;
    margin-top:10px !important;
    position:relative !important;
}
/* Hover state */
.option:hover {
    font-weight: bold;
    color: #008545;
}
.option-highligh {
    font-weight: bold;
    color: #008545 !important;
}
/* Reset last child for a nice layout */
.option:last-child {
    border-bottom: none;
}
/* My dropdown */
.my-dropdown {
    cursor: pointer;
    position: relative;
    padding: 0 1% 1% 1%;
}
/* Option */
.option {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    padding:4px;
}
.option-space {
    letter-spacing:0.25px !important;
    line-height:35px !important;
}
.text-field {
    height: 20px;
    width: 77px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
}
/* Hover state */
.option:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
/* Reset last child for a nice layout */
.option:last-child {
    border-bottom: none;
}
/* Transition */
.fade-enter-active, .fade-leave-active {
    transition: all .25s ease-out;
}
.fade-enter, .fade-leave-active {
    opacity: 0;
    transform: translateY(-30px);
}
.account-selector {
    height: 50px;
    width: 100%;
    border: 1px solid #999999;
    background-color: #FFFFFF !important;
    background: #FFFFFF !important;
}
.account-selector .k-dropdown-wrap {
    background: #FFFFFF;
}
.account-selector .k-dropdown-wrap .k-input {
    margin-top: 10px;
}
.invalid-text-input {
    border: 1px solid #C03A3A;
}
.invalid-info-text {
    height: 23px;
    color: #C03A3A;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    text-align: left;

    &.fix-padding {
        margin-bottom: 5px;
        margin-top: -2px;
    }

    @media (max-width: 640px) {
        font-size: 11px;
    }
}
.centered-content {
    text-align:center !important;
}
.textbox-cart {
    box-sizing: border-box;
    height: 33px;
    width: 150px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 23px;
    border: 0.5px solid #DDDDDD;
    cursor: default !important;
}
.disabled-textbox {
    opacity:0.3;
}
.button-adjacent-form {
     width:75%;
     float: left;
     margin-right: 10px;
}
.no-jobsites {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #333333;
    margin: 10px;
    text-align: left;
}