h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    overflow-wrap: break-word;
    @extend .hd-condensed;
    a {
        text-decoration: none;
        color: $black;
        &.text-link {
            @extend .text-link;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
h1,h2 {
    letter-spacing: -0.025em;
}
h3,h4,h5,h6 {
    letter-spacing: -0.005em;
}
.hd-1 {
    @extend h1;
}
.hd-2 {
    @extend h2;
}
.hd-3 {
    @extend h3;
}
.hd-4 {
    @extend h4;
}
.hd-5 {
    @extend h5;
}
.hd-6 {
    @extend h6;
}
.hd-1, .hd-2, .hd-3, .hd-4, .hd-5, .hd-6 {
    @extend .hd-condensed;
}
a.hd-1, a.hd-2, a.hd-3, a.hd-4, a.hd-5, a.hd-6 {
    text-decoration: none;
    color: $black;
}
.hd-condensed {
    // Condensed font -- Fix for Firefox 
    font-stretch: condensed;
}
.hd-bold {
    font-family: "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
    font-stretch: normal;
}
.hd-underline, .hd-underline-light {
    position: relative;
    border-bottom: 2px solid $dark-gray;
    padding-bottom: 15px;
    margin-top: rem-calc(24);
    > span {
        font-family: $body-font-family;
        font-weight: normal;
    }
}
.hd-underline {
    margin-top: rem-calc(36);
    margin-bottom: 1rem;
}
.hd-underline-light {
    border-color: $light-gray;
    line-height: 1.4;
    padding-bottom: 9px;
}
.lower {
    text-transform: none;
}