.find-equipment_wrap {
    .title {
        margin-bottom: $grid-spacing/2;
        @include breakpoint(medium) {
            margin-bottom: $grid-spacing - 22px;
        }
    }
}
.input-wrap {
    margin-bottom: $grid-spacing/2;
    @include breakpoint(large) {
        width: 22%;
        padding-left: $grid-spacing/4;
        padding-right: $grid-spacing/4;
    }
}
.find-equipment_form {
    form {
        > .row {
            margin: 0 auto;
            max-width: 540px;
            @include breakpoint(large) {
                display: flex;
                justify-content: center;
                max-width: 960px;
            }
            &:not(:last-child) {
                margin-bottom: 0;
            }
            &:last-child {
                .input-wrap {
                    margin-bottom: 0;
                }
            }
        }
    }
    input, select {
        margin-bottom: 0;
    }
    .input-large {
        @include breakpoint(large) {
            width: 25%;
        }
        @include breakpoint(xlarge) {
            min-width: 280px;
        }
    }
    .columns {
        > .button {
            margin-bottom: 0;    
        }
    }
}
.input-location-wrap {
    .location-btn {
        text-decoration: none;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 0;
        top: 20px;
    }
    & > label {
        position: relative;
        input {
            background-image: url(/content/img/pin-green.svg);
            background-repeat: no-repeat;
            background-size: 18px 25px;
            padding-left: 40px;
            background-position: 9px 50%;
        }
    }
}
