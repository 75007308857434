﻿.header-steps-container{
    margin-left: 0;
}
.headline-2 {
    padding-bottom: 0;
    padding-top: 3%;
}
.d-flex{
    display: flex;
}
.justify-content-center{
    justify-content: center;
}
.checkout-complete-container {

    > .row {
        > .columns {
            .success-banner {
                text-align: center;
                width: 120px;
                height: 120px;

                @media only screen and (max-width: 767px) {
                    width: 100px;
                    height: 100px;
                    margin: 50px 0 10px 0;
                }
            }

            .reservation-header {
                text-align: center;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 34px;
                line-height: 34px;
                letter-spacing: 0.25px;
                padding-top: 2%;
                padding-bottom: 1%;
                color: #333333;

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.2px;
                        margin-top: 5px;
                    }
                }
            }

            .reservation-subheader {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 25px;
                text-align: center;
                letter-spacing: 0.1px;
                color: #333333;

                @media only screen and (max-width: 767px) {
                    line-height: 18px;
                    margin: 25px 0 10px 0;
                }

                .account-message {
                    width: 65%;
                    padding-top: 0.8rem;

                    a{
                        font-weight: 600;
                        font-size: 18px;
                    }

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        padding-top: 0;
                    }
                }
            }

            .seperator {
                border-bottom: 1px solid #979797 !important;
                padding-top: 1%;
                margin-bottom: 3%;
            }

            .explore {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 18px;
                text-align: right;
                letter-spacing: 0.2px;
                color: #008545;
            }
        }
    }
}

#reservation-complete-page-instance {
    .show-only-in-desktop {
        display: block;

        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    .show-only-in-mobile {
        display: none;

        @media only screen and (max-width: 767px) {
            display: block;

            .headline-2 {
                font-weight: bold;
                font-size: 40px;
                line-height: 30px;
                margin-bottom: 10px;
            }
        }
    }

    .review-header {
        @media only screen and (max-width: 767px) {
            font-weight: bold;
            font-size: 24px;
            letter-spacing: 0.25px;
            text-align: left;
        }
    }
}

