// Pre-footer CTA
.pre-footer_cta-wrap {
    text-align: center;
    background: $fake-white;
    margin-top: $grid-spacing/2;
    @include breakpoint(medium) {
        margin-top: $grid-spacing;
    }
    & + &,
    .overlay-banner + &,
    .content-banner + &,
    .share_story_wrap + & {
        margin-top: 0;
    }
    &.alternate {
        background: $off-white;
    }
}
.pre-footer_cta-content {
    max-width: $narrow-max-width;
    margin-top: $grid-spacing/2;
    @include breakpoint(xlarge) {
        &.pre-footer_cta-expand {
            max-width: 1440px;
        }
    }
}
.pre-footer_cta-item {
    @include grid-column(12);
    float: none;
    max-width: 520px;
    margin: 0 auto $grid-spacing;
    @include breakpoint(medium) {
        float: left;
        @include grid-column(6);
    }
    @include breakpoint(large) {
        max-width: 720px;
    }
    @include breakpoint(xlarge) {
        .pre-footer_cta-expand & {
            @include grid-column(4);
        }
    }
    .info {
        background: $white;
        padding: $grid-spacing;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0 6px 10px 0px rgba(0,0,0,0.02);
        img {
            width: 100%;
        }
    }
    &:last-child {
        margin-bottom: 0;
        &:not(:first-child) {
            float: none;
            @include breakpoint(medium) {
                float: right;
            }
        }
    }
}
