$hr-color: rgba(255,255,255,.2);
// Pre-footer Link Menu 
.pre-footer {
    background: $primary-color;
    padding-top: map_get($section-spacing-top, small);
    padding-bottom: map_get($section-spacing-bottom, small);
    @include breakpoint(large) {
        padding-top: map_get($section-spacing-top, large);
        padding-bottom: map_get($section-spacing-bottom, large);
    }
    hr {
        border-color: $hr-color;
    }
    // Styling Footer Accordions
    .accordion {
        background: none;
    }
    .accordion-title {
        padding: 0;
        background: none;
    }
    .accordion-content {
        padding: 0;
        background: none;
    }
}
.pre-footer_heading {
    text-align: right;
    color: $white;
    display: flex;
    align-items: center;
    margin-bottom: $grid-spacing*1.5;
    @include breakpoint(medium) {
        margin-bottom: $grid-spacing;
    }
}
.pre-footer_heading_logo {
    text-align: left;
    > img {
        max-width: 142px;
    }
}
.pre-footer_heading_cta {
    @include breakpoint(small only) {
        padding-left: 0;
    }
}
.pre-footer_heading .support-title, .pre-footer_heading .phone-num {
    text-align: right;
    @include breakpoint(xlarge) {
        text-align: left;
    }
}
.pre-footer_heading .support-title {
    margin-bottom: rem-calc(1);
    color: $white;
    line-height: 1.5;
}
.pre-footer_heading .phone-num {
    text-decoration: none;
    color: $white;
    display: block;
    margin-bottom: 0;
    &:hover {
        text-decoration: underline;
    }
}
.pre-footer_menu {
    color: $white;
    .is-accordion-submenu-parent > a:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
        right: 2px;
        width: 10px;
        height: 10px;
        border: none;
        border-top: 2px solid $white;
        border-right: 2px solid $white;
        transform: rotate(135deg);
        margin-top: -7px;
        @include breakpoint(medium) {
            display: none;
        }
    }
    .is-accordion-submenu-parent[aria-expanded='true'] > a:after {
        transform: rotate(-45deg);
        margin-top: -2px;
    }
}
.pre-footer_menu .title {
    text-decoration: none;
    display: block;
    font-size: rem-calc(14);
    color: $white;
    margin-bottom: 0;
    padding: 8px 0;
    @extend h6;
    line-height: 1.1;
    &:hover {
        text-decoration: underline;
    }
    @include breakpoint(medium) {
        &:hover {
            text-decoration: none;
        }
    }
}
.pre-footer_menu > .columns > p {
    margin-bottom: 14px;
    @include breakpoint(medium) {
        margin-bottom: 28px;
    }
    > a {
        &:hover {
            text-decoration: underline;
        }
    }
}
.pre-footer_menu ul {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 14px;
    @include breakpoint(medium) {
        margin-bottom: 28px;
    }
}
.pre-footer_menu ul > li > a {
    display: block;
    padding: 7px 0;
    color: $white;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}
.app-badge {
    max-width: 135px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    @include breakpoint(medium) {
        .pre-footer & {
            margin-right: 0;
            display: block;
        }
    }
    @include breakpoint(small only) {
        width: calc(50% - 1rem);
    }
}
p > a:last-child {
    .app-badge {
        margin-right: 0;
    }    
}
// Main Footer
.white-footer {
    > .row {
        margin-top: $grid-spacing;
        margin-bottom: $grid-spacing;
        > .columns {
            @include breakpoint(small only) {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: center;
            }
        }
        @include breakpoint(medium) {
            margin-top: $grid-spacing*1.5;
            margin-bottom: $grid-spacing*1.5;
        }
        @include breakpoint(large) {
            display: flex;
            align-items: center;
            margin-top: $grid-spacing*2;
            margin-bottom: $grid-spacing*2;
        }
    }
}
.social-menu {
    margin-bottom: $grid-spacing/2;
    @include breakpoint(medium) {
        float: left;
        margin-bottom: 0;
        .white-footer & {
            margin-right: $grid-spacing;
        }
    }
}
.social_menu_wrap {
    padding-top: $grid-spacing;
    padding-bottom: $grid-spacing;
    > .columns, >.column {
        display: flex;
        justify-content: center;
        padding-left: $grid-spacing/4;
        padding-right: $grid-spacing/4;
    }
    .social-menu {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 310px;
    }
    &.social_menu_large {
        .social-menu {
            max-width: 380px;
        }
    }
    @include breakpoint(medium) {
        padding-top: $grid-spacing*1.5;
        padding-bottom: $grid-spacing*1.5;
    }
    @include breakpoint(small only) {
        .social-menu > .title {
            display: block;
            width: 100%;
            text-align: center;
            padding-right: 0;
        }
    }
}
.social-menu > li {
    &:not(:last-child) {
        padding-right: 1.5rem;
    }
}

.social-menu > li > a {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    width: 41px;
    height: 41px;
    text-align: center;
    &:hover {
        background-color: $off-white;
    }
}
.social-menu > li > a > img {
    height: 20px;
    margin-right: 0;
}
.social-menu .title {
    font-size: rem-calc(16);
    font-family: $header-font-family;
    text-transform: uppercase;
    color: $primary-color;
}
.white-footer .input-group {
    max-width: 320px;
    float: left;
    margin-bottom: 0;
}
.white-footer_region-selector {
    display: inline-block;
    width: auto;
    margin-bottom: $grid-spacing/2;
    @include breakpoint(medium) {
        margin-bottom: 0;
    }
    &.dropdown.menu > li.is-dropdown-submenu-parent > a {
        color: $body-font-color;
        font-size: rem-calc(12);
        &:after {
            display: inline-block;
            content: " ";
            width: 7px;
            height: 7px;
            border: none;
            border-top: 2px solid #333333;
            border-right: 2px solid #333333;
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
            position: relative;
            top: -2px;
            right: 0;
            margin-left: 1rem;
        }
    }
    &.menu > li > a img {
        margin-right: 0.5rem;
    }
}
.white-footer .legal-menu {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    > li {
        position: relative;
        @include breakpoint(small only) {
            text-align:center;
        }
        a{
            @include breakpoint(small only) {
                padding-right:0!important;
            }
        }
    }
    > li:not(:last-child) {
        > a {
            padding-right: 1.5rem;
        }
        &:after {
            content: '/';
            position: absolute;
            color: rgba(0, 0, 0, 0.4);
            top: 5px;
            right: 8px;
            @include breakpoint(small only) {
                content: '';
            }
        }
    }
    a.anchor-without-link {
        color: rgba(0, 0, 0, 0.4);
        pointer-events: none;
    }
    .legal-menu-with-submenu {
        padding-right:30px!important;
        padding-top:4px;
        *{
            display:inline;
        }
        > a{
            padding-right:12px!important;
            @include breakpoint(small only) {
                display:block;
                padding-bottom: 0;
                padding-right:0;
            }
        }
        li a{
            font-size: rem-calc(12);
            color: rgba(0, 0, 0, 0.4);
            padding-left: 0;
            padding-right: 0;
        }
        li a:hover{
            color: rgba(0, 0, 0, 0.8);
        }
        .legal-menu-submenu {
            > li:not(:last-child) {
                &:after {
                    content: '-';
                    padding-left: 7px;
                    padding-right: 2px;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
            > li:first-child {
                &:before {
                    content: '(';
                    position: absolute;
                    color: rgba(0, 0, 0, 0.4);
                    top: -2px;
                    left: -10px;
                }
            }
            > li:last-child {
                &:after {
                    content: ')';
                    position: absolute;
                    color: rgba(0, 0, 0, 0.4);
                    right: -8px;
                }
            }
        }
        @include breakpoint(small only) {
            padding-bottom: 5px;
            padding-right:0!important;
        }
    }
    @include breakpoint(medium) {
        padding-left: 100px;
        padding-right: 100px;
    }
    @include breakpoint(small only) {
        flex-direction: column;
    }
}
.white-footer .legal-menu > li > a {
    font-size: rem-calc(12);
    color: rgba(0,0,0,.4);
    padding-left: 0;
    &:hover {
        color: rgba(0,0,0,.8);
    }
}
a.anchor-without-link {
    cursor:default;
}
.white-footer_logo-wrap {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: $grid-spacing;
    @include breakpoint(large) {
        margin-top: 0;
    }
}
.white-footer_logos {
    max-width: 114px;
    @include breakpoint(xlarge) {
        max-width: 180px;
    }
}
.footer-update-wrap {
    margin-bottom: $grid-spacing;
    > .columns:first-child {
        @include breakpoint(medium) {
            display: flex;
            justify-content: center;
        }
        @include breakpoint(large) {
            justify-content: flex-start;
            padding-left: $grid-spacing;
        }
    }
}
.footer-legal-wrap {
    @include breakpoint(medium) {
        display: flex;
        align-items: center;
    }
}
