﻿#LiveBreadcrumbsContainer {
    padding: 0px !important;

    #TimezoneTextContainer {
        padding-left: 10px;
    }



    #LiveBreadcrumbsTopContainer {
        background-color: #EBEBEB;
        padding: 12px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        right: 0px;
        left: 0px;
        z-index: 100;

        .titletext {
            font-size: 20px;
            font-weight: bold;
            color: #616161;
        }

        #DatePickerContainer {
            right: 10px;
            top: 10px;
            position: absolute;
        }
    }

    #LiveBreadcrumbsLeftContainer {
        left: 0px;
        position: absolute;
        right: 200px;
        top: 50px;
        bottom: 0px;

        #LiveBreadcrumbsMap {
            border-right-width: 1px;
            border-right-style: solid;
            border-right-color: #E0E0E0;
            width: 100%;
            height: 100%;
        }
    }

    #LiveBreadcrumbsRightContainer {
        width: 200px;
        position: absolute;
        right: 0px;
        top: 50px;
        bottom: 0px;
        overflow-y: scroll;
        background-color: #F8F8F8;
    }

    #BreadcrumbResults {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        border-left: 1px #ACACAC solid;
        cursor: pointer;
    }

    .breadcrumbResult {
        background-color: #FAFAFA;
        border: 2px #DBDBDB solid;
        padding: 3px;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .breadcrumbResultActive {
        background-color: #F0DA96;
        border-color: #F0BB1A;
    }

    .breadcrumbResultHover {
        background-color: #F8F1DC;
        border-color: #EED075;
    }

    #BreadcrumbPinTooltip {
        background-color: yellow;
        border: 1px #ccc solid;
        padding: 3px;
        position: absolute;
        display: none;
    }
}
