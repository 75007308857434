.sunbelt-utility-bar {
    position: fixed;
    bottom: 0;
    transition: 450ms transform;
    transform: translateY(0);
    width: 100%;
    z-index: 100;
    background-color: $black;
    padding-left: 0;
    padding-right: 2px;
    box-shadow: 0px -6px 20px 0px rgba(0,0,0,.1);
    @include breakpoint(medium) {
        padding-left: $grid-spacing*.75;
        padding-right: $grid-spacing*.75;
    }
    @include breakpoint(large) {
        padding-left: $grid-spacing*1.5;
        padding-right: $grid-spacing*1.5;
    }
    @include breakpoint(small only) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .sunbelt-nearest-button {
        color: $white;
        text-decoration: none;
        height: 40px;
        display: flex;
        align-items: center;
        float: left;
        text-transform: none;
        > img {
            margin-right: 18px;
            width:12px;
            height:18px;
        }
        @include breakpoint(medium down) {   
            display: none;
        }
    }
    .sunbelt-stickyfooter-ctas {
        display: flex;
        float: right;
        .phone_button {
            padding-left: 16px;
            > img {
                margin-right: 10px;
            }
        }
        .chat_button {
            padding-left: 14px;
            > img {
                margin-right: 7px;
            }
        }
        @include breakpoint(medium only) {    
            float: none;
            width: 100%;
            .button {
                width: 50%;
            }
            .phone_button {
                display: none;
            }
            .chat_button {
                float: right;
            }
        }
        @include breakpoint(small down) {    
            float: none;
            width: 100%;
            .button {
                width: 100%;
            }
            .phone_button {
                display: none;
            }
            .chat_button {
                float: right;
            }
        }
        .attention-bubble {
            opacity: 0;
            transition: 450ms opacity;
            position: absolute;
            top: -55px;
            box-shadow: 0 10px 20px rgba(0,0,0,0.06), 0 6px 6px rgba(0,0,0,0.1);
            background-color: $off-white;
            border-radius: 9px;
            width: 120px;
            height: 40px;
            right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:after {
                position: absolute;
                content: " ";
                width: 0;
                height: 0;
                right: 24px;
                border-left: 11px solid transparent;
                bottom: -11px;
                border-right: 11px solid transparent;
                border-top: 11px solid #f3f3f3;
            }
            > p {
                margin-bottom: 0;
            }
            @include breakpoint(medium) {
                right: $grid-spacing*.75;
            }
            @include breakpoint(large) {
                right: $grid-spacing*1.5;
            }
        }
    }
    &.is_hidden {
        transform: translateY(40px);
    }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.utility-common-button {
    transition: all 250ms ease-in-out;
    margin-left: 2px;
    margin-bottom: 0;
    height: 40px;
    background-color: $black;
    &:hover {
        box-shadow: inset 0 0 8px rgba(0,0,0,.25);
        background-color: $dark-gray;
    }
    @include breakpoint(medium only) {    
            width: 50%;
            font-size:0.9rem;
    }
    @include breakpoint(small only) {    
        width: 100%;
        font-size:0.9rem;
    }
}
.sunbelt-stickyfooter-ctas .utility-common-button:first-child {
    @include breakpoint(medium down) {    
        padding-top:1em;
    }
}
.sunbelt-stickyfooter-ctas .utility-common-button:nth-child(2) {
    > img {
        width:16px;
        height:16px;
    }
    @include breakpoint(medium down) {    
        display:none;
    }
}
.sunbelt-stickyfooter-ctas .utility-common-button:nth-child(3) {
    > img {
        width:21px;
        height:18px;
    }
}