#MyAccountJobsitesMapScopeContainer {
    #locationPopupBoxClose {
        position: absolute;
        display: inline;
        top: 18px;
        right: 18px;
    }
    #locationPopupBoxContentContainer {
        white-space: nowrap;
    }
    .locationPopupBoxContentContent {
        background-color: #FFF;
        padding: 7px;
        border-radius: 4px;
        padding-right: 33px;
        white-space: normal;
        position: relative;
    }
    .jobsitepopuplistul {
        display: inline-block;
        width: 110px;
        list-style-type: none;
        padding-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 10px;
        vertical-align: top;
        color: #797979;
        margin-right: 4px;
        overflow-y: auto;
        max-height: 150px;
        white-space: normal;
    }
    .jobsitepopuplistli {
        padding-bottom: 2px;
        padding-top: 2px;
        padding-left: 6px;
        border-radius: 3px 0px 0px 3px;
        background-color: #FFF;
        margin-bottom: 2px;
        cursor: pointer;
    }
    .jobsitepopuplistliselected {
        margin-bottom: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        padding-left: 6px;
        background-color: #009B34;
        color: white;
        border-radius: 3px 0px 0px 3px;
    }
    #previousJobsiteButton {
        display: inline-block;
        padding-right: 7px;
    }
    #nextJobsiteButton {
        display: inline-block;
        padding-left: 7px;
    }
    #jobsiteIndexInfo {
        text-align: center;
        padding-top: 7px;
    }
    #jobSitePopupLeftTop {
        display: inline-block;
        height: 40px;
        float: left;
        margin-right: 10px;
        margin-left: 10px;
    }
    .jobsitePopupRight {
        display: inline-block;
    }
    #jobSitePopupLeftTopAccount {
        display: block;
        font-weight: bold;
        color: #858585;
    }
    #jobSitePopupLeftTopAccountNumber {
        font-size: 20px;
        vertical-align: top;
        line-height: 21px;
        color: #009B34;
        font-weight: bold;
    }
    #jobsiteCountInfoContainer {
        margin-top: 10px;
    }
    #jobsitePopupButtonContainer {
        text-align: center;
        margin-top: 10px;
    }
    #locationPopupFulldetailLink {
        display: none;
    }
    #locationPopupGetDirectionsLink {
        display: none;
    }
    #LocationsSearchBar {
        #LocationsNearMeButton {
            float: right;
            padding-left: 10px;
        }
    }
    #profitCenterMapSearch {
        width: 100%;
    }
    .searchBarText {
        display: none;
    }
    #MySunbeltJobSitesMapFooter {
        background-color: $cc-grid-background-color;
        border-color: $cc-border-color !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-radius: 0px 0px 4px 4px;
        z-index: 20;
        span {
            white-space: nowrap;
        }
        input[type="checkbox"] {
            margin-right: 7px;
            margin-left: 17px;
        }
        #jobsitesMapFooterCheckboxes {
            margin: 10px;
            color: rgb(78, 78, 78);
            font-size: 13px;
        }
    }
    #MySunbeltJobSitesMapInsetShadowBottom {
        width: 100%;
        z-index: 50;
        position: absolute;
        height: 7px;
        bottom: 0px;
        left: 0px;
        -webkit-box-shadow: inset 0px -5px 7px -3px rgba(0, 0, 0, 0.21);
        box-shadow: inset 0px -5px 7px -3px rgba(0, 0, 0, 0.21);
    }
    #MySunbeltJobSitesMapInsetShadowTop {
        width: 100%;
        z-index: 53;
        position: relative;
        top: 0px;
        left: 0px;
        height: 7px;
        -webkit-box-shadow: inset 0px 5px 7px -3px rgba(0, 0, 0, 0.21);
        box-shadow: inset 0px 5px 7px -3px rgba(0, 0, 0, 0.21);
    }
    #MySunbeltJobSitesMapUnmappableJobsitesNotification {
        position: absolute;
        background: rgb(225, 0, 0);
        height: 25px;
        cursor: pointer;
        width: 177px;
        right: 0px;
        top: 0px;
        z-index: 5;
        padding-top: 4px;
        padding-left: 12px;
        color: white;
        font-size: 12px;
        line-height: 21px;
    }
    #MySunbeltJobsSitesMapWrapper {
        height: 550px;
        @media (max-width: $screen-xs-max) {
            height: 290px;
        }
        position: relative;
        border-color: $cc-border-color !important;
        border-style: solid !important;
        border-width: 0px 1px 0px 1px;
    }
    #locationPopupBox {
        background-color: #F4F4F4;
        border: 1px solid #AAA;
        border-radius: 6px;
        -webkit-box-shadow: rgba(2, 2, 2, 0.21) 0px 0px 5px 3px;
        box-shadow: rgba(2, 2, 2, 0.21) 0px 0px 5px 3px;
        color: #464646;
        padding: 12px;
        font-size: 12px;
    }
    #locationPopupContainer {
        @media #{$mobile-wide-max} {
            position: relative;
            top: 100px !important;
            left: 0px !important;
        }
    }
    #locationPopupBoxContent {
        padding-right: 10px;
    }
    #locationPopupStalk {
        display: none;
        @media #{$tablet} {
            display: block;
        }
    }
    #JobSitePopupBadgeContainer {
        margin-left: 10px;
    }
    .jobSitePopupBadge {
        padding-bottom: 6px;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 0px;
        text-align: center;
        background-repeat: no-repeat;
        padding-top: 3px;
        color: white;
        font-weight: bold;
        font-size: 12px;
    }
    .jobSitePopupAddress {
        height: 40px;
        display: inline-block;
        font-weight: bold;
        color: #858585;
        border-left: solid;
        border-left-width: 1px;
        border-left-color: #CECECE;
        padding-left: 8px;
        max-width: 160px;
    }
    .jobSitePopupAlertBadge {
        background-image: url('/Content/images/MyAccount/mysunbelt-jobsite-popup-badge-red.png');
    }
    .jobSitePopupEquipmentBadge {
        background-image: url('/Content/images/MyAccount/mysunbelt-jobsite-popup-badge-green.png');
    }
    .blockCenter {
        text-align: center;
        display: block;
    }
    .jobSitePopupEquipmentText {
        padding-left: 5px;
        color: #009B34;
        font-weight: bold;
        font-size: 15px;
    }
}
#jobsiteMoreInfoWindow {
    #EquipmentGrid {
           margin-top: 10px;
        margin-bottom: 10px;
    }
}
#jobSitePopup {
    .button { 
        margin-bottom:0px;
    }
}