﻿#MyAccountEquipmentLiveScopeContainer {
    #LocationsSearchBar {
        #LocationsNearMeButton {
            display: none;
        }
        #LocationsSearchButton {
            display: none;
        }
        .searchBarText {
            display: none;
        }
    }
    #LocationSearchPopupButton {
        float: right;
        padding-left: 11px;
        padding-top: 3px;
        display: none;
        @media (min-width: $screen-md-min) {
            display: initial;
        }
    }
    .mapPopup {
        z-index: 3;
    }
    #LiveMapFooter {
        background-color: #F4F4F4;
        border-color: #DFDFDF !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-radius: 0px 0px 4px 4px;
        z-index: 20;
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 10px;
        color: #646464;
        font-size: 13px;
        font-family: Verdana, Helvetica, Sans-Serif;
        .footerLabel {
            padding-top: 5px;
            font-weight: bold;
        }
        span {
            white-space: nowrap;
        }
    }
    #MySunbeltLiveMap {
        height: 550px;
        @media (max-width: $screen-xs-max) {
            height: 290px;
        }
        position: relative;
        border-color: #DFDFDF !important;
        border-style: solid !important;
        border-width: 0px 1px 0px 1px;
    }
    #MySunbeltJobSitesMapInsetShadowBottom {
        width: 100%;
        z-index: 50;
        position: absolute;
        height: 7px;
        bottom: 0px;
        left: 0px;
        -webkit-box-shadow: inset 0px -5px 7px -3px rgba(0, 0, 0, 0.21);
        box-shadow: inset 0px -5px 7px -3px rgba(0, 0, 0, 0.21);
    }
    #MySunbeltJobSitesMapInsetShadowTop {
        width: 100%;
        z-index: 53;
        position: relative;
        top: 0px;
        left: 0px;
        height: 7px;
        -webkit-box-shadow: inset 0px 5px 7px -3px rgba(0, 0, 0, 0.21);
        box-shadow: inset 0px 5px 7px -3px rgba(0, 0, 0, 0.21);
    }
    #locationPopupBox {
        background-color: #F4F4F4;

        color: #464646; 
        padding: 12px;
        font-size: 12px;
        @media #{$mobile-wide-max} {
            max-width: 400px;
        }
    }
    #locationPopupContainer {
        @media #{$mobile-wide-max} {
            position: relative;
            top: 100px !important;
            left: 0px !important;
        }
    }
    #locationPopupBoxContent {
        padding-right: 10px;
    }
    #locationPopupBoxClose {
        position: absolute;
        display: inline;
        top: 18px;
        right: 18px;
    }
    .EquipmentPopupBoxContentContent {
        background-color: #FFF;
        padding: 7px;
        border-radius: 4px;
        font-family: Helvetica, Sans-Sarif;
        .epub-container {
            display: inline-table;
        }
        .boldLabel {
            font-weight: bold;
        }
        .smallFont {
            font-size: 9px;
            display: inline-table;
        }
        .lastRefresh {
            margin-left: 20px;
        }
    }
    #equipmentPopupLeftTop {
        display: inline-block;
        height: 40px;
        float: left;
        margin-right: 10px;
        margin-left: 10px;
    }
    #equipmentPopupLeftTopAccount {
        display: block;
        font-weight: bold;
        color: #858585;
    }
    #equipmentPopupLeftTopAccountNumber {
        font-size: 14px;
        vertical-align: top;
        line-height: 21px;
        color: #009B34;
        font-weight: bold;
    }
    .equipmentPopupAddress {
        height: 40px;
        display: inline-block;
        font-weight: bold;
        color: #858585;
        border-left: solid;
        border-left-width: 1px;
        border-left-color: #CECECE;
        padding-left: 8px;
        padding-right: 24px;
    }
    .fuel-info {
        font-weight: normal;
        font-size: 9px;
        color: #464646;
    }
    #locationPopupStalk {
        display: none;
    }
    #EquipmentPopupUtilizationTable {
        border-collapse: collapse;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 10px;
        th {
            font-size: 10px;
            font-style: italic;
            color: #CECECE;
            padding: 0 8px 3px 3px;
        }
        th:last-child {
            color: #009B34;
            font-weight: bold;
        }
        .headers {
            border-bottom: 1px solid #CECECE;
        }
        td {
            border: 1px solid #CECECE;
            margin: 0px;
            border-style: none;
            font-size: 15px;
            font-weight: bold;
            text-align: center;
        }
        td:last-child {
            color: #009B34;
            font-weight: bold;
        }
        .data-leftcolumn {
            width: 100px;
        }
        .data-cell {
            font-size: 7px;
            color: #009B34;
            text-align: right;
        }
        .header-cell {
            background-color: #EEE;
            width: 130px;
            text-align: center;
            font-size: 14px;
        }
        .columnTitle {
            font-size: 10px;
            color: black;
        }
        .headerBorder {
            border-bottom-style: solid;
            border-color: #cecece;
            border-width: 1px;
        }
        .line {
            border-bottom: 1px solid #cecece;
            height: 0.6em;
            width: 200px;
            text-align: center;
            margin-bottom: 1em;
            span {
                background: white;
            }
        }
        ul {
            list-style-type: none;
        }
    }
    .equipmentPopLineContainer {
        margin: 10px 0px -4px 0px;
        text-align: center;
        padding-left: 28%;
        border-bottom: solid 1px #cecece;
        height: 10px;
        .text {
            float: left;
            border: solid 0px #cecece;
            background-color: White;
            padding-right: 5px;
            height: 20px;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 9px;
            color: #cecece;
        }
    }
    #RolloverInfo, #RolloverCustomInfo {
        font-size: 10px;
        margin-left: 4px;
        margin-top: 9px;
        font-weight: bold;
        color: #6F6F6F;
        .header {
            font-weight: bold;
            color: #3A3A3A;
        }
        .green {
            color: #009B34;
        }
    }
    #LiveViewPopupButtons {
        margin-top: 10px;
        text-align: right;
        #DetailButton {
            padding-left: 4px;
        }
        #BreadcrumbButton {
            padding-left: 4px;
        }
    }
}
#MyAccountEquipmentLivePopupScopeContainer {
    #MySunbeltLiveMap {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    #LocationsSearchBar {
        margin-bottom: 0 !important;
        border-radius: 0 4px 0 0 !important;
        border-color: #DFDFDF !important;
        border-style: solid !important;
        position: relative !important;
        z-index: 199 !important;
        border-width: 1px !important;
        background-color: #F4F4F4;
        padding: 12px;
        #profitCenterMapSearch {
            width: 100%;
            padding-left: 7px;
            padding-right: 7px;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            -khtml-border-radius: 3px;
            border: 1px solid #C2C2C2;
            outline: 0px;
            height: 34px;
            -moz-box-shadow: inset -2px 2px 4px 0px #ebebeb;
            -webkit-box-shadow: inset -2px 2px 4px 0px #EBEBEB;
            box-shadow: inset -2px 2px 4px 0px #EBEBEB;
            vertical-align: middle;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
        }
        #LocationsNearMeButton {
            display: none;
        }
        #LocationsSearchButton {
            display: none;
        }
        .searchBarText {
            display: none;
        }
    }
    #locationPopupBox {
        background-color: #F4F4F4;
        color: #464646;
        padding: 12px;
        font-size: 12px;
    }
    #locationPopupBoxContent {
        padding-right: 10px;
    }
    #locationPopupBoxClose {
        position: absolute;
        display: inline;
        top: 18px;
        right: 18px;
    }
    .EquipmentPopupBoxContentContent {
        background-color: #FFF;
        padding: 7px;
        border-radius: 4px;
        font-family: Helvetica, Sans-Sarif;
        .epub-container {
            display: inline-table;
        }
        .boldLabel {
            font-weight: bold;
        }
        .smallFont {
            font-size: 9px;
            display: inline-table;
        }
        .lastRefresh {
            margin-left: 20px;
        }
    }
    #equipmentPopupLeftTop {
        display: inline-block;
        height: 40px;
        float: left;
        margin-right: 10px;
        margin-left: 10px;
    }
    #equipmentPopupLeftTopAccount {
        display: block;
        font-weight: bold;
        color: #858585;
    }
    #equipmentPopupLeftTopAccountNumber {
        font-size: 14px;
        vertical-align: top;
        line-height: 21px;
        color: #009B34;
        font-weight: bold;
    }
    .equipmentPopupAddress {
        height: 40px;
        display: inline-block;
        font-weight: bold;
        color: #858585;
        border-left: solid;
        border-left-width: 1px;
        border-left-color: #CECECE;
        padding-left: 8px;
        padding-right: 24px;
    }
    #EquipmentPopupUtilizationTable {
        border-collapse: collapse;
        font-size: 13px;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 10px;
        th {
            font-size: 10px;
            font-style: italic;
            color: #CECECE;
            padding: 0 10px 3px 10px;
        }
        th:last-child {
            color: #009B34;
            font-weight: bold;
        }
        .headers {
            border-bottom: 1px solid #CECECE;
        }
        td {
            border: 1px solid #CECECE;
            margin: 0px;
            border-style: none;
            font-size: 15px;
            font-weight: bold;
            text-align: center;
        }
        td:last-child {
            color: #009B34;
            font-weight: bold;
        }
        .data-leftcolumn {
            width: 100px;
        }
        .data-cell {
            font-size: 7px;
            color: #009B34;
            text-align: right;
        }
        .header-cell {
            background-color: #EEE;
            width: 130px;
            text-align: center;
            font-size: 14px;
        }
        .columnTitle {
            font-size: 10px;
            color: black;
        }
        .headerBorder {
            border-bottom-style: solid;
            border-color: #cecece;
            border-width: 1px;
        }
        .line {
            border-bottom: 1px solid #cecece;
            height: 0.6em;
            width: 200px;
            text-align: center;
            margin-bottom: 1em;
            span {
                background: white;
            }
        }
        ul {
            list-style-type: none;
        }
    }
    .equipmentPopLineContainer {
        margin: 10px 0px -4px 0px;
        text-align: center;
        padding-left: 28%;
        border-bottom: solid 1px #cecece;
        height: 10px;
        .text {
            float: left;
            border: solid 0px #cecece;
            background-color: White;
            padding-right: 5px;
            height: 20px;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 9px;
            color: #cecece;
        }
    }
    #RolloverInfo, #RolloverCustomInfo {
        font-size: 10px;
        margin-left: 4px;
        margin-top: 9px;
        font-weight: bold;
        color: #6F6F6F;
        .header {
            font-weight: bold;
            color: #3A3A3A;
        }
        .green {
            color: #009B34;
        }
    }
    #LiveViewPopupButtons {
        margin-top: 10px;
        text-align: right;
        #DetailButton {
            padding-left: 4px;
        }
    }
}
