.map-viewport {
    height: 650px;
    width: 100%;
    position: relative;
    @media screen and (max-width: $screen-xs-max) {
        height: 240px;
        width: 100%;
    }
}
.map-overlay {
    height: 614px;
    width: 356px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 18px;
    margin-right: 30px;
    @media screen and (max-width: $screen-xs-max) {
        display: none;
    }
}
.location-mobile-list {
    @media screen and (min-width: $screen-sm-min) {
        display: none;
    }
    margin-bottom: 37px;
}
.location-item {
    align-items: center;
    display: flex;
    margin-left: 8px;
    margin-right: 7px;
    margin-top: 17px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 12px;
    position: relative;
    &.selected-location {
        border: 1px solid #CCCCCC;
    }
    .location-input {
        height: 32px;
        margin: 0px 7px;
        padding: 0px;
        width: 32px;
    }
    .location-item-container {
        width: 100%;
        .location-title {
            color: #333333;
            font-family: Roboto;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0.2px;
            margin-bottom: 10px;
            text-align: left;
            .location-title-primary {
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                line-height: 16px;
                & > span {
                    &:first-child {
                        overflow: hidden;
                    }
                    &.location-distance {
                        color: #333333;
                        font-family: Roboto;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0.2px;
                        margin-left: 1rem;
                        position: relative;
                        white-space: nowrap;
                    }
                }
            }
            .location-description {
                color: #333333;
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.2px;
                text-align: left;
            }
        }
        .location-detail {
            color: #333333;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.1px;
            line-height: 19px;
            text-align: left;
        }
        .location-recommended {
            color: #3C76C3;
            font-family: Roboto;
            font-size: 11px;
            letter-spacing: 0.1px;
            line-height: 19px;
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 14px;
        }    
    }
}
