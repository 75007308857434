﻿table#invoice-summary {
    border: none !important;
    font-size: .9em;
    max-width: 650px;
    width: 100%;

    tr, td, trow, tbody {
        border: none !important;
    }
    tr:last-child {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.2em;
        color: #262525;
    }
    td:last-child {
        text-align: right;
    }
}
label#invoices {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    color: #707070;
}
.payment-method {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    white-space: nowrap;
    width: 250px;
    &.bank-accounts {
        align-items: flex-start;
    }
    span {
        display: block;
        font-size: 1.2em;
        line-height: 1.2em;
        margin-left: 10px;
        max-width: 150px;
        white-space: pre-wrap;
        &:first-of-type {
            margin-left: 20px;
        }
        &.bank-name {
            font-weight: bold;
        }
        &.small {
            align-self: 'center';
            color: #7a7979;
            font-size: .8em;
            margin-left: 15px;
        }
        .Cardlogo {
            max-width: 40px;
        }
    }
}
#payment-methods-source {
    .payment-item {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        max-width: 320px;
        position: relative;
        label {
            align-items: center;
            cursor: pointer;
            margin: 0;
            width: 275px;
            i.fa.fa-circle-o, i.fa.fa-dot-circle-o {
                align-self: flex-start;
            }
        }
    }
}
.payment-deletion {
    align-self: flex-start;
    line-height: 1.2em;
    margin-top: 6px;
}
.confirmation-tail {
    height: 10px;
    width: 10px;
    top: 9px;
    right: -15px;
    background-color: white;
    z-index: 2;
    transform: rotate(45deg);
    display: none;
    position: absolute;
    box-shadow: 3px 3px 4px 1px rgba(0, 0, 0, 0.2);
}
.confirmation-dialog {
    height: 50px;
    width: 100px;
    top: -9px;
    right: -110px;
    background-color: white;
    z-index: 3;
    display: none;
    position: absolute;
    box-shadow: 3px 3px 4px 1px rgba(0, 0, 0, 0.2);
}
.deletion-prompt {
    font-weight: bold;
    padding-top: 7px;
    padding-left: 10px;
    font-size: 13px;
    max-height: 25px;
    height: 25px;
}
.deletion-buttons {
    width: 105px;
}
.deletion-confirmation-button {
    text-decoration: none;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    border-top: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    margin-top: 5px;
    width: 50px;
    padding-left: 9px;
    color: red;
}
.deletion-cancel-button {
    color: #3C76C3;
    text-decoration: none;
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    border-top: 1px solid #CCCCCC;
    margin-top: 4px;
    width: 50px;
    padding-left: 5px;
    margin-left: -3px;
}
.payment-delete-button {
    top: 3px;
    color: #3C76C3;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
}
form.sb-payment-form {
    margin-bottom: 40px;
    a {
        font-size: .9em;
    }
    label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        input[type="radio"] ~ i.fa.fa-circle-o {
            color: #c8c8c8;
            display: inline;
        }
        input[type="radio"] ~ i.fa.fa-dot-circle-o {
            display: none;
        }
        input[type="radio"]:checked ~ i.fa.fa-circle-o {
            display: none;
        }
        input[type="radio"]:checked ~ i.fa.fa-dot-circle-o {
            color: #007a39;
            display: inline;
        }
    }
    input[type="radio"] {
        display: none;
    }
}
.paymentTopLink {
    margin-top: -14px;
    margin-bottom: 40px;
    a {
        font-size: .9em;
    }
}
#opacity {
    padding-left:20px;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
form#payment-review {
    h4 {
        margin-bottom: 12px;
    }
    .payment-method {
        margin-bottom: 40px;

        span {
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .button {
        margin-top: 30px;
    }
    table#invoice-summary {
        tbody {
            tr {
                span {
                    font-size: 13px;
                }
                td {
                    &:nth-of-type(2) {
                        text-align: right;
                    }
                    &.extra-space {
                        display: none;
                        padding-left: 14rem;
                    }
                }
                &.invoice-row {
                    td {
                        padding-bottom: 4px;

                        &.extra-bottom-space {
                            padding-bottom: 20px;
                        }
                    }
                }
                &#surcharge-row-pay-later-title {
                    border-top: 1px solid rgba(112, 112, 112, 0.5) !important;
                    td {
                        padding: 20px 0 10px;
                    }
                    h3 {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 1.2em;
                        color: #707070;
                        font-family: "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "Roboto", "Helvetica", "Arial", sans-serif;
                        font-stretch: normal;
                        text-align: left;
                        margin: 0;
                    }
                }
                &#surcharge-row-pay-later-values {
                    td {
                        padding-left: 0;
                    }
                }
                &#surcharge-row-pay-later-checkbox {
                    border-bottom: 1px solid rgba(112, 112, 112, 0.5) !important;
        
                    td {
                        padding-left: 0;
                        padding-bottom: 20px;
        
                        label {
                            -moz-user-select: none;
                            -ms-user-select: none;
                            -webkit-user-select: none;
                            align-items: flex-start;
                            color: #222222;
                            cursor: pointer;
                            display: flex;
                            font-size: 14px;
                            max-width: 215px;
                            position: relative;
                            text-align: left;
                            user-select: none;

                            &.full-width {
                                max-width: 100%;
                            }
                            input {
                                cursor: pointer;
                                height: 0;
                                opacity: 0;
                                position: absolute;
                                width: 0;

                                &:hover ~ .checkmark {
                                    background-color: #b4e8cc;
                                }
                                &:checked ~ .checkmark {
                                    background-color: #008545;
                                    &:after {
                                        display: block;
                                    }
                                }
                            }
                            span {
                                font-size: 14px;
                                margin-left: 30px;
                            }
                            .checkmark {
                                background-color: #fff;
                                border: 1px solid #868686;
                                height: 22px;
                                left: 0;
                                margin-left: 0;
                                position: absolute;
                                top: 0;
                                width: 22px;

                                &:after {
                                    -ms-transform: rotate(45deg);
                                    -webkit-transform: rotate(45deg);
                                    content: " ";
                                    border: solid white;
                                    border-width: 0 2px 2px 0;
                                    display: none;
                                    height: 11px;
                                    left: 7px;
                                    position: absolute;
                                    top: 3px;
                                    transform: rotate(45deg);
                                    width: 6px;
                                }
                            }
                        }
                    }
                }
                &#surcharge-invoice-subtotal-row {
                    td {
                        padding-top: 20px;
                    }
                }
                &#summary-total {
                    td {
                        span {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
div.row.payments-wrap {
    max-width:85%;
    margin-left:auto;
    margin-right:auto;
    margin-top:15px;
}
.new-payment-summary {
    display:none;
}
/* Large and up */
@media screen and (min-width: 64em) {
    .new-payment-summary {
        display:block;
    }
    form.sb-payment-form {
        margin-bottom: 0px;
    }
    div.row.payments-wrap {
        margin-top: 60px;
        max-width: 80%;
    }
    .right-border {
        border-right: 1px solid grey;
    }
}
.payment-complete {
    h3 {
        color: #007a39;
        margin-bottom: 40px;
    }
    h2 {
        margin-bottom: 10px;
    }
}
// Tablet and up
@media screen and (min-width: 640px) {
    form#payment-review {
        table#invoice-summary {
            tbody {
                tr {
                    td {
                        &.extra-space {
                            display: table-cell;
                        }
                    }
                }
            }
        }
    }
}