﻿#InvoicesPopupAccountSummary {
    padding: 10px;
	font-weight: bold;
    background-color: #ececec;
    border-bottom: 1px solid #000000;
}
#invoiceDateFilterContainer {
    @media #{$mobile-wide-max} {
        display:none;
    }
    label {
        display:inline-block;
    }
    .button {
        margin-bottom:0px;
    }
}
#Invoice {
    table {
        margin-bottom: 2px;
        tbody td {
            padding: 5px;
        }
    }
    .ShadowBox {
        display: inline-block;
        padding: 1px 1px 10px 1px;
        overflow: auto;
        background-image: none; // over-riding ShadowBox in Site.less
        width: 100%;
    }
    .ShadowBoxContent {
        display: block;
        border: 5px solid #ececec;
        padding: 6px 10px 4px 10px;
        overflow: auto;
    }
    #InvoiceTabs-1 {
        margin-left: 0px;
        padding-bottom: 0px;
        #OpenInvoiceGrid {
            border-right-style: none;
            border-left-style: none;
            border-bottom-style: none;
        }
    }
    #InvoiceTabs-2 {
        margin-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        #PaidInvoiceGrid {
            border-style: none;
        }
    }
    #InvoiceTabs-3 {
        margin-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        #AllInvoiceGrid {
            border-style: none;
        }
    }
    #InvoiceTabs-4 {
        margin-left: 0px;
    }
    #InvoiceTabs {
        font-size: 10px;
        @media #{$mobile-wide-max} {
            display: none;
        }
        .k-tabstrip-items {
            padding-left: 0px;
        }
        button {
            font-size: 12px;
        }
        input[data-role='Amount'] {
            width: 70px;
            text-align: right;
            font-size: 10px;
        }
        .link {
            text-decoration: underline;
            color: #009c39;
        }
        .selectedRow {
            font-weight: bold;
        }
        input[data-role='datepicker'] {
            text-align: left;
        }
        .k-item {
            border-color: #c5c5c5;
        }
        div.k-content {
            border-color: #c5c5c5;
        }
    }
    .mainTitle {
        font-size: 20px;
        font-weight: bold;
    }
    .totalTitle {
        font-size: 24px;
        text-align: right;
    }
    .accoutItemTitle {
        font-size: 18px;
        text-align: right;
        font-weight: bold;
    }
    .accountItem {
        font-size: 18px;
        text-align: right;
    }
    .accountSummary {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
    }
    .accountSummaryContent {
        padding: 0px 0px 0px 0px;
        a {
            text-decoration: underline;
        }
    }
    .invoiceSummaryTitle {
        font-size: 16px;
        font-weight: bold;
    }
    .invoiceDarkGray {
        font-size: 12px;
        font-weight: bold;
    }
    .invoiceLightGray {
        font-size: 12px;
    }
    .invoiceTitle {
        text-align: right;
    }
    .invoiceItem {
        text-align: left;
    }
    .invoiceCurrency {
        text-align: right;
    }
    .checkOn {
        background: no-repeat url(/Content/images/Shared/CheckOn.png);
        width: 27px;
        display: block;
        height: 27px;
    }
    .checkOff {
        background: no-repeat url(/Content/images/Shared/CheckOff.png);
        width: 27px;
        display: block;
        height: 27px;
    }
    .checkDisable {
        background: no-repeat url(/Content/images/Shared/GreyCheckNoBorder.png);
        width: 27px;
        display: block;
        height: 27px;
    }
}
// Start Print Styles --------------------------------------
// Hide the printing wrapper
#invoice-printing {
    display: none !important;
}
@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline !important;
    }
    a[href]:after {
        content: " (" attr(href) ")" !important;
    }
    abbr[title]:after {
        content: " (" attr(title) ")" !important;
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "" !important;
    }
    pre,
    blockquote {
        border: 1px solid #999 !important;
        page-break-inside: avoid !important;
    }
    thead {
        display: table-header-group !important;
    }
    tr,
    img {
        page-break-inside: avoid !important;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3 !important;
        widows: 3 !important;
    }
    h2,
    h3 {
        page-break-after: avoid !important;
    }
    .navbar {
        display: none !important;
    }
    .btn > .caret,
    .dropup > .btn > .caret {
        border-top-color: #000 !important;
    }
    .label {
        border: 1px solid #000 !important;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }
}
@media print {
    .visible-print {
        display: block !important;
    }
    table.visible-print {
        display: table !important;
    }
    tr.visible-print {
        display: table-row !important;
    }
    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}
.visible-print-block {
    display: none !important;
}
@media print {
    .visible-print-block {
        display: block !important;
    }
}
.visible-print-inline {
    display: none !important;
}
@media print {
    .visible-print-inline {
        display: inline !important;
    }
}
.visible-print-inline-block {
    display: none !important;
}
@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}
@media print {
    .hidden-print {
        display: none !important;
    }
}
@media print {
    header {
        display: none !important;
    }
    footer {
        display: none !important;
    }
    .off-canvas-wrapper {
        display: none !important;
    }
    .LeftNav {
        display: none !important;
    }
    .pre-footer {
        display: none !important;
    }
    // Display the printing wrapper
    #invoice-printing {
        display: block !important;
    }
}
// End Print Styles ----------------------------------------
