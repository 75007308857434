﻿#dashboard-header {
    #startDateContainer {
        display:inline-block;
        @media (max-width: 550px) {
            margin-bottom:10px;
            width: 100%;
        }
    }
    span.daterange-label {
        width: 41px;
        display: inline-block;
        text-align: right;
        padding-right: 4px;
    }
    .header-column {
        padding-bottom: 8px;
    }
    #selectDashboardContainer {
        @media (max-width: $screen-md-max) {
            margin-left:44px;
        }
    }
}
#Dashboard {
    .ChartShadow {
        min-width: 300px;
        display: inline-block;
        padding: 1px 1px 14px 1px;
        overflow: auto;
        background-image: url('/Content/images/Shared/ShadowBoxSmall.png');
        background-repeat: no-repeat;
        background-position: center bottom;
        .ChartTop {
            height: 31px;
            background-image: url('/Content/images/MyAccount/Charts/ChartTopBG.png');
            background-color: #eeeeee;
            background-position: 109px 0px;
            .Right {
                float: right;
                padding: 3px;
            }
        }
        .ChartContent {
            display: block;
            border: 5px solid #ececec;
            border-top: 0px;
            padding: 20px;
            overflow: hidden;
            label {
                font-size:1.6em;
                font-weight: bold;
                margin-bottom:0px;
            }
            .Dates {
                margin-top: 3px;
                font-size:1.2em;
                font-weight: bold;
                @media (min-width: $screen-sm-min) {
                    float: right;
                }
            }
            .sb-chart-container {
                clear: both;
                margin-top: 15px;
                height:250px;
                z-index: 0;
            }
        }
    }
    .full {
        width: 100%;
    }
    .half {
        width: 50%;
        @media #{$mobile-wide-max} {
            width:100%;
        }
    }
}
