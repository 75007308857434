.tooltip {
    display: block !important;
    z-index: 10000;
    width: 350px;
    .tooltip-inner {
        background: $light-gray;
        color: $dark-gray;
        padding: 16px;
    }
    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 8px;
        border-color: $light-gray;
        z-index: 1;
    }
    &[x-placement^="top"] {
        margin-bottom: 16px;
        .tooltip-arrow {
            border-width: 8px 10px 0 10px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -8px;
            left: calc(50% - 8px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    &[x-placement^="bottom"] {
        margin-top: 16px;
        .tooltip-arrow {
            border-width: 0 10px 8px 10px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -8px;
            left: calc(50% - 8px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    &[x-placement^="right"] {
        margin-left: 16px;
        .tooltip-arrow {
            border-width: 10px 8px 10px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -8px;
            top: calc(50% - 8px);
            margin-left: 0;
            margin-right: 0;
        }
    }
    &[x-placement^="left"] {
        margin-right: 16px;
        .tooltip-arrow {
            border-width: 10px 0 10px 8px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -8px;
            top: calc(50% - 8px);
            margin-left: 0;
            margin-right: 0;
        }
    }
    &.popover {
        .popover-inner {
            background: $light-gray;
            color: $dark-gray;
            padding: 24px;
            box-shadow: 0 5px 30px rgba(black, .1);
        }
        .popover-arrow {
            border-color: $light-gray;
        }
    }
    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }
    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}
