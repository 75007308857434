﻿::-ms-reveal {
    display: none;
}

#MyAccountChangePassword {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    color: #1C1C1C;
    margin: 50px 0;

    .change-password-container {
        width: 360px;
        min-height: 790px;
        height: auto;
        background: #FFFFFF;
        border: 1px solid #B7B7B7;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 20px;

        .title-header {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            padding: 10px 0;
        }

        #changepasswordform {
            .row {
                .password-wrapper {
                    display: flex;
                    flex-direction: row;

                    .show-password-wrapper {
                        margin-top: 33px;
                        height: 48px;
                        border: 2px solid #d6d6d6;
                        border-left: none;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 47px;
                        display: flex;
                        align-items: center;

                        &.error {
                            background-color: #fff;
                            border: 2px solid #D23B12;
                            border-left: none;
                        }

                        span {
                            cursor: pointer;
                            text-decoration: underline;
                            margin-bottom: 0px !important;
                        }
                    }

                    .form-group {
                        padding-bottom: 15px;
                        width: 84%;

                        .form-control {
                            background: #fff;
                            border: 2px solid #D6D6D6;
                            box-sizing: border-box;
                            border-radius: 8px;
                            padding: 12px;
                            height: 48px;
                            margin-bottom: 4px !important;
                            border-right: none !important;
                            border-top-right-radius: 0px !important;
                            border-bottom-right-radius: 0px !important;
                            font-size: 16px;

                            &:focus {
                                border: 2px solid #D6D6D6;
                            }

                            &.is-invalid-input,
                            &.is-invalid-input:not(:focus),
                            &.is-invalid-input:focus {
                                background-color: #fff !important;
                                border: 2px solid #D23B12 !important;
                                border-right: none !important;
                            }
                        }

                        .form-error {
                            color: #D23B12;
                            font-family: 'Roboto';
                            font-size: 14px;
                            font-weight: 400;
                            margin-top: 0.5rem;
                            margin-bottom: 0;
                        }

                        .control-label {
                            font-family: 'Roboto';
                            font-size: 15px;
                            font-weight: 400;
                            color: #1C1C1C;
                            line-height: 28px;
                            padding-bottom: 5px;

                            &.control-error {
                                color: #D23B12;
                            }
                        }
                    }
                }

                .password-requirment, li {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 28px;
                    color: #000000;
                }

                .submitbtn {
                    background: #008545;
                    border-radius: 8px;
                    color: #fff;
                    width: 100%;
                    height: 48px;
                    cursor: pointer;
                    margin: 15px 0;
                    border-style: unset;
                }

                .backlink {
                    color: #008545;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 10px;
                    text-decoration: none;
                }

                .global-error {
                    color: #D23B12;
                    font-family: 'Roboto';
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: -0.5rem;
                }
            }
        }
    }
}
