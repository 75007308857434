﻿#UserAccessEditPopup, #AddNewUserToAccountPopup, #AddNewUserInvitationSentPopup, #AddAccountPopup, #ConfirmationRemoveUserPopup, #AddUserToAccountPopup {
    padding: 15px;
    table {
        tbody {
            border: 0px;
            tr {
                border-bottom: 0px;
            }
            td { 
                padding:2px;
            }
        }
    }
    input#NewAccountNumber {
        display: inline-block;
        margin-bottom: 0px;
    }
    input#EmailAddress {
        margin-bottom:0px;
    }
}
#UserAccessEditPopup {
    .am-edit-row {
        margin-bottom:5px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        tr {
            overflow: auto;
            background-color: #ffffff;
            &.ItemRowAlt {
                background-color: #f5f5f5;
            }
            th {
                overflow: auto;
                width: 500px;
                text-align: left;
                vertical-align: top;
                padding: 5px 0px 5px 15px;
                div {
                    overflow: auto;
                    margin-bottom: 10px;
                    .Check {
                        float: left;
                        margin-right: 15px;
                        margin-top: 18px;
                    }
                    .ItemImg {
                        float: left;
                    }
                    span {
                        display: block;
                        float: left;
                        margin-left: 12px;
                        margin-top: 10px;
                        font-weight: normal;
                    }
                }
            }
            td {
                overflow: auto;
                width: 215px;
                padding: 0px 0px 0px 10px;
                text-align: left;
                vertical-align: top;
                margin-left: 23px;
                vertical-align: middle;
            }
        }
    }
}
#AccountManagementContainer {
    .SectionLabel {
        color: #009c39;
        font-size: 20px;
        font-weight: bold;
    }
    #div-AddAccount {
        padding-bottom: 16px;
    }
    #AccountUserlist {
        font-size: 12px;
        .name {
            color: grey;
        }
    }
    #AccountUsersForm {
        .checkCell {
            text-align: center;
        }
        .faddedCheck {
            opacity: .41;
        }
        tr:nth-child(even) {
            background-color: rgba(128, 128, 128, 0.08);
        }
        tbody, td, tr, table {
            box-sizing: content-box;
        }
        td {
            padding:1px;
        }
        table {
            border-spacing: 2px;
            border-collapse: separate;
            width:815px;
        }
        .button.tiny {
            margin-bottom:0px;
            font-size:11px;
        }
        #AccountManagementTableHeaderImage {
            max-width:initial;
        }
    }
    #div-Address {
        #BillingAddress {
            margin-bottom: -10px;
            padding-bottom: 0px;
            padding-top: 4px;
            color: #3A3A3A;
            font-size: 14px;
            label {
                width: 56px;
                display: inline-block;
            }
        }
        #OtherContact {
            padding-top: 10px;
        }
    }

    #div-UserAccounts {
        @media (max-width: $screen-sm-max) {
            overflow-x: auto;
            width: 100vw;
        }
    }
}
#SimplePopup {
    margin-bottom: 0px !important;
    border-radius: 0px 4px 0px 0px !important;
    border-color: #DFDFDF !important;
    border-style: solid !important;
    position: relative !important;
    z-index: 200 !important;
    border-width: 0px !important;
	span {
		font-size: 18px;
	}
	label {
		font-style: italic;
        font-size: 18px;
	}
	input {
		float: left;
		width: 230px;
		font-size: 18px;
		color: #000000;
		overflow: visible; /* IE Hack to fix horizontal padding */
	}
	.SectionTitle
	{
	    font-size: 21px;
	  	strong
		{
			font-weight: bold;
		}
	}
}

