#dateRangeModal.reveal {
  width: 320px;
  margin: 0 auto;
  padding: 40px 20px 20px;
  &:focus {
      outline: none;
  }
}
input[name='daterange'] {
  font-family: $header-font-family;
  text-transform: uppercase;
  background-image: url('/content/img/calendar-dark.svg');
  background-repeat: no-repeat;
  background-size: 27px 27px;
  background-position: 5px;
  padding-left: 46px;
}
input[readonly][name='daterange'],input[readonly][name='daterangepicker_start'],input[readonly][name='daterangepicker_end'] {
    cursor: pointer;
}
.arrow-left {
  &:before {  
    color: #0a0a0a;
    width: 12px;
    content: "";
    display: block;
    height: 12px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    transform: rotate(-45deg);
    position: relative;
    left: 12px;
  }
}
.arrow-right {
  &:before {
    color: #0a0a0a;
    width: 12px;
    content: "";
    display: block;
    height: 12px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(45deg);
    position: relative;
    right: -5px;
  }
}
.applyBtn {
  display: block;
  margin: 0 auto;
  @extend .button;
}
.cancelBtn {
  display: none;
}
.daterangepicker {
  display: none;
  color: inherit;
  background-color: #fff;
  width: 100%;
  padding: 0;
  margin-top: 1px;
  top: 100px;
  @include breakpoint(medium) {
    position: absolute;
    border-top: 10px solid $primary-color;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  @media (max-width: 563px) {
    left: 0 !important;
  }
}
.daterangepicker:before, .daterangepicker:after {
    position: absolute;
    display: inline-block;
    display: none;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}
.daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
    right: 9px;
}
.daterangepicker.opensleft:after {
    right: 10px;
}
.daterangepicker.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
}
.daterangepicker.openscenter:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
}
.daterangepicker.opensright:before {
    left: 9px;
}
.daterangepicker.opensright:after {
    left: 10px;
}
.daterangepicker.dropup {
    margin-top: -5px;
}
.daterangepicker.dropup:before {
    top: initial;
    bottom: -7px;
    border-bottom: initial;
    border-top: 7px solid #ccc;
}
.daterangepicker.dropup:after {
    top: initial;
    bottom: -6px;
    border-bottom: initial;
    border-top: 6px solid #fff;
}
.daterangepicker.dropdown-menu {
    max-width: none;
    z-index: 3001;
}
.daterangepicker.single .ranges, .daterangepicker.single .calendar {
    float: none;
}
.daterangepicker.show-calendar .calendar {
    display: block;
}
.daterangepicker .calendar {
    display: none;
    max-width: 280px;
    margin: 4px auto 0;
}
.daterangepicker .calendar th, .daterangepicker .calendar td {
    white-space: nowrap;
    text-align: center;
    min-width: 32px;
}
.daterangepicker .calendar-table {
    border: 1px solid $light-gray;
    padding: 4px 20px;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
    background-color: #fff; 
    thead {
      font-family: $header-font-family;
      text-transform: uppercase;
    }
}
.daterangepicker table {
    width: 100%;
    margin: 0;
}
.daterangepicker td, .daterangepicker th {
    text-align: center;
    width: 20px;
    height: 32px;
    padding: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: #eee;
    border-color: transparent;
    color: inherit;
}
.daterangepicker td.week, .daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}
.daterangepicker td.off:not(.disabled), .daterangepicker td.off.in-range:not(.disabled), .daterangepicker td.off.start-date:not(.disabled), .daterangepicker td.off.end-date:not(.disabled) {
    background-color: #fff;
    border-color: transparent;
    color: #999; 
    opacity: 0;
    visibility: hidden;
}
.daterangepicker td.in-range {
    background-color: #ebf8ee;
    border-color: transparent;
    color: #000;
    border-radius: 0;
}
.daterangepicker td.start-date {
    border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
    border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
    border-radius: 4px;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $primary-color;
    border-color: transparent;
    color: #fff;
}
.daterangepicker th.month {
    width: auto; 
    color: $dark-gray;
}
.daterangepicker td.disabled.off, .daterangepicker option.disabled.off {
    color: #999;
    cursor: not-allowed;
    opacity: 0.25;
    visibility: visible;
}
.daterangepicker select.monthselect, .daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}
.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%;
}
.daterangepicker select.yearselect {
    width: 40%;
}
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
    width: 60px;
    margin-bottom: 0;
}
.daterangepicker .input-mini {
    border: 1px solid #ccc;
    font-family: $header-font-family;
    text-transform: uppercase;
    color: $body-font-color;
    height: 40px;
    line-height: 40px;
    display: block;
    vertical-align: middle;
    margin: 0 0 5px 0;
    padding: 0.625rem;
    width: 100%;
}
.daterangepicker .input-mini.active {
    border: 1px solid $primary-color;
}
.daterangepicker .daterangepicker_input {
    position: relative;
    padding: 0.5rem 20px;
}
.daterangepicker .daterangepicker_input i {
    position: absolute;
    left: 8px;
    top: 8px;
}
.daterangepicker.rtl .daterangepicker_input i {
    left: auto;
    right: 8px;
}
.daterangepicker .calendar-time {
    margin: 5px auto 2px;
    line-height: 30px;
    position: relative;
    label {
        text-align: left;
    }
}
.daterangepicker .calendar-time select.disabled {
    color: #ccc;
    cursor: not-allowed;
}
.ranges {
    font-size: 11px;
    float: none;
    background-color: $off-white;
    border: 1px solid $light-gray;
    border-radius: 0 0 4px 4px;
    padding: 1rem 0;
    text-align: left;
}
.ranges ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}
.ranges li {
    font-size: 13px;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    color: $primary-color;
    padding: 3px 12px;
    margin-bottom: 8px;
    cursor: pointer;
}
.ranges li:hover {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
}
.ranges li.active {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;
}
/*  Larger Screen Styling */
@media (min-width: 640px) {
  .daterangepicker {
    width: 320px;
  }
  .daterangepicker .calendar {
    margin: 4px auto 0;
  }
  .daterangepicker .ranges {
    width: 100%;
    border-radius: 0;
  }
  .daterangepicker .ranges ul {
    width: 160px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .calendar.left {
    clear: none;
  }
  .daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .calendar {
    float: left;
  }
  .daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .calendar {
    float: right;
  }
  .daterangepicker.ltr {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker.ltr .calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker.ltr.single .calendar.left {
    margin: 0 auto;
    float: none;
  }
}
.daterangepicker.ltr .calendar.right {
    margin-left: 0;
}
.daterangepicker.ltr .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.daterangepicker.ltr .calendar.left .calendar-table {
    padding-right: 12px;
}
.daterangepicker.ltr.single .calendar.left .calendar-table {
    padding-right: 20px;
}
.daterangepicker.rtl {
    direction: rtl;
    text-align: right;
}
.daterangepicker.rtl .calendar.left {
    clear: right;
    margin-left: 0;
}
.daterangepicker.rtl .calendar.left .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.daterangepicker.rtl .calendar.right {
    margin-right: 0;
}
.daterangepicker.rtl .calendar.right .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.daterangepicker.rtl .left .daterangepicker_input {
    padding-left: 12px;
}
.daterangepicker.rtl .calendar.left .calendar-table {
    padding-left: 12px;
}
.daterangepicker.rtl .ranges, .daterangepicker.rtl .calendar {
    text-align: right;
    float: right;
}
@media (min-width: 730px) {
  .daterangepicker.ltr .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .calendar.left {
    clear: none !important;
  }
}
