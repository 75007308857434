// Fleet mgmt animation
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes beam_left {
    0% { opacity: 1; left: 16%; top: 38%; }
    49% { opacity: 1; left: 16%; top: 38%; }
    50% { opacity: 1; left: 16%; top: 38%; }
    74% { opacity: 0; left:36%; top: 15%; }
    100% { opacity: 0; left:36%; top: 15%; }
}
@keyframes beam_mid {
    0% { opacity: 1; left: 49%; top: 38%; }
    49% { opacity: 1; left: 49%; top: 38%; }
    50% { opacity: 1; left: 49%; top: 38%; }
    74% { opacity: 0; left: 49%; top: 22%; }
    100% { opacity: 0; left: 49%; top: 22%; }
}
@keyframes beam_right {
    0% { opacity: 1; left: 84%; top: 38%; }
    49% { opacity: 1; left: 84%; top: 38%; }
    50% { opacity: 1; left: 84%; top: 38%; }
    74% { opacity: 0; left:64%; top: 13%; }
    100% { opacity: 0; left:64%; top: 13%; }
}
// Green on mobile/tablet
.show-bubble {
    animation-name: open;
    animation-fill-mode: forwards;
    animation-duration: 200ms;
}
@keyframes open {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }
    25% {
        opacity: 1;
        transform: scale(0.5);
    }
    50% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-1000px);
    }
    60% {
        opacity: 1;
        transform: translateY(30px);
    }
    80% {
        transform: translateY(0);
    }
    100% {
        opacity: 1;
    }
}
// One click reorder responsive image 
.banner-oneclickreorder.content-banner {
    .image_container {
        @include breakpoint(medium down) {
            height: 360px;
            background-size: auto 100%;
        }
        > img {
            display: none;
        }
    }
}
// Mobile app responsive image 
.banner-cmobileapp.content-banner {
    .image_container {
        @include breakpoint(medium down) {
            background-size: cover;
        }
        @include breakpoint(small only) {
            height: 420px;
            background-position: center;
        }
        > img {
            display: none;
        }
    }
    @include breakpoint(medium only) {
        .info {
            width: 50%;
        }
    }
}