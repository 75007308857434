.location_map_container {
    height:872px;
    #pcMap {
        height: 792px;
    }

    @include breakpoint(large) {
        position: relative;
    }

    .distance {
        .value {
            margin-bottom: rem-calc(8);

            @include breakpoint(medium) {
                font-size: rem-calc(42);
            }
        }
    }

    .location {
        @include breakpoint(large) {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
        }

        > .name {
            margin-bottom: rem-calc(3);
        }
    }

    .location_results {
        @include breakpoint(medium) {
            padding: $grid-spacing;
            padding-top:0px;
        }

        @include breakpoint(large) {
        }
    }
}
.location_map {
    #pcMap {
        min-height: 356px;
    }

    > iframe {
        pointer-events: none;
        display: block;
    }

    &.active > iframe {
        pointer-events: all;
    }
}
.location_results {
    background-color: $white;
    @include breakpoint(large) {
        height: calc(100% - 40px);
    }
    select {
        margin-bottom: 0;
    }
    hr {
        border-bottom-color: $black;
    }
    ul {
        margin-left: 0;
    }
    li {
        list-style-type: none;
        position: relative;
        border-bottom: $hr-border;
        margin-bottom: $grid-spacing*.75;
        .text-link {
            text-transform: uppercase;
            color: $anchor-color;
            font-weight: bold;
            font-family: $header-font-family;
        }
        .distance {
            margin-bottom: 1rem;
            small.miles {
                font-size: rem-calc(24);
                margin-left: -3px;
                text-transform: none;
                color: $black;
            }
        }
        .location {
            margin-bottom: $grid-spacing*.75;
            p:last-child {
                margin-bottom: 0;
            }
        }
        .details  {
            position: absolute;
            top: 0;
            right: 0;
            img {
                margin-bottom: 1rem;
            }
            .button {
                width: 100%;
                @include breakpoint(medium) {
                    width: auto;
                }
            }
            @include breakpoint(medium) {
                position: static;
            }
        }
        &.more-locations {
            border-bottom: none;
            text-align: center;
            margin-top: $grid-spacing*.75;
        }
    }
}
.location_results_list {
    position:relative;
    @include breakpoint(large) {
        height: calc(100% - 126px);
        overflow-y: scroll;
    }
    .pickup_map_container & {
        @include breakpoint(medium) {
            height: calc(100% - 126px);
            overflow-y: scroll;
        }
    }
}
.find-location-form {
    .input-location-wrap {
        .location-btn {
            top: 0;
        }
    }
    .input-group-button {
        .button.search {
            padding: 12px;
            width: 40px;
            height: 40px;
        }
    }
}
.location-title-container {
    max-width: $narrow-max-width;
    text-align: center;

    h1.title {
        font-size: 56px;
        text-transform: initial;
    }
}
.location-store-container {
    margin-bottom: 4rem;
    max-width: $narrow-max-width;
    background: $fake-white;
    @include breakpoint(medium) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    @include breakpoint(large) {
        padding-top: 0;
        padding-bottom: 0;
    }
    p {
        margin-bottom: 1rem;
    }
    .location-store-image {
        margin-bottom: 2rem;
        @include breakpoint(small only) {
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(large) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 3rem;
        }
    }
    .contact_info {
        .title {
            margin-bottom: rem-calc(4);
        }
    }
}
.location-store-info {
    max-width: $narrow-max-width;
    margin-bottom: $global-margin *2;
    @include breakpoint(large) {
        margin-bottom: $global-margin *4;
    }
    .is_open {
        padding-top: rem-calc(5);
        display: block;
        font-size: rem-calc(14);
        font-family: $body-font-family;
        font-style: italic;
        text-transform: initial;
    }
}
.location-hours {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
    margin-bottom: $grid-spacing;
    .item {
        .day {
            width: 100px;
            display: inline-block;
        }
        .time {
        }
    }
}
.location-store-reps {
    list-style-type: none;
    margin-left: 0;
    display: inline-block;
    li {
        margin-bottom: 1rem;
    }
    .name,
    .phone,
    .email {
        display: block;
    }
}
#LocationsSearchBar {
    .ui-helper-hidden-accessible {
        display:none;
    }
}
#locationssearchcontainer {
    h1.capital-case{
        text-transform:capitalize;
    }
    #LocationsSearchBar {
        border: 0px;
        background-color: initial;
        padding: 0px;
        margin: 0px;
        #LocationsSearchButton {
            margin-left:10px;
        }
    }
}