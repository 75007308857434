﻿$color-red: #C03A3A;
$color-green: #008545;
$color-black: #333333;

@mixin suggester-entered-text {
    color: $color-black;
    font-size: 14px;
    font-weight: bold;
}

.checkout-verify-addres-modal {
    background-color: red;

    .modal-window{
        width: 90%;
        height: 90%;
        padding: 30px 60px;
        overflow-y: scroll;
        @media screen and (max-width: 767px){
            padding: 45px 10px;
        }
    }

    .primary-button{
        padding-top: 0;
        @media screen and (max-width: 767px){
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }
    }

    .edit-entered-address{
        margin-top:10px;
        }

    .disable-button {
        background: #ccc;
        pointer-events: none;
        cursor: not-allowed;
    }

    .modal-sub-text {
        margin-bottom: 8px;
        width: 45%;
        @media screen and (max-width: 767px){
            width: 90%; 
        }
    }

    .addr-list {
        width: 56%;
        margin: 0% 22%;

        @media screen and (max-width: 767px){
            width: 90%;
            margin: 0;
        }

        .error-text {
            color: $color-red;
        }

        .correction-text {
            color: $color-green;
        }

        .address-continer-1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            
            @media screen and (max-width: 767px){
                margin-left: 10px;
                flex-direction: column;
                > div.suggested-address {
                    margin: 0px 12px;
                }
            }

            > div.entered-address {
                margin: 0px 12px;

                > div {
                    &:first-child {
                        > span {
                            @include suggester-entered-text;
                        }
                    }

                    &:nth-child(2) {
                        display: flex;
                        flex-direction: column;
                        margin: 4px 4px 4px 0px;
                    }
                }
            }

            > div.suggested-address {
                > div {
                    &:first-child {
                        > span {
                            @include suggester-entered-text;
                            margin: 4px 4px 4px 0px;
                        }
                    }

                    &:nth-child(2) {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .address-container-2 {
            @media screen and (max-width: 767px){
            margin-left: 20px;
            }
            div.entered-address {
                margin-bottom: 24px;

                > div:nth-child(1) {
                    margin-bottom: 8px;

                    > span {
                        @include suggester-entered-text;
                    }
                }

                > div:nth-child(2) {
                    > span {
                    }
                }
            }

            div.suggested-address {
                > div {
                    &:first-child {
                        margin-bottom: 12px;

                        > span {
                            @include suggester-entered-text;
                        }
                    }
                }

                .suggestion-container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    @media screen and (max-width: 767px){
                        flex-direction: column;
                    }

                    .radio-input {
                        flex: 26%;
                        .radio-label {
                            display: flex;
                            flex-direction: column;

                            span {
                                font-weight: normal;
                            }
                        }

                        .checkmark {
                            margin-top: 24px;
                            border: 1px solid #008545;
                            height: 24px;
                            width: 24px;
                        }
                        .checkmark:after {
                            top: 3px;
                            left: 3px;
                        }
                    }
                }
            }
        }
    }

    .button-row{
        justify-content: center;
    }
}
