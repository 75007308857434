.curbside-banner {
    max-height: 100px;
    overflow:hidden;
    position:relative;
    border-bottom: 2px solid #E4E4E4 
}
.curbside-icon {
    height: 60px;
    width: 60px;
    max-width: 60px !important;
    margin-top: -3px;
    position: absolute;
}
.curbside-title {
    color: #333333;
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    width: 408px;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 21px;
    margin-left: 83px;
}
.curbside-text {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 17px;
    text-align: left;
    margin-left: 83px;
}
.curbside-button {
    border: 4px solid #FFFFFF;
    text-decoration: none;
    padding: 12px 75px;
    position: absolute;
    right: -350px;
}
.curbside-link {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 1;
}
.curbside-divider {
    position: absolute;
    right: -24px;
    top: -10px;
    z-index: -1;
}
.curbside-bg-container {
    max-height: 150px;
    max-width: 150px;
    min-height: 150px;
    min-width: 150px;
    position: relative;
    margin: auto;
}
.curbside-bg {
    position: absolute;
    -ms-transform: rotate(45);
    transform: rotate(-45deg);
    right: -1208px;
    width: 2000px;
    height: 1000px;
    min-height: 300px;
    background: #008545;
    z-index: -2;
}
.curbside-mini-link {
    display: none;
}
@media screen and (max-width: 900px) {
    .curbside-button {
        display: none;
    }
    .curbside-bg {
        right: -1208px;
    }
    .curbside-text {
        display: none;
    }
    .curbside-mini-link {
        display: initial;
        color: #008545;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 17px;
        text-align: left;
        text-decoration: none;
        margin-left: 55px;
    }
    .curbside-title {
        color: #222222;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        width: 408px;
        text-align: left;
        margin-bottom: -2px;
        margin-top: 21px;
        margin-left: 55px;
    }
    .curbside-icon {
        height: 43px;
        width: 43px;
        max-width: 43px !important;
        margin-top: -3px;
        position: absolute;
    }
    .curbside-banner {
        max-height: 75px !important;
    }
}
@media screen and (max-width: 640px) {
    .curbside-divider {
        right: -100px;
    }
    .curbside-bg {
        right: -1280px;
    }
    .curbside-title {
        margin-top: 10px;
        font-size: 14px;
    }
    .curbside-banner {
        max-height: 60px !important;
    }
}