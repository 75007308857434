﻿div.checkout-complete .Buttons {
    text-align: right;
    span {
        padding-left: 3px;
        padding-right: 3px;
        font-weight: bold;
    }
    a.button {
        margin-bottom: 0px;
    }
}
