﻿#CreditApplicationForm {
    a {
        color: inherit;
        font-weight: 600;
    }

    .prettygreen {
        color: $brand-green;
    }

    .formChoices {
        font-weight: bolder;
        padding-bottom: 11px;
        border-bottom: 1px solid #d4d4d4;
        margin-bottom: 20px;
        margin-right: 50px;
        margin-left: 50px;
    }

    .credit-app-home-block {
        text-align: center;
        margin-top: 15px;
        padding-bottom: 20px;

        p {
            margin-top: 20px;
            margin-bottom: 20px;
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    /**
     *  The below drop down lists in the Credit App need more space in the margin-top 
     *  compared to the default '.form-error' class to display properly
     **/
    #PhysicalStates-error {
        display: none;
        margin-top: 0;
        margin-bottom: 1.25rem;
        font-size: 0.85714rem;
        font-weight: bold;
        color: #cc4b37;
    }

    #PhysicalStates-error.is-visible {
        display: block;
    }

    #BillingStates-error {
        display: none;
        margin-top: 0;
        margin-bottom: 1.25rem;
        font-size: 0.85714rem;
        font-weight: bold;
        color: #cc4b37;
    }

    #BillingStates-error.is-visible {
        display: block;
    }

    #BusinessTypes-error {
        display: none;
        margin-top: 0;
        margin-bottom: 1.25rem;
        font-size: 0.85714rem;
        font-weight: bold;
        color: #cc4b37;
    }

    #BusinessTypes-error.is-visible {
        display: block;
    }

    #ddJobTitles-error {
        display: none;
        margin-top: 0;
        margin-bottom: 1.25rem;
        font-size: 0.85714rem;
        font-weight: bold;
        color: #cc4b37;
    }

    #ddJobTitles-error.is-visible {
        display: block;
    }
}
.credit-app-response-block {
    margin-bottom:100px;
}
#CreditApplicationGetStarted, #CreditApplicationMain {
    margin-bottom: 20px;

    .form-group {
        margin-bottom: 10px;
    }

    overflow-x: hidden;

    .k-dropdown {
        width: 100%;
    }

    .radioOff {
        background: no-repeat url(/Content/images/Shared/Radial-Not-Selected.png);
        width: 32px;
        height: 32px;
        display: inline-block;
        margin: 0px 6px -8px 0px;
        border: none;
    }

    .radioOn {
        background: no-repeat url(/Content/images/Shared/Radial-Selected.png);
        width: 32px;
        height: 32px;
        display: inline-block;
        margin: 0px 6px -8px 0px;
        border: none;
    }

    .radio-label {
        display: inline-block;
        vertical-align: top;
        padding-top: 4px;
    }

    .sb-checkbox-group {
        img {
            float: left;
            margin-right: 10px;
        }

        label {
            font-weight: normal;
            display: inline;
        }
    }

    #btn-CreditApplicationGetStarted {
        margin-top: 10px;
    }

    #btn-CreditApplicationGetStarted, #btn-CreditApplicationVerificationCodeConfirm, #btn-CreditApplicationGetStartedContinue {
        margin-bottom: 20px;
    }

    .Note {
        color: dimgrey;
        font-size: .62em;
    }

    .row > .SubText {
        font-size: 11px;
        margin-bottom: 10px;
        color: #909090;
    }

    div.Section {
        margin-bottom: 10px;
        margin-top: 12px;
    }

    .Section {


        label {
            font-size: 14px;
            color: #b1b1b1;
        }
    }

    #last-buttons {
        a:first-child {
            margin-right: 10px;
        }
    }

    .medText {
        color: dimgrey;
        font-size: .8em;
    }

    .formLabelSubText {
        padding: 0px 0px 0px 0px !important;
    }

    #register {
        margin-left: 100px;
    }

    #register label {
        margin-right: 5px;
    }

    #register input {
        padding: 5px 7px;
        border: 1px solid #d5d9da;
        box-shadow: 0 0 5px #e8e9eb inset;
        width: 250px;
        font-size: 1em;
        outline: 0;
    }

    #password-strength.short {
        color: #FF0000;
    }

    #password-strength.weak {
        color: #E66C2C;
    }

    #password-strength.good {
        color: #2D98F3;
    }

    #password-strength.strong {
        color: #006400;
    }
    .billing-sync {
        white-space: nowrap;
        margin-bottom: 4px;

        div {
            font-size: 12px;
            color: #737373;
            vertical-align: middle;
            display: inline-block;
            white-space: normal;
            padding-right: 20px;
        }
    }
    #terms {
        float: right;
        width: 200px;
        margin: 0px 0px 0px 0px;
        height: 29px;
    }
}

#ExperianSimilarAddressDialog, #WynneAddressToUseDialog, #ExperianCorrectedAddressDialog {
    .col-option {
        margin-bottom: 5px;
    }

    .row-option {
        background-color: #f1f1f1;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
    }

    .row {
        margin-bottom: 16px;
    }

    .SBGreenButton {
        margin-bottom:10px;
    }
    div#AddressDialogText {
        margin-bottom: 10px;
    }

    .heading {
        font-weight: bold;
        margin-bottom: 2px;
    }
}

.LeftContent {
    .PageHeader {
        margin-top:20px;
    }
}
