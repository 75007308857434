.guest {
    box-sizing: border-box !important;
    border: 2px solid $button-green !important;
    background-color: #F3F3F3 !important;
    color: $button-green !important;
}
.blackborder {
    margin-top:30px;
    box-sizing: border-box !important;
    border: 2px solid black !important;
    background-color: #F3F3F3 !important;
    color: black !important;
    width:80% !important;
}
.disabled-button {
    //Fix for REFSL-528 changed the disabled button color to grey
    //background-color: $button-green !important;
    background: #ccc !important;
    opacity: 0.35;
    pointer-events: none;
}
.disabled-button-green {
    background-color: #008545 !important;
    opacity: 0.35;
    pointer-events: none;
}
.iframe-button {
    margin-bottom: 50px !important;
}
.edit-button {
    color: #3C76C3;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 18px;
    text-decoration: none;
    margin-left: 7px;
}
.primary-button {
    padding-left: 58px;
    padding-right: 58px;
    padding-top: 14px;
    cursor: pointer;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 28px;
    height: 50px;
    text-decoration: none;
    text-align: center;
    background-color: $button-green;
}
.primary-button:hover {
    color: #FFFFFF;
}
.secondary-button:hover {
    color: $button-green;
}
.secondary-button {
    text-align: center;
    cursor: pointer;
    color: $button-green;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.23px;
    line-height: 22px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 16px;
    text-decoration: none;
}
.form-adjacent-button {
     cursor: pointer;
     line-height: 22px;
     float: right;
     text-decoration: none;
     border: 2px solid $button-green;
     height: 50px;
     width: 53px;
     padding-top: 13px;
     color: $button-green;
     font-family: Roboto;
     font-size: 14px;
     font-weight: 500;
     letter-spacing: 0.1px;
     line-height: 23px;
     text-align: center;
}
.remove-from-list-button {
     background: url('/content/img/X.svg') no-repeat;
     text-align: center;
     cursor: pointer;
     color: $button-green;
     float: right;
     text-decoration: none;
     border: none;
     margin-top: 4px;
     height: 12px;
     width: 12px;
     margin-right: 27px;
}
.add-button-with-icon {
    background: url('/content/images/icons-svg/plus-green-2.0.svg') no-repeat;
    background-position-x: left;
    background-position-y: 3px;
    color: $button-green;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 24px;
    padding-left: 2rem;
    text-align: left;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
    }
}
.action-button-within-input {
    color: #222222;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    position: absolute;
    right: 1rem;
    text-align: right;
    top: calc(50% - 12px);
    &:hover {
        cursor: pointer;
    }
}
.edit-button-adjacent-text {
    margin-left: 1rem;
    &:hover {
        cursor: pointer;
    }
    img {
        width: 16px;
    }
}