.quote-wrapper {
    .shopping {
        max-width: 330px;
    }
    .empty-quote {
        &-img {
            margin-top: 2rem;
        }
        &-text {
            margin-top: 3rem;
        }
    }
    .btn {
        color: #008545;
        font-size: 16px;
        font-weight: 700;
        font-family: 'Roboto Condensed', sans-serif;
        font-style: normal;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0;
        line-height: 1;
        border: 0;
        border-radius: 0;
        padding: 19px 40px;
        background: transparent;
        display: inline-block;
    
        &.btn--outline {
            color: #008545;
            background: transparent;
            border: 4px solid #008545;
            padding: 13px 32px 13px 32px;
            width: 250px;
            max-width: 100%;
    
            &:hover {
                color: #005E31;
                background: transparent;
                border-color: #005E31;
            }
        }
        @media (min-width: 768px) {
            padding: 19px 40px;
        }
    }
}