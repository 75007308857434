﻿.cart-items-review-container {
    .items {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #979797 !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        color: #333333;
        text-align: left;
        padding-bottom: 14px !important;

        .item-image {
            width: 35%;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;

                @media only screen and (max-width: 767px) {
                    width: 90px;
                    height: 90px;
                }
            }
        }

        .item-desc {
            width: 50%;

            .item-name {
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.13px;
                width: 80%;

                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.11px;
                    width: 100%;
                }
            }

            .item-price {
                font-style: italic;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.256667px;
                padding-top: 5px;

                @media only screen and (max-width: 767px) {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.22px;
                }
            }

            .item-quantity {
                margin-top: 20px;

                .qty-text {
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 1.22px;

                    @media only screen and (max-width: 767px) {
                        font-weight: bold;
                    }
                }

                .qty {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 29px;
                }
            }
        }

        .item-total-cost {
            width: 30%;
            display: flex;
            font-weight: bold;

            @media only screen and (max-width: 767px) {
                font-weight: bold;
            }

            span {
                align-self: flex-end;
                width: 100%;
                text-align: right;
            }
        }
    }
}
