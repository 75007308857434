﻿#yourcart-page-instance {
   .padding-textbox {
       padding-left: 3%;
       padding-right: 3%
   }
   .pickup-button {
       display: flex;
       justify-content: center;
       flex-wrap: wrap;
       padding: 0 1%;
       padding-bottom: 4.18%;
   }
   .pickup-up-or-delivery {
       padding-bottom: 9.94%;
   }
   .pickup-delivery-div {
       padding-top: 6.2%;
   }
   .padding-controls {
       padding-left: 1%
   }
   .padding-sides {
       padding-right: 2%
   }
   .div-left {
       text-align: left
   }
   .keyline {
       box-sizing: border-box;
       height: 2px;
       width: 560px;
       border: 1px solid #979797;
   }
   .rental-duration-right {
       .rental-duration-inputs {
           @include breakpoint(xmedium) {
               float: right;
           }
       }
   }
   .rental-duration-inputs {
       width: 333px;
       @include breakpoint(medium down) {
           margin-left: auto;
           margin-right: auto;
       }
       .duration-time {
           width: 110px;
           float: right;
       }
       .duration-date {
           float:left;
           padding-right: 10px;
       }
   }
   .login-options {
       margin-top: 22px;
   }
   .login-input {
       margin-bottom: 16px;
   }
   #cm-submit-button {
       width: 100%;
       @include breakpoint(medium) {
           max-width: 200px;
       }
   }
   .continue-as-guest-container {
       @include breakpoint(small only) {
           text-align: center;
       } 
       a {
           text-decoration: none;
           line-height: 50px;
           font-weight: bold;
           font-size: 16px;
           color: #007a39;
       }
   }
   .cart-totals {
       margin-top: 32px;
       margin-bottom: 50px;
       .show-mobile-mini-cart-only {
           display: none;
       }
   }
   // use for non mini-cart
   .cart-items {
       @include breakpoint(medium) {
           .row.desc {
               margin-bottom: 24px;
               .title {
                   font-size: 18px;
                   line-height: 28px;
               }
           }
           > .row {
               padding: 32px 0 0px;
           }
           > .row::after {
               padding-top: 14px;
           }
        .price, .qty {
            font-size: 16px;
        }
        img {
            max-height: 130px;
        }
        }
        > .row:first-child {
            padding-top: 0px; 
        }
    }
}