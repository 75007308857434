﻿// My Account Styles
// ------------------------------------------------------
#QuickRent {
    .k-tabstrip-wrapper {
        #QuickRentTabs {
            outline: none;

            .k-tabstrip-items {
                margin: 0;
                padding: 0;
                list-style-type: none;
                height: 40px;

                .k-item {
                    margin: 0;
                    float: left;
                    margin: 0;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border: 1px solid $cm-lightgrey;
                    cursor: pointer;

                    &.k-state-active {
                        border-bottom: none;

                        .k-link {
                            .fa {
                                color: $cm-darkgrey;
                            }

                            .qr-tab-label {
                                color: $cm-darkgrey;
                            }
                        }
                    }

                    .k-loading {
                    }

                    .k-link {
                        padding: 8px 13px;
                        display: block;

                        .fa {
                            color: $cm-darkgreen;
                        }

                        .qr-tab-label {
                            display: none;
                            color: $cm-darkgreen;
                            float: right;

                            @media(min-width: 768px) {
                                display: block;
                            }
                        }
                    }
                }
            }

            .k-content {
                > div {
                    .Summary {
                        font-weight: 900;
                        text-align: right;
                        font-size: 1rem;
                        padding: 5px 15px;
                    }

                    .Items {
                        table {
                            tbody {
                                .ItemRowAlt {
                                    background-color: $cm-lightgrey;
                                }

                                tr {
                                    th {
                                        > div {
                                            > div {
                                                input {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// ------------------------------------------------------
#CreateAccount {
    padding: 20px;

    #CreditAccount {
        h1 {
            padding-top: 48px;
            margin-bottom: 0px;
            margin-top: 0px;
            font-size: 28px;
            padding-bottom: 15px;
            color: $cm-darkgreen;
        }
    }

    #password-strength.short {
        margin-left: 30px;
        color: #FF0000;
    }

    #password-strength.weak {
        margin-left: 30px;
        color: #E66C2C;
    }

    #password-strength.good {
        margin-left: 30px;
        color: #2D98F3;
    }

    #password-strength.strong {
        margin-left: 30px;
        color: #006400;
    }
}
.hide-notification-checkbox {
    display: none;
    color: $cm-lightgrey;
}