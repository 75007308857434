.month-selector-dropdown {
    box-sizing: border-box;
    width: 332px;
    border: 1px solid #DDDDDD;
    background-color: #FFFFFF;
    box-shadow: -10px 0 20px 5px rgba(0,0,0,0.08);
    padding: 21px 24px 19px 12px;
    position: absolute;
    z-index: 177;
    margin-top: -10px;
}
.minicart-calender {
    margin-top: 40px !important;
    width: 325px !important;
    padding: 21px 24px 19px 12px !important;
}
.calender-overflow {
    z-index:180 !important;
    position:fixed !important;
    margin-top:0px !important;
}
.month-selector-header {
    height: 19px;
    width: 31.89px;
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 19px;
    z-index: 2;
}
.month-selector-days {
    overflow: hidden;
    width: 282px;
    margin-left: 13.95px;
    z-index: 2;
    position: relative;
}
.month-selector-day {
    width: 35.88px;
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 28px;
    text-align: center;
    float: left;
    height: 25px;
    margin-bottom: 12px;
    margin-right: 4px;
    z-index: 3;
    position:relative;
}
.month-selector-weekend {
    width: 35.88px;
    color: #BBBBBB;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 28px;
    text-align: center;
    float: left;
    height: 25px;
    margin-bottom: 12px;
    margin-right: 4px;
    z-index: 3;
    position: relative;
    padding-top: 4px;
}
.month-selector-dayrow {
    margin-bottom: 12px;
    z-index: 2;
}
.month-selector-footer {
    position: absolute;
    right: 25.95px;
    overflow: hidden;
    width: 125px;
    z-index: 2;
}
.month-selector-previous {
    color: #008545;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 22px;
    float: left;
    z-index: 2;
    cursor: pointer;
}
.month-selector-next {
    color: #008545;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 22px;
    float: right;
    z-index: 2;
    cursor: pointer;
}
.day-selected-circle {
    display: block;
    height: 35px;
    width: 35px;
    background-color: #008545;
    position: absolute;
    top: 0;
    z-index: 2;
}
.day-button {
    z-index: 3;
    position: relative;
    height: 35px;
    width: 35px;
}
.day-button-selected {
    z-index: 3;
    position: relative;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 28px;
    text-align: center;
    height: 35px;
    width: 35px;
}

.day-header{
    font-weight:bold;
}
