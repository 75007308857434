﻿#locationPopupBox {
    background-color: #fff;
    color: #464646;
    padding: 12px;
    font-size: 12px;
}
#locationPopupContainer {
    z-index: 3;
    a.button {
        margin-bottom: 0px;
    }
}
.location-detail-popup {
    #locationPopupFulldetailLink, #locationPopupGetDirectionsLink {
        display: none;
    }
}
#locationPopupBoxContent {
    #locationPopupBoxContentContent {
        background-color: white;
        padding: 7px;
        margin-bottom: 2px;
        border-radius: 0px;
        font-family: Helvetica, Sans-Sarif;
        width: 267px;
    }
    #locationPopupLocationName {
        margin-bottom: 3px;
        font-weight: bold;
        color: green;
        font-size: 14px;
    }
    #locationPopupLocationTop {
        display: inline-block;
        height: 40px;
        float: left;
        margin-right: 10px;
        margin-left: 10px;
    }
    #locationPopupLocationBranch {
        display: block;
        font-weight: bold;
        color: #858585;
    }
    #locationPopupLocationBranchNumber {
        font-size: 20px;
        vertical-align: top;
        line-height: 21px;
        color: green;
        font-weight: bold;
    }
    #locationPopupLocationBranchNumberSymbol {
        color: green;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
    }
    #locationPopupLocationAddress {
        height: 40px;
        display: inline-block;
        font-weight: bold;
        color: #858585;
        padding-left: 4px;
        width: 180px;
    }
    #locationPopupGetDirectionsLink {
        margin-left: 10px;
        width: 150px;
    }
}
#locationPopupBoxClose {
    position: absolute;
    display: inline;
    top: 18px;
    right: 18px;
}
#locationPopupStalk {
    background-image: url('/Content/images/Locations/locations-pointer_shadow-grey.png');
    position: absolute;
    width: 28px;
    height: 31px;
    bottom: -30px;
    left: 40px;
}
