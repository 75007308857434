﻿#CheckoutRollover {
    min-height: 300px;
    .title {
        font-size: 1.4em;
        font-weight: bold;
        margin-bottom: 4px;
    }
    .subtitle {
        font-size: 1.1em;
        color: grey;
        margin-bottom: 8px;
    }
}
#WynneCheckoutRollover {
    min-height: 300px;
    .title {
        font-size: 1.4em;
        font-weight: bold;
        margin-bottom: 4px;
    }
    .subtitle {
        font-size: 1.1em;
        color: grey;
        margin-bottom: 8px;
    }
}
