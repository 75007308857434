.mt-2{
    margin-top:  2rem;
}
.mb-2{
    margin-bottom: 2rem;
}

.optionals-margin {
    padding-top: 24px !important;
}

.hide-learn {
    display:none;
}

.cart-totals {
    font-size: 14px;
    color: #333333;
    font-family: Roboto;
    line-height: 24px;

    @include breakpoint(medium) {
        line-height: 28px;
        font-size: 16px;
    }

    img.tool-tip {
        padding-left: 8px;
    }

    .sub-text {
        font-size: 11px;
    }

    .empty-cost {
        width: 8px;
        border-top: 1.5px solid #333333;
        margin-top: 12px;
    }

    .cost {
        float: right;

        @media only screen and (max-width: 767px) {
            font-weight: bold;
        }
    }

    .cost-bold {
        font-weight: bold;
    }

    .right {
        float: right;
    }

    .sub-total {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.18px;

        @include breakpoint(small) {
            padding-top: 9px;
        }

        div {
            display: inline-block;
        }
    }

    .tax-desc {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.18px;
        margin-bottom: 20px;

        @include breakpoint(small) {
            padding-top: 9px;
        }

        div {
            display: inline-block;
        }
    }

    .loading-placeholder.text {
        height: 16px;
        min-width: 65px;
        margin-top: 5px;
    }

    span.env-fee {
        max-width: 160px;
        display: inline-block;

        @include breakpoint(370px) {
            max-width: initial;
            display: inline;
        }
    }

    .fee-options {
        .fee-options-header {
            font-family: Roboto;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            color: #222222;
            text-align: left;
            padding: 20px 0;

            @media only screen and (max-width: 767px) {
                font-weight: bold;
                font-size: 18px;
                line-height: 20px;
                color: #222222;
            }
        }

        .fee-option {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            &.terms-margin {
                margin-top: 5rem;
            }           
        }

        .terms-required{
            font-size: 11px;
            font-style:italic;
            color: #C03A3A;
            display: block;
            text-align:left;
        }
    }

    .cost-container {
        width: 15%;
    }

    .fee-option-info {

        width:85%;

        .fee-option-subheader {
            font-family: Roboto;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
            text-align: left;

            @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.23px;
            }
        }

        .fee-option-info-text {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.2px;
            color: #333333;
            text-align: left;

            .blue-text {
                padding-left: 0;
                color: #3C76C3;
                text-decoration:none !important;
            }
        }
    }

    .seperator {
        border-bottom: 1px solid #979797 !important;
        padding-top: 2%;
        margin-bottom: 5%;
        display: block !important;
    }

    .align-left {
        text-align: left;
    }
}

.mini-cart-totals .cart-totals {
    font-size: 14px;
    .sub-total {
        font-family: "Roboto Condensed";
    }
}
