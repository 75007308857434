﻿#QuickRentTabs, #InvoiceTabs, #AccountActivityTabs {
    // Clear tab strip outside border and background
    &.k-tabstrip {
        border: none;
        background-color: #ffffff;
        background-image: none;
    }
    .k-item {
        border: 1px solid #e2e2e2;
        border-bottom: none;
    }
    div.k-content {
        min-height: 360px;
        overflow: auto;
        padding-left: 0px;
        padding-right: 0px;
        border: solid 1px #e2e2e2;
    }
}
#QuickRentTabs, #InvoiceTabs {
    a.k-link {
        font-weight: 500;
    }
}