﻿#ToolFlexPackage {
    padding: 15px;
    background-color: #009c39;
    .ItemImage {
        margin-right: 12px;
    }
    .ShadowBox {
        margin-bottom: 0px;
    }
    .tf-item-title {
        color: #ffffff;
        font-size: 0.82em;
        font-weight: 100;
    }
    .tf-item-details {
        float: right;
        @media (max-width: $screen-sm-max) {
            float: none;
        }
    }
    #tf-package-header {
        padding-bottom: 20px;
        color: #ffffff;
        font-size: 1.6em;
        font-weight: bold;
        .flex-accent {
            color: #FFDF00;
            font-style: italic;
        }
        .tf-price {
            float: right;
            text-align: right;
            font-weight: normal;
        }
    }
    #tf-package-footer {
        color: rgba(255, 255, 255, 0.64);
        font-size: 15px;
        margin-top: 10px;
        font-weight: bold;
        a {
            color: #ffdf00;
        }
    }
    .ShadowBoxContent {
        background-color: white;
    }
    .tf-item-remove {
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.75);
        width: 174px;
        border-radius: 6px;
        padding: 2px 2px 2px 5px;
        margin-top: 4px;
        color: rgba(255, 255, 255, 0.74);
        font-size: 14px;
    }
    .tf-empty-item-container, .tf-item {
        display: inline-block;
        margin-bottom: 10px;
    }
    .tf-empty-item-container {
        .tf-item-title {
            color: rgba(255, 255, 255, 0.5);
            text-transform: uppercase;
        }
        .ShadowBoxContent {
            border: 5px dashed rgba(255, 255, 255, 0.5);
            background-color: initial;
        }
    }
}
.toolflex-add-button {
    vertical-align:middle;
    font-weight: bold;
    color: #F7F7F7;
    cursor: pointer;
    background-color: #009C39;
    display:inline-block;
    padding: 3px 6px 3px 5px;
    border-radius: 4px;
    margin-left: 63px;
    font-size: 13px;
    .flex-accent {
        color: #FFDF00;
        font-style: italic;
    }
}
