.top-bar {
    padding: 0;
    display: flex;
    position: relative;
    padding-bottom: 70px;
    @include breakpoint(large) {
        position: static;
        padding-bottom: 0;
    }
}
.top-bar .top-bar-left {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 250px;
    text-align: center;
    @include breakpoint(large) {
        width: auto;
        flex: 2 0 118px;
    }
    @media (min-width: 1279px) and (max-width: 1378px) {
        flex: 1 0 118px;
    }
    ul.menu {
        height: auto;
        width: auto;
        margin-right: 0;
        display: block;
        .menu-icon {
            margin-top: 14px;
            margin-left: 15px;
            margin-right: 15px;
            width: auto;
            height: auto;
            font-family: $header-font-family;
            font-size: rem-calc(10);
            text-transform: uppercase;
            font-weight: $header-font-weight;
            color: $primary-color;
            padding-top: rem-calc(20);
            &:after {
                left: 50%;
                transform: translateX(-50%);
                width: 20px;
                box-shadow: 0 5px 0 $primary-color, 0 10px 0 $primary-color;
            }
        }
        > li > a,
        > li > span {
            height: auto;
            &.logo {
                padding: 0;
                > img {
                    height: 50px;
                    @include breakpoint(large) {
                        height: auto;
                    }
                }
            }
        }
    }
    .logo > img {
        width: 118px;
    }
    @include breakpoint(medium down) {
        .logo > img {
            width: auto;
        }
    }
}
.top-bar .top-bar-right {
    position: relative;
    width: auto;
    flex: 0 0 60px;
    @include breakpoint(medium) {
        flex: 0 0 80px;
    }
    .cart.button {
        width: 100%;
        height: 100%;
        padding: 2px;
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        justify-content: center;

        &::before {
            display: block;
            content: "";
            width: 30px;
            height: 14px;
            background: url('/content/img/cart.svg');
            background-repeat: no-repeat;
            margin-top: 8px;
            margin-bottom: 5px;

            @include breakpoint(large) {
                margin-bottom: 10px;
            }
        }

        .count {
            background: $black;
            border-radius: 9px;
            padding: 3px;
            font-size: 12px;
            line-height: 12px;
            min-width: 18px;
            position: absolute;
            bottom: 50%;
            right: 50%;
            margin-bottom: 3px;

            @include breakpoint(large) {
                margin-bottom: 8px;
            }

            margin-right: 2px;
            font-family: $body-font-family;
        }
    }
}
header.compact {
    background-color: $topbar-background;
}
header.compact .top-bar {
    position: relative;
    padding-bottom: 0;
    border-top: none;
    .top-bar-left {
        max-width: 187px;
        @media (max-width: 345px) {
            max-width: 137px;
        }
        .logo > img {
            margin: 4px 0 4px $grid-spacing/2;
            width: 170px;
            height: 50px;
            padding: 0;
        }
    }
    .top-bar-printemail {
        display: flex;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        color: $dark-gray;
        font-size: rem-calc(11);
        a {
            color: $dark-gray;
            font-size: rem-calc(11);
            &:first-child {
                padding-right: 0.75rem;
            }
            &:last-child {
                padding-left: 0.75rem;
            }
            > img {
                margin-right: 0.25rem;
            }
        }
    }
}
// Top Bar Navigation (Desktop)
.top-bar .top-bar-navigation {
    flex: 20 1 0;
    @include breakpoint(large) {
        margin-right: 30px;
    }
    .top-bar_search-input {
        background: linear-gradient(#ECECEC, $off-white, $white);
        @include breakpoint(large) {
            background: $white;
        }
    }
    @include breakpoint(medium down) {
        $search-form-height: 70px;
        .search-form {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0;
            margin-bottom: 0;
            .top-bar_search-input {
                margin-left: 0;
                display: block;
                width: 100%;
                height: $search-form-height;
                font-size: 1.5rem;
                line-height: $search-form-height;
                padding-left: $search-form-height;
                border: none;
                border-top: 1px solid #ECECEC;
            }
            .input-group-button .button {
                position: absolute;
                left: 0;
                top: 0;
                height: $search-form-height;
                width: $search-form-height;
                padding: 20px;
                background: none;
            }
        }
    }
}
.search-complete-dropdown {
    display: none;
    z-index: 55;
    position: absolute;
    top: inherit;
    left: inherit;
    width: 100%;
    background-color: $white;
    border: 1px solid $light-gray;
    border-width: 0 1px 1px;
    padding: 0;
    margin-top: 0;
    @include breakpoint(large) {
        top: inherit;
        left: inherit;
        width: auto;
        border-width: 0 1px 1px;
        margin-left: 15px;
    }
    @media (min-width: 1279px) and (max-width: 1378px) {
        margin-left: 7px;
    }
}
.search-complete-list {
    list-style-image: none !important;
    background-color: transparent;
    list-style-type: none;
    margin: 0;
    line-height: 1.8;
    > li {
        color: $dark-gray;
        > a {
            display: block;
            padding: 4px $form-spacing / 2;
            &:hover {
                strong {
                    color: $primary-color;
                }
            }
        }
        &:hover {
            background-color: lighten($light-gray, 6%);
        }
        &.search-complete-cat {
            > a, > span {
                color: lighten($dark-gray, 20%);
                padding: 6px $form-spacing / 2;
                margin-left: 1rem;
                font-size: rem-calc(12);
                letter-spacing: 0.025em;
                font-weight: normal;
            }
        }
    }
}
.search-complete-more {
    border-top: 1px solid $light-gray;
    background-color: lighten($light-gray, 6%);
    display: block;
    font-size: rem-calc(11);
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}
.top-bar-navigation {
    padding-top: 20px;
    > .row {
        margin: 0;
    }
    @media (min-width: 1816px) {
        > .row {
            max-width: none;
        }
    }
    .menu.main-menu {
        display: none;
        @include breakpoint(large) { display: block; }
        @media (min-width: 1279px) and (max-width: 1378px) {
            display: flex;
            justify-content: space-between;
            width: 63%;
        }
    }
}
.top-bar-navigation .input-group {
    @media (min-width: 1024px) and (max-width: 1278px) {
        width: 50%;
        padding-right: 20px;
    }   
}
.top-bar .top-bar-navigation input.input-group-field {
    max-width: none;
    margin-left: 15px;
    width: calc(100% - 15px);
    @media (min-width: 1279px) and (max-width: 1378px) {
        margin-left: 7px;
        width: calc(100% - 7px);
    }
}
.top-bar_menu-wrap {
    @media (min-width: 1024px) and (max-width: 1278px) {
        display: flex;
        flex-wrap: wrap;
    }
}
.top-bar .menu {
    display: block;
    float: left;
    height: 50px;
    @media (min-width: 1024px) and (max-width: 1278px) {
        order: 2;
    }
}
.top-bar-navigation .menu>li {
    padding: 0;
    &.active > a {
        background: none;
        position: relative;
        border-bottom: 2px solid $primary-color;
    }
}
.top-bar-navigation .menu>li>a {
    display: inline-block;
    font-family: $header-font-family;
    font-size: rem-calc(18);
    color: $primary-color;
    text-transform: uppercase;
    height: 50px;
    padding: 14px 15px 17px 15px;
    transition: opacity 125ms ease-in-out;
    position: relative;
    @media (min-width: 1000px) and (max-width: 1378px) {
        padding: 14px 6px 17px 6px;
    }
    &:hover {
        border-bottom: 2px solid $primary-color;
    }
}
.top-bar .menu.sub-menu {
    float: right;
    li {
        .title {
            display: none;
            @include breakpoint(large) {
                display: block;
            }
        }
    }
    display: inline-block;
        margin-right: 0;
        @media (min-width: 1024px) and (max-width: 1278px) {
        order: 1;
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
}
.top-bar .menu.sub-menu>li>a {
    font-size: rem-calc(12);
    font-weight:bold;
    color: $black;
    padding: 9px 15px 15px 15px;
    > small {
        font-size: 100%;
    }
    @media (min-width: 1279px) and (max-width: 1378px) {
        padding: 9px 7px 15px 7px;
    }
}
.top-bar .menu.sub-menu>li>a>small {
    text-transform: none;
    font-family: $body-font-family;
}
.top-bar .top-bar-navigation .menu.sub-menu > li {
    > a {
        position: relative;
        padding-top: 30px;
        text-align: left;
        small {
            font-weight: bold;
            color: $primary-color;
            text-transform: uppercase;
            line-height:2;
        }
        .downArrow{
            background-repeat: no-repeat;
            background-image: url('/content/img/arrow-down-dark.svg');
            position:relative;
            top:2px;
        }
        &::before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            height: 25px;
            background-repeat: no-repeat;
            width: 18px;
            margin-left: -9px;
        }
        @include breakpoint(large) {
            padding-top: 9px;
            small {
                font-weight: normal;
                color: $black;
                text-transform: none;
            }
            &::before {
                left: 0;
                top: auto;
                margin-left: 0;
            }
        }
    }
    &.locations > a {
        &::before {
            background-image: url('/content/img/pin-green.svg');
        }
        &::after {
            background-image: url('/content/img/arrow-down-dark.svg');
        }
    }
    &.support > a {
        &::before {
            background-image: url('/content/img/phone-green.svg');
        }
    }
    @include breakpoint(large) {
        &.locations > a {
            padding-left: 30px;
        }
        &.support > a {
            &::before {
                content: none;
                display: none;
            }
        }
    }
}
// Dropdowns
.dropdown-pane.hovered {
    position: relative;
    display: none;
    @include breakpoint(large) {
        display: block;
    }
}
.dropdown-header.dropdown-pane {
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px 0; 
    text-align: left;
}
.dropdown-header.dropdown-pane.full-width {
    width: 100%;
    left: 50% !important;
    transform: translateX(-50%);
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.dropdown-header ul {
    list-style-type: none;
    margin-left: 0;
    a {
        display: block;
        padding: 7px 0 9px;
        text-decoration: none;
        &:hover {
            strong {
                color: $primary-color;
            }
        }
        > p {
            color: $body-font-color;
            margin-bottom: rem-calc(3);
        }
    }
    &.dropdown-menu {
        li {
            padding: 7px 0;
        }
        li > a {
            @extend .hd-6;
            display: inline-block;
            padding: 7px 0;
            margin-bottom: 0;
        }
    }
}
.dropdown-locations {
    .results-list .row {
        margin-bottom: $dropdown-padding;
        &::after {
            display: block;
            content: "";
            margin-left: $dropdown-padding;
            margin-right: $dropdown-padding;
            height: 1px;
            background-color:$medium-gray;
        }
    }
}
.search-form .search {
    padding: 11px;
    width: 41px;
}
.top-bar_menu-wrap .menu li, .top-bar_menu-wrap .menu.horizontal li {
    display: inline;
}
#search-input.isloading {
    background-image: url('../img/preloader.gif');
    background-size: 15px 15px;
    background-position: right center;
    background-position-x: 99%;
    background-repeat: no-repeat;
}
.punchout .punchoutlogo{
    img{
        max-height:40px;
    }
}
.punchout .input-group {
        @media (max-width: 1278px) and (min-width: 1024px) {
        width: 100%;
        padding-right: 20px;
    }
}
.punchout .top-bar .menu.sub-menu {
        @media (max-width:1378px) and (min-width:1279px) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 63%;
    }
}
.punchout .top-bar_menu-wrap {
        @media (max-width:1278px) and (min-width:1024px) {
        display: block;
        flex-wrap: unset;
    }
}
.punchout .top-bar-navigation .input-group{
    opacity:0.5;
}
#sitemessagebanner {
    border-bottom: 0px solid #fff;
    border-color: #fff;
    font-size: 20px;
    height: 70px;
    padding-top: 20px;
    text-align: center;
}
        