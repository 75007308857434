﻿.minicart-subtotals {
    font-size: 14px;
    color: #333333;
    font-family: Roboto;
    line-height: 24px;
    letter-spacing: 0.2px;

    @include breakpoint(medium) {
        line-height: 28px;
        font-size: 16px;
    }

    .d-flex {
        display: flex;
    }

    .sub-header {
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 0.177778px;
    }

    .rental-subtotal {
        padding-top: 1.2rem;

        > .cost {
            margin-left: auto;
            padding-top: 0px;
            align-items: center;
        }
    }

    img.tool-tip {
        padding-left: 8px;
    }

    .sub-text {
        font-size: 11px;
    }

    .empty-cost {
        width: 8px;
        border-top: 1.5px solid #333333;
        margin-top: 12px;
    }

    .cost {
        float: right;
    }

    .right {
        float: right;
    }

    .sub-total {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.18px;

        @include breakpoint(small) {
            padding-top: 9px;
        }

        div {
            display: inline-block;
        }
    }

    .loading-placeholder.text {
        height: 16px;
        min-width: 65px;
        margin-top: 5px;
    }

    span.env-fee {
        max-width: 160px;
        display: inline-block;

        @include breakpoint(370px) {
            max-width: initial;
            display: inline;
        }
    }
}

.minicart-subtotals {
    font-size: 14px;

    .sub-total {
        font-family: "Roboto Condensed";
    }
}
