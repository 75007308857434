.button {
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    font-family: $header-font-family;
}
.button.small {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.button.large {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    @include breakpoint(medium) {
        padding: rem-calc(21) rem-calc(50) rem-calc(20);
    }
}
