﻿.cart-items {
    font-family: Roboto;

    .desc {
        margin-bottom: 9px;

        .title {
            font-weight: bold;
            margin-top: -3px;

            .loading-placeholder.text {
                width: 190px;

                &.cost {
                    width: 100px;
                }
            }
        }

        .cost {
            font-style: italic;
        }

        .remove {
            cursor: pointer;
        }
    }



    .input-group {
        width: 96px;
        margin-bottom: 0.5em;

        .qty {
            width: 40px;
            text-align: center;
            font-weight: bold;

            .type {
                display: block;
                text-transform: uppercase;
                font-size: 9px;
                font-weight: normal;
            }
        }

        .input-group-button {
            min-width: 34px;

            .btn {
                background-color: #fff;
                color: #333333;
                border: 1px solid #bbbbbb;
                cursor: pointer;
                height: 34px;
                width: 34px;

                &.disabled {
                    opacity: 0.44;
                }
            }

            .subtract {
                font-size: 22px;
                line-height: 0px;

                span {
                    padding-top: 3px;
                    margin-top: -1px;
                    display: block;
                }
            }

            .add {
                font-size: 22px;
                line-height: 0px;
            }

            .loading-placeholder {
                width: 30px;
                height: 30px;
            }
        }
    }

    .price {
        padding-top: 15px;

        .loading-placeholder.text {
            width: 40px;
        }
    }

    > .row {
        padding: 15px 0 0px;
        position: relative;
        margin-right: -1.42857rem !important;
        margin-left: -1.42857rem !important;

        & > .overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 8px;
            background-color: rgba(255, 255, 255, 0.98);
            opacity: 0.98;
            display: none;
            text-align: center;
            padding: 20px 50px;
            flex-direction: column;
            justify-content: center;

            &.show {
                display: flex;
            }

            .question {
                font-weight: bold;
                margin-bottom: 5px;
            }

            .controls {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }

        .columns {
            &.small-4 {
                padding-left: 1rem !important;
                padding-left: 1rem !important;
            }

            &.small-8 {
                padding-left: 1rem !important;
                padding-right: 2.3rem !important;
            }
        }
    }

    > .row::after {
        margin: 19px 19px 0;
        border-bottom: 1px solid #ccc;
        content: "";
        display: block;
        padding-top: 4px;
    }

    > .row:last-child::after {
        border-bottom: none;
    }

    .loading-placeholder.image {
        width: 80px;
        height: 96px;
    }

    .cross {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        overflow: hidden;
        opacity: .75;

        &:hover {
            &::before, &::after {
                background: blue;
            }
        }

        &::before, &::after {
            content: '';
            position: absolute;
            height: 1.4px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: #000;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    .cartItem-remove {
        height: 23px;
        width: 42px;
        color: #333333 !important;
        font-family: Roboto;
        font-size: 20px;
        letter-spacing: 0.26px;
        line-height: 22.5px;
        text-align: right;
    }

    .item-complete {
        display: none;
        font-size: 16px;

        .right {
            float: right;
        }

        .unit-cost, .quantity {
            font-size: 11px;
            line-height: 18px;
        }

        .title {
            font-weight: bold;
        }

        .quantity {
            padding-top: 8px;
            padding-bottom: 12px;
        }
    }

    .item-review-padding {
        padding-bottom: 14px !important;
    }
}
