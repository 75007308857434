﻿

.seggregate-accounts {
    img {
        max-height: 26px;
    }

    .error-text {
        color: #C03A3A;
    }
}

.account-item-width {
    width: 95%;
}

.jobsite-selector-wrapper {
    > div {
        padding: 0px !important;
    }
}

@media only screen and (max-width: 767px) {

    .jobsite-text{
        font-size: 11px;
    }

    .country-selector {
        display: flex;
        flex-direction: column;

        .radio-input {
            &:nth-child(1) {
                margin-left: 17%;
                display: flex;
            }

            &:nth-child(2) {
                display: flex;
                margin-left: 17%;
            }
        }
    }

    .jobsite-selector-wrapper {
        margin: 0% !important;
    }

    .location-header-text {
        font-size: 24px !important;
    }
}

@media only screen and (min-width: 499px) and (max-width: 767px) {
    .radio-input {
        &:nth-child(1) {
            margin-left: 38% !important;
        }

        &:nth-child(2) {
            margin-left: 34% !important;
        }
    }
}

#locationBox::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */
    letter-spacing: 0.1px;
    color: #5A5A5A;
}

#locationBox:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */
    letter-spacing: 0.1px;
    color: #5A5A5A;
    opacity: 1;
}

#locationBox::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */
    letter-spacing: 0.1px;
    color: #5A5A5A;
    opacity: 1;
}

#locationBox:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    /* or 164% */
    letter-spacing: 0.1px;
    color: #5A5A5A;
}

.location-modal-error-border {
    height: 48px;
    border: 1px solid #cc4b37 !important;
}

.location-modal-error-border:focus {
    border: 1px solid #cc4b37 !important;
}

.location-form {
    padding-top: 24px;
}

.punchout-account-selector {
    .container {
        width: 100%;

        .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-right: -12px;
            margin-left: -12px;
            max-width: none;

            .col-1 {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 8.333333%;
                flex: 0 0 8.333333%;
                max-width: 8.333333%;
            }
        }

        @media only screen and (max-width: 767px) {
            max-width: 720px;

            .row {
                .col-sm-12 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0 10px
                }

                margin-right: 0;
                margin-left: 0;
            }
        }

        @media only screen and (min-width: 768px) {
            max-width: 720px;
            padding-right: 24px;
            padding-left: 24px;

            .row {
                .col-sm-12 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0 10px
                }

                margin-right: 0;
                margin-left: 0;
            }
        }

        @media only screen and (min-width: 992px) {
            max-width: 960px;

            .row {
                .col-md-5 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 41.666667%;
                    flex: 0 0 41.666667%;
                    max-width: 41.666667%;
                    padding: 0 15px;
                }
            }
        }

        @media only screen and (min-width: 1066px) {
            max-width: 1044px;

            .row {
                .col-lg-5 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 41.666667%;
                    flex: 0 0 41.666667%;
                    max-width: 41.666667%;
                    padding: 0 15px
                }
            }
        }
    }
}