﻿#DisneyReservationDiv {
    min-height: 300px;
    .row {
        margin-bottom: 10px;
    }
    .title {
        font-size: 1.4em;
        font-weight: bold;
        margin-bottom: 4px;
    }
    .subtitle {
        font-size: 1.1em;
        color: grey;
        margin-bottom: 8px;
    }
    #DisneyReservationForm {
        margin-top: 10px;
        .leftLabel {
            width: 120px;
            display: inline-block;
        }
        .rightLabel {
            width: 120px;
            display: inline-block;
        }
        td {
            padding-left: 30px;
            padding-bottom: 5px;
        }
    }
}
