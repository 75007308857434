﻿input.k-input {
    margin-bottom:0px;
}
.k-popup h3 {
    font-size: 1.2em;
    font-weight: normal;
    margin: 0 0 1px 0;
    padding: 0;
}
.k-popup p {
    margin: 0;
    padding: 0;
    font-size: .8em;
}