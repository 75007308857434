.equipment-slider_title {
    padding: 0 $grid-spacing/2;
    &:hover, &:active, &:visited, &:focus {
        background: none;
    }
    > .title {
        margin-bottom: 0;
    }
}
.equipment-slider-wrap .equipment-slider_title {
    border: none !important;
    text-decoration: none;
}
.equipment-slider-wrap .tabs {
    border-width: 0 0 2px;
    margin-left: $grid-spacing/2;
    margin-right: $grid-spacing/2;
    @include breakpoint(medium) {
        margin-left: $grid-spacing*.75;
        margin-right: $grid-spacing*1.5;
    }
}
.equipment-slider-wrap .tabs-title > a {
    > .title {
        color: $primary-color;
    }
}
.equipment-slider-wrap .tabs-title > a:focus, .equipment-slider-wrap .tabs-title > a[aria-selected='true'] {
    background-color: $primary-color;
    > .title {
        color: $white;
    }
}
.equipment-slider-wrap .tabs-content {
    border: none;
    background: $white;
}
.equipment-slider-wrap .slick-slider.tabs-panel {
    display: none;
    padding: 0;
    .is-active {
        display: block;
    }
}
.equipment-slider-wrap .slick-slider.tabs-panel.is-active {
    display: block;
}
.equipment-slider-wrap .slick-slider.tabs-panel[aria-hidden="false"] {
    display: block;
}
.equipment-slider-wrap .accordion-title::before {
    content: '';
}
.equipment-slider-wrap .accordion-content {
    padding: 0;
    border: none !important;
    @include breakpoint(large) {
        display: block;
    }
}
.equipment-slider .slick-list {
    cursor: grab;
    padding-right: 60px;
    &:after, &:before {
        transition: all 250ms ease-in-out;
        z-index: 2;
        background: $white;
        box-shadow: 0 0 $slick-button-width -12px rgba(0,0,0,.5);
        width: 40px;
        content: " ";
        position: absolute;
        height: 100.1%;
        top: 0;
        @include breakpoint(medium) {
            width: $slick-button-width;
        }
    }
    &:after {
        right: 0;
    }
    &:before {
        left: -100%;
    }
    &:active {
        cursor: grabbing;
    }
}
.equipment-slider .slick-list {
    &:before {
        opacity: 0;
    }
}
.equipment-slider .slick-slide {
    outline: none;
    padding-top: $grid-spacing/2;
    margin-left: $grid-spacing/2;
    margin-right: $grid-spacing/2;
    margin-bottom: $grid-spacing;
    @include breakpoint(medium) {
        margin-left: $grid-spacing*.75;
        margin-right: $grid-spacing*.75;
        margin-bottom: $grid-spacing*1.5;
    }
}
.equipment-slider .slick-slide a {
    outline: none;
    &.slide-link > img {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: $form-spacing;
        max-height: 170px;
    }
}
.equipment-slider .slick-slide .title > a {
    line-height: 1.5;
}
.equipment-slider:not(.home-equipment-slider) .slick-slide .slide-content {
    .title {
        font-size: rem-calc(14);
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        text-transform: uppercase;
        margin-bottom: rem-calc(2);
    }
    p {
        font-size: rem-calc(12);
        color: #707070;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.equipment-slider .slick-prev {
    transition: all 250ms ease-in-out;
    left: -100%;
}
.equipment-slider .slick-next {
    right: 0;
    @include breakpoint(medium) {
        right: 0;
    }
}
.equipment-slider .slick-prev, .equipment-slider .slick-next {
    z-index: 2;
    &:before {
        color: $black;
        width: 20px;
        content: "";
        display: block;
        height: 20px;
        border-top: 2px solid #000;
    }
}
.equipment-slider .slick-prev {
    &:before {
        opacity: 0;
    }
}
.equipment-slider .slick-prev:before {
    border-left: 2px solid #000;
    transform: rotate(-45deg);
    margin-left: 16px;
    @include breakpoint(medium) {
        margin-left: 24px;
    }
}
.equipment-slider .slick-next:before {
    border-right: 2px solid #000;
    transform: rotate(45deg);
    margin-left: 24px;
    @include breakpoint(medium) {
        margin-left: 16px;
    }
}
.equipment-slider.show-prev {
    .slick-list {
        &:before {
            left: 0;
            opacity: 1;
        }
    }
    .slick-prev {
        left: 0;
        &:before {
            opacity: 1;
        }
        &.slick-disabled:before {
            opacity: 0.25;
        }
    }
}
// Home Equipment Slider (Large/Gray)
$home-equipment-bg: $off-white;
.home-equipment-slider_wrap {
    background-color: $home-equipment-bg;
    @include breakpoint(medium) {
        display: block;
    }
}
.home-equipment-slider_title {
    > .columns, > .column {
        p:last-child {
            margin-bottom: 0;
        }
    }
}
.home-equipment-slider {
    text-align: center;
    margin: 0 auto;
}
.home-equipment-slider .qty {
    display: none;
}
.home-equipment-slider .slick-list {
    &:after, &:before {
        background: $home-equipment-bg;
    }
}
.home-equipment-slider.equipment-slider .slick-slide {
    padding-top: $grid-spacing;
    hr {
        display: none;
    }
}
.home-equipment-slider .slick-slide img {
    margin: 0 auto;
}
.home-equipment-slider .slick-slide .slide-content {
    transition: opacity 125ms ease-in-out;
    opacity: 0;
    margin-top: $grid-spacing/2;
    @include breakpoint(medium) {
        position: relative;
        width: 520px;
        left: 50%;
        transform: translateX(-50%);
    }
    @include breakpoint(large) {
        width: 640px;
    }
    p:last-child, a.button {
        margin-bottom: 0;
    }
}
.home-equipment-slider .slick-slide:not(.slick-current) .slide-content {
    opacity: 0;
}
.home-equipment-slider .slick-slide.slick-current.has-fadeIn .slide-content {
    opacity: 1;
}
.rates_list {
    font-size: rem-calc(12);
}
