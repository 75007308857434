﻿div#spinner {
    display: none;
    width: 100px;
    height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 50px;
    font: normal 16px Tahoma, Geneva, sans-serif;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 999999;
    overflow: auto;
}
