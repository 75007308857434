﻿#QuickRent {
    #QuickRentTabs {
        .qr-tab-label {
            display: none;
            @media #{$tablet} {
                display:inline;
            }
            @media #{$desktop} {
                display:inline;
            }
        }
        .FrequentFavoriteOrders {
            .Summary {
                padding-right: 10px;
                padding-bottom: 5px;
                font-weight: 500;
                text-align: right;
                border-bottom: 1px solid #e2e2e2;
            }
            .Items {
                height: 400px;
                table {
                    width: 100%;
                    border-collapse: collapse;
                    tr {
                        overflow: auto;
                        background-color: #ffffff;
                        &.ItemRowAlt {
                            background-color: #f5f5f5;
                        }
                        th {
                            overflow: auto;
                            width: 490px;
                            text-align: left;
                            vertical-align: top;
                            padding: 15px 0px 15px 15px;
                            div {
                                overflow: auto;
                                margin-bottom: 10px;
                                .Check {
                                    float: left;
                                    margin-right: 15px;
                                    margin-top: 12px;
                                }
                                .ItemImg {
                                    float: left;
                                }
                                span {
                                    display: block;
                                    float: left;
                                    margin-left: 12px;
                                    margin-top: 10px;
                                    font-weight: normal;
                                }
                                div {
                                    font-weight: normal;
                                }
                            }
                        }
                        td {
                            overflow: auto;
                            width: 225px;
                            padding: 15px 15px 15px 0px;
                            vertical-align: top;
                            .Previous {
                                overflow: auto;
                                span {
                                    float: left;
                                    font-weight: bold;
                                    margin: 0 10px 0px 0px;
                                }
                                label {
                                    float: left;
                                    display: block;
                                    margin-right:12px;
                                    text-align: left;
                                }
                                div {
                                    overflow: auto;
                                    clear: both;
                                    font-size: .9em;
                                    font-style: italic;
                                    padding-top: 15px;
                                }
                            }

                            .Actions {
                                margin-top: 20px;
	                            display: flex;

                                .AddToCart {
		                            cursor: pointer;
	                            }

                                img {
		                            cursor: pointer;
		                            margin: 10px 4px;
		                            height: 32px;
	                            }
                                span {
		                            font-weight: bold;
		                            color: #999;
                                    cursor: pointer;
	                            }
                            }
                        }
                    }
                }
            }
        }
        .RecentOrders {
            .Summary {
                padding-right: 10px;
                padding-bottom: 5px;
                font-weight: 500;
                text-align: right;
                border-bottom: 1px solid #e2e2e2;
            }
            .Items {
                height: 400px;
                table {
                    width: 100%;
                    border-collapse: collapse;
                    tr {
                        overflow: auto;
                        background-color: #ffffff;
                        &.ItemRowAlt {
                            background-color: #f5f5f5;
                        }
                        th {
                            overflow: auto;
                            width: 500px;
                            text-align: left;
                            vertical-align: top;
                            padding: 15px 0px 15px 15px;
                            div {
                                overflow: auto;
                                margin-bottom: 10px;
                                .Check {
                                    float: left;
                                    margin-right: 15px;
                                    margin-top: 12px;
                                }
                                .ItemImg {
                                    float: left;
                                }
                                span {
                                    display: block;
                                    float: left;
                                    margin-left: 12px;
                                    margin-top: 10px;
                                    font-weight: normal;
                                }
                                div {
                                    font-weight: normal;
                                }
                            }
                        }
                        td {
                            overflow: auto;
                            width: 215px;
                            padding: 15px 15px 15px 0px;
                            text-align: right;
                            vertical-align: top;
                            .OrderDate {
                                margin-bottom: 10px;
                            }
                            label {
                                display: inline-block;
                                width: 70px;
                                text-align: right;
                                font-size: .8em;
                                font-style: italic;
                                margin-bottom: 2px;
                            }
                            span {
                                display: inline-block;
                                width: 70px;
                                font-size: .8em;
                                margin-bottom: 2px;
                            }
                            .OrderLink {
                                display: block;
                                color: $brand-green;
                                font-size: .8em;
                                margin-top: 6px;
                            }
                            .AddToCart {
                                cursor: pointer;
                            }
                            img {
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}