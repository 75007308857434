﻿#completePageFooter {
    height: 82px;
    background-color: $color-sbgreen;
    width: 100%;
    text-align: center;
    u {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.17px;
        height: 82px;
        line-height: 82px;
        text-decoration: none;
        font-weight: normal;
        span {
            font-weight: bold;
        }
    }
}
@include breakpoint(small only) { 
    #completePageFooter {
        height: 92px;
        display:flex;
        flex-direction:column;
        u {
            height: 58px;
            line-height: 58px;
        }
    }
}