﻿* {
    box-sizing: border-box;
}
.bold {
    font-weight:bold;
}
.header {
    background-color: #fafafa;
    margin: auto;
    text-align: center;
    position: relative;
    min-height: 110px;
    padding: 10px;

    @media screen and (max-width: $screen-xs-max) {
        padding: 0;
        min-height: 60px;
    }
}
.header-steps-container {
    width: 100%;
    text-align: center;
    top: 0px;
    position: absolute;
    @media #{$max-ipad-mini} {
        display: none;
    }
    @media #{$tablet-wide} {
        margin-left: 65px;
    }
    @media #{$ipad-mini-768-800} {
        margin-left: 50px;
    }
    margin-top: 5px;
    z-index:1;
}
.header-logo-container {
    position: absolute;
    top: -35px;
    z-index: 2;
    &:active,
    &:focus {
        outline: none;
    }
    img, a {
        &:active,
        &:focus {
            outline: none;
        }
    }
}
.header-center {
    margin-top: 25px;
    text-align: left;
    .header-number, .header-number-active {
        margin-right: 5px;
        height: 34px;
        width: 29px;
        font-family: Roboto;
        font-size: 34px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 34px;
        vertical-align: middle;
    }
    .header-number, .header-text {
        color: #ABABAB;
    }
    .header-number {
        @media #{$screen-801-1015} {
            font-size: 30px;
            margin-right: 0px;
        }
        @media #{$ipad-mini-768-800} {
            font-size: 18px;
        }
    }
    .header-text, .header-text-active {
        height: 28px;
        width: 79px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 28px;
        vertical-align: middle;
    }
    .header-text {
        @media #{$screen-801-1015} {
            font-size: 16px;
        }
        @media #{$ipad-mini-768-800} {
            font-size: 12px;
        }
    }
    .header-number-active, .header-text-active {
        color: #333333;
    }
    .header-number-active {
        @media #{$screen-801-1015} {
            font-size: 30px;
            margin-right: 0px;
        }
        @media #{$ipad-mini-768-800} {
            font-size: 18px;
        }
    }
    .header-text-active {
        @media #{$screen-801-1015} {
            font-size: 16px;
        }
        @media #{$ipad-mini-768-800} {
            font-size: 12px;
        }
    }
}
.header-steps {
    display: inline-block;
    text-align: left;
    background-color: #fafafa;
    margin-left: 40px;
    @media #{$screen-801-1015} {
        margin-left: 5px;
    }
    @media #{$ipad-mini-768-800} {
        margin-left: 5px;
    }
    &:nth-last-child(2):first-child {
        margin-right: 100px;
    }
}
.subheader {
    background-color: #F3F3F3;
    height: 50px;
    line-height: 48px;
    margin-bottom: 75px;
}
.logo {
    margin-left: 10px;
    display: block;

    @media screen and (max-width: $screen-xs-max) {
        margin-left: 0;
    }
}
.logo-image {
    height: 100px;
    width: 100px;

    @media screen and (max-width: $screen-xs-max) {
       height: 80px
    }
}
a.linkHeader {
    text-decoration: none;
}
.footer {
    background-color: #FFFFFF;
    box-shadow: 0 -10px 20px 0 rgba(0,0,0,0.08);
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
}
.checkout-body {
    margin-bottom: 85px;
    .text {
        &.green-bolder {
            font-size: 18px;
            color: #007A39;
            font-weight: bold;
        }
    }
}
.cart-breadcrumb {
    height: 50px;
    background-color: #F3F3F3;
    padding-top: 18px;
    padding-left: 40px;
    @media screen and (max-width: $screen-xs-max) {
        height: 28px;
        padding-top: 6px;
        padding-left: 10px;
    }
}
#cart-breadcrumb:before {
    content: '';
    width: 6px;
    height: 6px;
    border-bottom: 1px solid #333;
    border-left: 1px solid #333;
    position: relative;
    top: -1px;
    margin-right: 6px;
    display: inline-block;
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
}
.account-selector-container {
    margin-bottom: 110px;
    margin-top: 20px;
}
.jobsite-address-container {
    text-align: left;
    margin-top: 13px;
    margin-bottom: 10px;
}
.modal-open {
    -webkit-filter: blur(3px);
    filter: blur(3px);
}
.modal-window {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    background-color: #FFFFFF;
    height: auto;
    padding: 77px;
    &.sign-in-to-continue {
        width: 50%;
        #sign-in-form {
            margin-top: 1rem;
            margin-left: 10%;
            margin-right: 10%;
            width: 100%;
            .fortgot-password-link {
                text-align: left;
            }
            .different-email-link {
                font-size: 1rem;
                text-decoration: none;
            }
            .field-container {
                display: flex;
                flex-direction: column;
                & > label {
                    &.text-email-chekbox {
                        align-items: center;
                        display: flex;
                        & > input[type="checkbox"] {
                            margin-left: 0;
                        }
                    }
                }
            }
            #cm-submit-button {
                margin-bottom: 0;
            }
            .modal-action-container {
                .columns:last-child {
                    display: flex;
                    align-items: center;
                }
            }
            & > .row {
                & > .columns {
                    &:first-child {
                        padding-right: 0.5rem;
                    }
                    &:last-child {
                        padding-left: 0.5rem;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $screen-xs-max) {
        width: 100%;
        padding: 35px;
        &.sign-in-to-continue {
            width: 100%;
            #sign-in-form {
                margin-left: 0;
                margin-right: 0;
                .field-container {
                    padding: 0;
                    &:first-child {
                        margin-bottom: 1rem;
                    }
                }
                #cm-submit-button {
                    margin-bottom: 0;
                }
                .modal-action-container {
                    .columns {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0;
                        &:first-child {
                            margin-bottom: 1rem;
                        }
                    }
                }
                & > .row {
                    & > .columns {
                        &:first-child {
                            padding-right: 0;
                        }
                        &:last-child {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
.modal-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(51, 51, 51, 0.44);
}
.modal-body-text {
    padding-top: 25px !important;
    padding-bottom: 25px !important; 
}
.modal-button-padding {
    padding-bottom: 30px !important;
}
.modal-closed {
    display: none;
}
.modal-button-close {
    width: 60px;
    height: 60px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
.modal-button-close:after,
.modal-button-close:before {
    content: '';
    width: 28px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1.5px 0 0 -14px;
    background: #333;
    display: block;
}
.modal-button-close:before {
    -webkit-transform: rotate(-45deg) translateY(0);
    -ms-transform: rotate(-45deg) translateY(0);
    transform: rotate(-45deg) translateY(0);
}
.modal-button-close:after {
    -webkit-transform: rotate(45deg) translateY(0);
    -ms-transform: rotate(45deg) translateY(0);
    transform: rotate(45deg) translateY(0);
}
.modal-header {
    margin-bottom: 15px;
}
.modal-action-container {
    margin-top: 10px;
}
.no-scroll {
    overflow: hidden !important;
}
/* TODO: revist centering */
.align-center {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 1%
}
.profiler-results {
    @include breakpoint(small only) {
        display: none;
    }
}
.highlight-div {
    padding-bottom: 40px;
    background-color: #F3F3F3;
    padding-top: 40px;
    margin-bottom: 111px;

    @media screen and (max-width: $screen-xs-max) {
        margin-bottom: 76px;
        background-color: inherit;
    }
}
.checkout {
    font-family: Roboto;

    a.button {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 15px;
        margin-top: 15px;
        border: 1px solid transparent;
        border-radius: 0;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        font-size: 16px;
        -webkit-appearance: none;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        background-color: #007a39;
        color: #fff;
        height: 50px;
        width: 232px;
        padding-top: 12px;
        letter-spacing: 0.25px;

        @media screen and (max-width: $screen-xs-max) {
            width: 90%;
        }
    }

    @media screen and (max-width: $screen-xs-max) {
        .align-step-center {
            display: flex;
            justify-content: center
        }
    }
}
.mb-5{
    margin-bottom:2em;
}

.complete-footer {
    box-shadow: 0 -10px 20px 0 rgba(0,0,0,0.08);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: #008545;
    color: #FFF;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 18px;
    letter-spacing: 0.1px;

    .linkDiv {
        padding: 10px;

        @media (min-width: 800px) {
            position: absolute;
        }

        a {
            text-decoration: none;
            font-weight: 700;
            color: #FFF;
        }
    }

    .chat-now-link {
        padding-top: 10px;
        a {
            text-decoration: none;
            font-weight: 500;
            color: #FFF;
            display: block;
        }

        @media (min-width: 800px) {
            font-weight: 500;
            position: relative;
            left: 25%;
            display: inline;
            padding-top: 0;
        }
    }
}

a.explore-nav {
    font-family: Roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #008545;
    text-decoration: none;

    &:hover, &:focus {
        color: #008545 !important;
    }
}

#generic-timeout-error{
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 0 2em;
    
    img{
        height: 269px;
         width: 275px;
    }

    .error-message-title{
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 26px;
        color: #333333;
        margin-top: 1.5em;
    }

    span{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        color: #333333;
        margin-top: 1em;
    }

    @media (max-width: 800px) {
        height: 80vh;
        img{
            height: 150px;
             width: 156px;
        }
    
        .error-message-title{
            font-size: 20px;
            line-height: 26px;
            text-align: center;
        }
    
        span{
            font-size: 14px;
            line-height: 24px;
        }
    }
}

#newjobsitetakeover{
    .txt-padding {
        padding-right: 1%;
        padding-left: 1%;
    }
}