.cart-addition {
    .detail-link {
        font-size: rem-calc(12);
        color: $body-font-color;
        text-decoration: none;
        &:after {
            display: inline-block;
            content: " ";
            width: 10px;
            height: 10px;
            border: none;
            border-top: 2px solid $dark-gray;
            border-right: 2px solid $dark-gray;
            transform: rotate(135deg);
            position: relative;
            top: -2px;
            margin-left: 1rem;
        }
    }
    .details {
        display: none;
        margin-top: $grid-spacing/2;
    }
    &.expanded {
        .detail-link:after {
            transform: rotate(-45deg);
            top: 3px;
        }
        .details {
            display: block;
        }
    }
    .qty {
        text-align: left;
        .input-group {
            display: none;
        }
        .add-button {
            padding-left: 2.8rem;
            padding-right: 1.05rem;
            background: url(/content/img/add_light.svg) no-repeat 11px 50% $primary-color;
            background-size: 16px;
        }
        &.active {
            .input-group {
                display: table;
                max-width: 120px;
            }
            .input-group-field {
                background-color: #00441d;
                border: none;
                text-align: center;
                color: $white;
                width: 40px;
                font-weight: bold;
            }
            .input-group-button {
                width: 40px;
                > .button {
                    width: 40px;
                    font-size: 24px;
                    padding: 0;
                    text-align: center;
                }
                > .qty-add-btn {
                    background: url(/content/img/add_light.svg) no-repeat 11px 50% / 16px $primary-color;
                }
                > .qty-subtract-btn {
                    background: url(/content/img/subtract_light.svg) no-repeat 11px 50% / 16px $primary-color;
                }
            }
        }
    }
    .fav_button {
        text-decoration: none;
        display: inline-block;
        margin: 0.5rem 0;
        > img {
            position: relative;
            top: -1px;
            margin-right: 0.5rem;
        }
        &.is-fav {
            color: $black;
        }
    }
}
.cart-addition .cart-addition-qty.qty {
    text-align: center;
    .input-group {
        margin: 0 auto;
    }
    .qty-wrap & .input-group {
        margin: 0;
    }
    .button {
        margin-bottom: 0;
    }
}
#AddToCartDialog {
    a.button {
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 2px;
    }
}