﻿$button-color: #008545;

@mixin flexCenter($direction) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: $direction;
}

.payment-container {
  width: 90vw;
  padding: 10px;
  margin: auto;
}

.saved-payment-methods {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid gray;
    padding: 14px 0;

    .payment-method-details {
        display: flex;
    }

    .payment-card-details {
        display: flex;
        flex-direction: column;
    }



    button {
        color: #008545;
        outline: none;
        border: none;
        background-color: inherit;
        cursor: pointer;
    }
}

.card-number {
    font-size: 17px;
}

.radio-top-option {
    margin-right: 32px;
}

/*styles for radio button to disabled*/
.disable-payment-radio-button {
  background-color: #e5e5e5 !important;
  cursor: not-allowed;
}

/*styles for delete card and card exists popup*/
#paymentdeletemodaltakeover,
#paymentcardexistsmodaltakeover {
  #locationBoxContainer {
    > div:nth-child(2) {
      margin: 0px !important;
    }

    div.row.align-center.action-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-left: 10%;

      > div:nth-child(2) {
        > div {
          > div {
            padding-top: 18px !important;
          }
        }
      }

      .location-submit {
        &:first-child {
          width: 232px;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .payment-container {
    width: 465px;
  }

  .payment-info-text {
    display: flex;
    justify-content: center;
  }

  .payment-methods {
    display: flex;
  }
}
