.related_products {
    $child-selector: '.product-item';
    // default: 2, 3, 6
    @include grid-layout(2, $child-selector, $grid-column-gutter);
    @include breakpoint(medium) {
        @include grid-layout(3, $child-selector, $grid-column-gutter);
    }
    @include breakpoint(large) {
        @include grid-layout(6, $child-selector, $grid-column-gutter);
    }
    &.large { // large: 1, 2, 4
        @include grid-layout(1, $child-selector, $grid-column-gutter);
        @include breakpoint(medium) {
            @include grid-layout(2, $child-selector, $grid-column-gutter);
        }
        @include breakpoint(large) {
            @include grid-layout(4, $child-selector, $grid-column-gutter);
        }
    }
    .product-item {
        margin-bottom: 40px;
        p {
            font-size: rem-calc(12);
            margin-bottom: 0.5em;
            > a {
                font-size: rem-calc(12);
            }
        }
        &_img {
            margin-bottom: 0.5em;
            max-width: 100%;
            max-height: 150px;
            @include breakpoint(xlarge) {
                max-height:180px;
            }
        }
        .title {
            font-size: rem-calc(14);
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            text-transform: uppercase;
            margin-bottom: 0.5em;
            a {
                text-decoration: none;
            }
        }
    }
}
.simple_listing {
    margin-top: $grid-spacing;
    .product-item .title {
        position: relative;
        margin-bottom: rem-calc(3);
        a {
            position: relative;
            display: block;
            padding-right: 1rem;
            &:after {
                display: inline-block;
                content: " ";
                width: 10px;
                height: 10px;
                border: none;
                border-top: 2px solid #333333;
                border-right: 2px solid #333333;
                -ms-transform: rotate(135deg);
                transform: rotate(135deg);
                position: absolute;
                top: -1px;
                right: 2px;
            }
        }
    }
    .product-item .text-link {
        font-size: rem-calc(12);
    }
    .product-item a.recent_rental {
        color: $primary-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.simple_listing .product-item.expanded {
    position: relative;
    .title {
        a {
            &:after {
                top: 4px;
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }
}
.subcat_list {
    display: none;
    box-shadow: 0 0 6px rgba(0,0,0,0.15);
    background: $white;
    border-bottom: 1px solid $light-gray;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    z-index: 1;
    width: calc(100% - 39px);
    @include breakpoint(medium) {
        width: calc(100% - 60px);
    }
    > li {
        margin-bottom: 0.25rem;
        line-height: 1.25;
        a {
            display: inline-block;
            font-size: rem-calc(12);
            color: $body-font-color;
            padding: 0.5rem;
        }
    }
    .product-item.expanded & {
        display: block;
        position: absolute;
    }
}
