﻿$color-green: #008545;
$color-light-green: #BFE0D0;
$color-dark-grey: #5A5A5A;
$color-light-yellow: #FEFAE5;
$color-grey-scale: #222222;
$color-grey-silver: #F2F2F2;
$color-black: #333;
$color-red: #C03A3A;


/*===================================pickup page styles ==============================================*/
.error-message {
    color: $color-red;
}
.lead-time-error {
    width: 170px !important;
    margin-top: -12px;
}
/*===================================pickup page styles ends here==============================================*/
// intended global style
.enable-future-date {
    color: black !important;
    cursor: context-menu;
}

.warning-header-text {
    font-size: 34px !important;
    font-weight: bold;
    color: $color-black;

    @media only screen and (max-width: 767px) {
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 30px !important;
        line-height: 18px;
        margin:0;
    }
}

@media only screen and (max-width: 767px) {
    .user-confrimation-text {
        margin: 1em 0;
        line-height: 23px;
    }
}

#userconfirmtiontakeover {
    /*styles for user warning popup*/
    #locationBoxContainer {
        > div:nth-child(2) {
            margin: 0px !important;
        }

        div.row.align-center.action-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-left: 10%;
            margin-top:5px;

            > div:nth-child(2) {
                > div {
                    > div {
                        padding-top: 36px !important;
                    }
                }
            }

            .location-submit {
                &:first-child {
                    width: 232px;
                    @media only screen and (max-width: 767px) {
                        width: 80vw;
                        margin: 1em 0 !important;
                    }
                }
            }
        }
    }
}

.disable-window-selection {
    pointer-events: none;
}

#deleteEmailbutton,
#add-button {
    cursor: pointer;
}

/*mixins*/
@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
}

@mixin span-text-style {
    > span {
        font-size: 14px;
        line-height: 20px;
        color: $color-grey-scale;

        &:first-child {
            font-weight: bold;
            margin-bottom: 4px;
        }
    }
}

@mixin icon-img-style {
    height: 24px;
    margin-top: 8px;
}

.dropdown-container {
    position: relative;
    max-width: 403px;
    margin: auto;
    width: 100%;
}

.dropdown-grop {
    font-size: 14px;
    color: #008545;
    font-weight: bold;
    padding: 0.625rem;
    cursor: pointer;
    text-indent: 0;
}

.dropdown-options {
    font-size: 14px;
    padding: 4px;
    cursor: pointer;
}

.account-selector-dropdown{
    z-index:101;
    margin-top:0px;
}

.warning-position{
    margin-top:-18px;
    position:relative;
}

.warn-slot-unavailable {
    @include flex-row;
    background-color: $color-light-yellow;
    padding: 12px;

    .warning-icon {
        @include flex-column-center;
        width: 33%;
        justify-content: center;
        align-items: center;

        > img {
            @include icon-img-style;
        }
    }

    .warn-text {
        @include flex-column-center;
        @include span-text-style;
        margin-left: 12px;
    }
}

.info-pickup {
    @include flex-row;    
    padding: 12px;
    background-color: $color-grey-silver;
    border-radius: 8px;
    margin-top:-14px;

    .info-icon {
        flex-direction: column;
        width: 32%;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
            @include icon-img-style;
            width: 21px;
            height: 21px;
            margin-top:0;
        }
    }

    .info-text {
        @include flex-column-center;
        @include span-text-style;
        margin-left: 10px;
    }
}



.time-slot-selector {

    p.hint-text {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }

    div.slot-container {

        > div.group-name {
            font-size: 16px;
        }

        .selected-slot {
            background-color: $color-light-green;
        }

        > div.slots {
            > div.slot-window {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                border: 2px solid $color-green;
                height: 48px;
                padding: 14px;
                box-sizing: border-box;
                margin: 12px 0px;
                cursor: pointer;

                > span {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 18px;
                    padding: 0px 2px !important;
                    color: $color-dark-grey;
                }
            }
        }
    }
}

.dates {
    width: 50%;
}

.times {
    padding-left: 10px;
    width: 50%;

    .dropdown-container {
        input[type="text"] {
            padding-right: 1.8rem;
        }
        .dropdown-arrow, .dropdown-arrow-up {
            right: 10px;
        }
        #style-time {
            .dropdown-options {
                display: flex;
                flex-wrap: wrap;
                padding: 0.625rem;

                span {
                    text-indent: 0;

                    &:nth-child(2) {
                        margin: 0 0.2rem;
                    }
                }
            }
        }
    }
}


.d-flex {
    display: flex;

    .d-left {
        width: 50%;

        .label-font {
            font-size: 15px;
        }
    }

    .d-right {
        margin-left: 12px;
        width: 50%;

        .label-font {
            font-size: 15px;
        }
    }

    .margin-reqd-field {
        margin-top: -15px;
    }
}

@media only screen and (min-width: 767px) {
    .cash-inst-top {
        margin-top: 20px !important;
    }
}

.cash-inst {
    margin-top: 0px;
}

.char-limit {
    text-align: right;
}

.credit-instructions {
    .label-inst {
        font-size: 15px;
    }

    .add-email-label {
        font-size: 12px !important;
    }

    .div-padding {
        padding-top: 20px;
    }

    .div-width {
        width: 50%;

        .right-div-margin {
            margin-left: 12px;
        }
    }

    .text-area {
        height: 155px;
    }

    .extra-email {
        width: 85%;
    }

    .add-button-email {
        width: 56px;
        border: 1px solid #008545;
        box-sizing: border-box;
        background: #FFFFFF;
        margin-bottom: 8px;
        text-align: center;
        display: inline-grid;
        max-height: 50px;

        > .a-link {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0.1px;
            color: #008545;
        }
    }
}

.extra-email-rec {
    .input-div {
        border-right: none;
    }

    .button-div {
        border-left: none !important;
        margin-left: 0px;
        border: 1px solid #999999;
    }
}

.red-border {
    border: 1px solid #C03A3A !important;
}

.black {
    color: #000;
}

// mobile view css
.checkout {
    @media only screen and (max-width: 767px) {
        #base-content {
            width: 100%;

            .cash-info-text {
                height: 80px !important;
            }

            .shopping {
                padding: 0px;
                width: 88% !important;
            }

            .date-container {
                flex-direction: column;

                > .d-left {
                    width: 100%;
                }

                > .d-right {
                    width: 100%;
                    margin-left: 0px;
                }

                .times {
                    width: 100%;

                    > .dropdown-container {
                        width: 100% !important;
                    }
                }
            }

            .end-date-with-slots {                
                top: 60vh;
            }

            .instructions-margin {
                margin-top: 4% !important;
            }

            .time-slot-selector {                
                top: -14vh;

                .slot-container {
                    .slots {
                        .slot-window {
                            width: 100%;
                        }
                    }
                }
            }

            .credit-instructions {
                display: flex;
                flex-direction: column;

                > div {
                    width: 100%;
                    margin-left: 0px;
                }
            }
        }
    }
}