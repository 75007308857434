#rates-debug {
    font-size: 11px;
    color: #252525;
    margin-bottom: 16px;
}
.product_item_desc {
    margin-top: $grid-spacing;
    margin-bottom: $grid-spacing;
    @include breakpoint(medium) {
        margin-top: $grid-spacing/2;
        margin-bottom: $grid-spacing/2;
    }
}
.product_item_add_wrap {
    @include breakpoint(small only) {
        background-color: $off-white;
        text-align: center;
        padding: $grid-spacing/2 0;
        margin-left: -($grid-spacing/2);
        margin-right: -($grid-spacing/2);
        .cart-addition .qty {
            text-align: center;
        }
        .cart-addition .qty .add-button {
            margin-bottom: $grid-spacing/2;
        }
        .cart-addition .qty .input-group {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $grid-spacing/2;
        }
    }
}
.product_detail_pricing {
    margin-top: $grid-spacing/2;
    margin-bottom: $grid-spacing/2;
    > li:not(:last-child) {
        padding-right: $grid-spacing*1.5;
    }
    @include breakpoint(small only) {
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        > li:not(:last-child) {
            padding-right: $grid-spacing;
        }
    }
}
.rates_shopping_location {
    margin-right:14px;
}