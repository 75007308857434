/*!
// CSS only Responsive Tables
// http://dbushell.com/2016/03/04/css-only-responsive-tables/
// by David Bushell
*/
.rtable {
  display: flex;
  vertical-align: top;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
  background: none;
  margin-bottom: $grid-spacing;
}
.rtable thead {
  display: flex;
  flex-shrink: 0;
  border: none;
}
.rtable tbody {
  display: flex;
  border: none;
  // optional - enable iOS momentum scrolling
  -webkit-overflow-scrolling: touch;
  // scrolling shadows
  background: radial-gradient(ellipse, rgba(0, 0, 0, 0.2) 0%, transparent 75%) -4% center, radial-gradient(ellipse, rgba(0, 0, 0, 0.2) 0%, transparent 75%) 104% center;
  background-size: 12px 100%, 12px 100%;
  background-attachment: scroll, scroll;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.rtable th {
  display: block;
  padding: 6px $grid-spacing/2;
  font-size: rem-calc(14);
  font-family: $header-font-family;
  text-transform: uppercase;
  @extend .hd-condensed;
}
.rtable td {
  display: block;
  padding: 6px $grid-spacing;
  @include breakpoint(small only) {
    padding: 6px $grid-spacing/2;
  }
}
.rtable tr {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border: none;
}
// slick slider
.rtable .slick-prev, .rtable .slick-next {
  z-index: 2;
  @include breakpoint(small only) {
    width: 40px;
  }
  &:before {
    content: ' ';
    width: 20px;
    display: block;
    height: 20px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
  }
}
.rtable .slick-prev {
  left: 0;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 359px) {
    display: none !important;
  }
  &:before {
    transform: rotate(-135deg);
    margin-left: 16px;
  }
}
.rtable .slick-next {
  right: 0;
  &:hover {
    cursor: pointer;
  }
  &:before {
    transform: rotate(45deg);
    margin-left: 4px;
    @include breakpoint(medium) {
      margin-left: 26px;
    }
  }
}
.rtable .slick-list {
  cursor: grab;
  padding: 0 $grid-spacing;
  &:active {
    cursor: grabbing;
  }
  @media (max-width: 359px) {
    padding: 0 $grid-spacing 0 0;
  }
}
.rtable .slick-list:after, .rtable .slick-list:before {
    background: $white;
    z-index: 2;
    box-shadow: 0 0 40px -12px rgba(0, 0, 0, 0.5);
    width: 40px;
    content: " ";
    position: absolute;
    height: 100.1%;
    top: 0;
}
.rtable .slick-list:after {
  right: 0;
}
.rtable .slick-list:before {
  left: 0;
  @media (max-width: 359px) {
    display: none !important;
  }
}