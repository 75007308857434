﻿#AccountActivity {
    table {
        margin-bottom: 0px;
    }
    table tbody td {
        padding: 5px;
    }
    table tbody {
        border: 0px;
    }
    table tbody tr {
        border-bottom: 0px;
    }
    .k-grid td {
        line-height: 1em !important;
    }
    span.jobSiteName {
        font-weight: bold;
        line-height: 110% !important;
    }
    p.contract {
        font-weight: bold;
    }
    a.contract {
        text-decoration: none !important;
    }
    span.text {
        line-height: 110% !important;
    }
    span.textBold {
        font-weight: bold;
        line-height: 110% !important;
    }
    .leftDate {
        float: left;
        width: 18%;
    }
    .rightDate {
        margin-left: 25%;
    }
    span.textCorpAccountName {
        line-height: 10px !important;
    }
    #column_container {
        width: 100%;
    }
    .left_column {
        float: left;
        width: 10%;
        padding-bottom: 2px;
    }
    .right_column {
        float: left;
        width: 90%;
        padding-bottom: 4px;
    }
    a.equipment_more {
        font-weight: bold;
        line-height: 1em !important;
        text-align: right !important;
        margin-left: 40%;
    }
    .k-window {
        font-size: 12px;
    }
}
#ManageAlertsWindow {
    .alignleft {
        float: left;
    }
    .alignright {
        float: right !important;
    }
    div.k-content {
        min-height: 360px;
        overflow: auto;
        padding-left: 0px;
        padding-right: 0px;
        border: solid 1px #e2e2e2;
    }
    .k-item {
        border: 1px solid red;
        border-bottom: 1px solid black !important;
    }
}
#AlertDialog {
    table {
        border-collapse: collapse;
        margin: 0;
        width: 100% !important;
    }

    td {
        padding-top: .5em;
        padding-bottom: .5em !important;

        &.checkbox {
            padding-left: 15px;

            img {
                cursor: pointer;
                min-width: 25px;
            }
        }
    }

    .alertDarkGray {
        font-weight: bold;
    }

    .alertTitle {
        text-align: center;
    }

    .alertLabel {
        margin-right: 0.3rem;
        text-align: left;
        width: 25%;
    }

    .additional-message {
        margin-left: 1rem;
        width: 30%;
    }

    .row-input-message {
        display: flex;
        align-items: center;

        .k-widget {
            width: 54%;
        }

        .alertLabel {
            font-size: x-small;
            margin-left: 1rem;
            width: 40%;
            margin-right: 0;
        }

        select {
            padding-left: 0.4rem;
        }
    }

    .row-input {
        input {
            margin-bottom: 0;
        }
    }

    .checkOn {
        background: no-repeat url(/Content/images/Shared/CheckOn.png);
        width: 27px;
        display: block;
        height: 27px;
    }

    .checkOff {
        background: no-repeat url(/Content/images/Shared/CheckOff.png);
        width: 27px;
        display: block;
        height: 27px;
    }

    .jobSiteCheckOn {
        background: no-repeat url(/Content/images/Shared/CheckOn.png);
        display: inline-block;
        height: 27px;
    }

    .jobSiteCheckOff {
        background: no-repeat url(/Content/images/Shared/CheckOff.png);
        display: inline-block;
        height: 27px;
    }

    .checkDisable {
        background: no-repeat url(/Content/images/Shared/GreyCheckNoBorder.png);
        width: 27px;
        display: block;
        height: 27px;
    }

    .custom-columns {
        padding: 0;
    }

    .row.carriers, .row-input-message {
        display: flex;

        .custom-columns {
            &:first-child {
                & > span {
                    display: flex;
                    flex-direction: column;
                    width: 100% !important;

                    &.is-invalid-input:not(:focus) {
                        background-color: transparent;
                    }

                    &[aria-disabled="true"] {
                        cursor: not-allowed;
                    }
                }
            }

            &:last-child {
                margin-left: 10px;
            }

            .form-error {
                margin-top: 0.25rem;
                margin-bottom: 0;

                &:hover {
                    background-color: transparent;
                    background-image: none;
                    border-color: none;
                    color: #cc4b37;
                }
            }

            .alertLabel {
                display: flex;
                font-size: 14px;
                padding-left: 10px;
                width: calc(100% - 10px);
            }
        }
    }

    .row.carriers {
        align-items: center;

        .custom-columns {
            &:first-child {
                width: 30%;
            }

            &:last-child {
                width: calc(70% - 10px);
            }
        }
    }

    .row-input-message {
        .custom-columns {
            &:first-child {
                width: 50%;
            }

            &:last-child {
                width: calc(50% - 10px);
            }
        }
    }
}