﻿.newjobsite-container {
    display: none;

    .headline-3 {
        padding-bottom: 20px;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 42px;
        text-align: center;
        color: #333333;
    }

    &.modal-close {
        display: contents;
    }

    input::placeholder {
        color: #5A5A5A;
    }

    .required-note {
        display: flex;
        justify-content: flex-start;

        .required-note-subtext {
            padding-left: 70px;
            
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #222222;

            @media (max-width: 640px) {
                padding-left: 0px;
            }
        }

        @media (max-width: 640px) {
            text-align: left;
        }
    }

    .modal-action-container {
        .primary-button {
            padding-top: 0;
        }

        .disabled-button {
            background-color: dimgrey !important;
            letter-spacing: 0.25px;
            line-height: 28px;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
        }

        @media (max-width: 640px) {
            padding: 20px 0;

            .primary-button {
                width: 100%;
            }
        }
    }

    .modal-button-close:after, .modal-button-close:before {

        @media (max-width: 640px) {
            width: 16px;
            height: 2px;
            left: 59%;
        }
    }

    .location-suggestion-container {
        margin: 0;
        width: 75%;
        top: 44.5%;
        position: absolute;
        z-index: 9999;

        @media (max-width: 640px) {
            top: 210px;
            width: 91%;
            padding-right: 0;
        }

        .default-suggestion {
            text-align: left;
        }

        .location-suggestion-dropdown {
            text-align: left;
            padding-left: 0;
            padding-top: 5px;

            .suggestion-item {
                line-height: 28px;

                &:hover {
                    background-color: #f3f3f3;
                }

                .suggestion-display {
                    padding-left: 8px;
                }
            }
        }
    }
}

@media (max-width: 640px) {

    .takeover .takeover-inner {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .takeover .takeover-body {
        padding-left: 10px;
        padding-right: 10px;
        height:85vh;
        overflow-y:scroll;
    }

    .zip-message {
        line-height: 14px;        
    }
}
