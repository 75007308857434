ul {
    > li {
        &:last-child {
            margin-bottom: 0;
        }
        &.hd-5 {
            line-height: inherit;
            margin-bottom: 1rem;
        }
    }
}