﻿#CheckoutWhere {
    .k-grid-reset {
        display: none;
    }
    .title {
        color: #000000;
        font-size: 1.4em;
        font-weight: bold;
        margin-bottom: 4px;
    }
    .subtitle {
        color: grey;
        font-size: 1.1em;
        margin-bottom: 8px;
    }
    #sbAccountSelector .accountSelectorContainer {
        width: 100%;
        float: none;
    }
    .PONumber {
        .POInput {
            // Note doesn't use standard input because it doesn't match the accountSelector
            // style and this input is positioned right beside the account selector
            // --------------------------------------------------------------------------------
            input[type="text"] {
                color: #000000;
                font-size: 1.1em;
                margin: 4px 0px 4px 0px;
                padding: 12px 8px;
                overflow: visible; /* IE Hack to fix horizontal padding */
            }
        }
    }
    .WhereAndHowDiv {
        @media only screen and (min-width : $screen-sm-min) {
            padding-top: 35px;
        }
        .row > div {
            margin-top: 35px;
        }
        img.option {
            float: left;
            margin-right: 10px;
        }
        label.option {
            float: left;
            margin-top: 4px;
        }
        .PickupOption {
            float: left;
            margin-top: 10px;
            cursor: pointer;
        }
        .DeliveryOption {
            float: left;
            margin: 10px 30px 0 10px;
            cursor: pointer;
        }
        .HowContent {
            height: 150px;
        }
        .WhereContent {
            .WhereContentTitle {
                float: left;
            }
            #JobSiteCreateLink {
                float: right;
                cursor: pointer;
                margin-top: 18px;
                margin-bottom: 8px;
            }
        }
        #DeliveryNotice {
            display: block;
            font-size: 14px;
            clear: both;
            padding-top: 10px;
            padding-left: 10px;
            color: #444343;
        }
        #CheckoutPCPanel {
            margin-top: 20px;
            #ChangeBranchLink {
                display: block;
                float: left;
                margin-right: 20px;
                cursor: pointer;
            }
            .PCDetails {
                float: left;
                font-size: 14px;
                span.block {
                    display: block;
                }
                span.top-padding {
                    padding-top: 10px;
                }
                span.grey {
                    color: grey;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    }
    .Buttons {
        margin: 30px 0px 0px 0px;
        text-align: center;
        @include breakpoint(medium) {
            text-align: right;
        }
        a {
            width: 120px;
            margin-left: 6px;
            cursor: pointer;
        }
    }
}
#CheckoutChooseLocationWindow {
    #LocationResults {
        min-height: 230px;
        margin-bottom: 10px;
        div.k-grid-header, div.k-grid-footer {
            display:block !important;
        }
    }
    #LocationsWindowButtonContainer {
        text-align: right;
    }
    #CancelLocationLink {
        cursor: pointer;
    }
    #ConfirmLocationLink {
        cursor: pointer;
        margin-left: 4px;
    }
    .searchBarText {
        font-size: 14px;
        vertical-align: middle;
        float: left;
        display: block;
        padding-top: 9px;
        margin-right: 10px;
    }
    #LocationsNearMeButton {
        float: right;
    }
    #LocationsSearchButton {
        float: right;
        margin-right: 10px;
        margin-left: 10px;
    }
    #LocationsSearchBar {
        margin-bottom: 20px;
        border-radius: initial;
        border-color: initial;
        border-style: initial;
        position: relative;
        z-index: initial;
        border-width: initial;
        background-color: initial;
        padding: initial;
    }
    #profitCenterMapSearch {
        width: 100%;
        padding-left: 7px;
        padding-right: 7px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        -khtml-border-radius: 3px;
        border: 1px solid #c2c2c2;
        outline: 0px;
        height: 34px;
        -moz-box-shadow: inset -2px 2px 4px 0px #ebebeb;
        -webkit-box-shadow: inset -2px 2px 4px 0px #ebebeb;
        box-shadow: inset -2px 2px 4px 0px #ebebeb;
        vertical-align: middle;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}
#checkoutWhereConfirmButtons {
    margin-top: 10px;
}