﻿#CheckoutWhen {
    .Title {
        display: block;
        font-size: 22px;
        margin-bottom: 15px;
    }
    .EmptyCart {
        height: 335px;
    }
    .RentalDates {
        .StartDate {
            margin-bottom: 8px;
            label {
                font-size: 18px;
            }
        }
        .EndDate {
            margin-top: 5px;
            label {
                font-size: 18px;
            }
        }
        .FavoriteButton {
            cursor: pointer;
        }
    }
    .Items {
        margin-top: 15px;
    }
    .price {
        font-size: 1.6em;
        text-align: right;
        margin-right: 15px;
    }
    .AvailabilityWarning {
        margin-top: 5px;
        color: #fd5757;
        font-size: 12px;
        display:none;
        i {
            color: red;
            font-size: 14px;
        }
        label {
            margin-bottom: 0px;
        }
    }
    .whenValueAddRow {
        margin-bottom: 18px;
    }
    .RPPContent {
        text-align: right;
        label {
            margin-right: 5px;
            color: #737373;
            font-size: 18px;
            @include breakpoint(medium) {
                font-size: 22px;
            }
        }
        img {
            margin-left: 20px;
            display: inline-block;
            vertical-align: top;
            margin-top: 2px;
        }
        span {
            display: inline-block;
            text-align: right;
            font-size: 18px;
            @include breakpoint(medium) {
                font-size: 22px;
            }
        }
    }
    .RPPSubContent {
        text-align: right;
        white-space: nowrap;
        label {
            font-size: 14px;
            color: #7e7e7e;
        }
        a {
            cursor: pointer;
            margin-right: 57px;
            font-size: 14px;
        }
        span {
            white-space: normal;
            display: inline-block;
            text-align: right;
            font-size: 14px;
            color: #ff0000;
        }
    }
    .FuelConvContent {
        text-align: right;
        label {
            margin-right: 5px;
            color: #7e7e7e;
            font-size: 18px;
            @include breakpoint(medium) {
                font-size: 22px;
            }
        }
        img {
            margin-left: 20px;
            display: inline-block;
            vertical-align: top;
            margin-top: 2px;
        }
        span {
            display: inline-block;
            text-align: right;
            font-size: 18px;
            @include breakpoint(medium) {
                font-size: 22px;
            }
        }
    }
    .FuelConvEstimate {
        text-align: right;
        span {
            white-space: normal;
            display: inline-block;
            text-align: right;
            color: #ff0000;
            font-size: 14px;
        }
    }
    .Totals {
        text-align: right;
        margin: 0 0px 80px 0px;
        hr {
            border-top: solid 1px #ccebd6;
            border-bottom: none;
            border-left: none;
            border-right: none;
            margin: 0;
            margin-bottom: 24px;
        }
        label {
            margin-right: 10px;
            color: #7e7e7e;
            font-size: 22px;
        }
        span {
            display: inline-block;
            text-align: right;
            font-size: 22px;
        }
        em {
            display: block;
            float: right;
            max-width: 500px;
            margin-top: 10px;
            color: #7e7e7e;
            font-size: 12px;
        }
    }
    .SpecialInstructions {
        text-align: right;
        margin: 0 0px 20px 0px;
        label {
            margin-right: 10px;
            font-size: 16px;
            color: darkgray;
        }

        .SubText {
            font-size: 14px;
            color: #ababab;
            white-space: nowrap;
        }
        #SpecialInstructions {
            height: 60px;
            max-width: 500px;
            float: right;
        }
        em {
            font-size: 12px;
            color: #ababab;
        }
    }
    .AdditionalEmailRecipients {
        text-align: left;
        margin: 0 0px 20px 0px;
        float: right;
        width: 100%;
        max-width: 500px;
        label {
            margin-right: 10px;
            font-size: 16px;
            color: darkgray;
        }
        em {
            margin-right: 10px;
            font-size: 12px;
            color: #ababab;
        }
        #email-row-container {
            span.email-span {
                display: block;
                overflow: hidden;
                padding-right: 10px;
            }
            #emailAddressTextBox {
                margin-right: 10px;
            }
            #addEmail {
                width: 100px;
                float: right;
            }
        }
        table.email-tables {
            margin-bottom: 0px;
        }
        #emailsTable i.fa.fa-times-circle {
            margin-left: 6px;
        }
        table.email-tables thead, table.email-tables tbody, table.email-tables tfoot, table.email-tables tr {
            border: 0px;
            border-bottom: 0px;
            background-color: transparent;
        }
        table.email-tables thead th, table.email-tables thead td, table.email-tables tfoot th, table.email-tables tfoot td {
            padding: 0px;
            font-weight: initial;
            text-align: initial;
        }
    }
    .Agreements {
        text-align: left;
        margin: 0 0px 32px 0px;
        position: relative;
        max-width: 500px;
        float: right;
        width: 100%;
        label {
            margin-bottom: 10px;
            color: darkgray;
            font-size: 16px;
        }
        hr {
            border-top: solid 1px #ccebd6;
            border-bottom: none;
            border-left: none;
            border-right: none;
            margin: 0;
            margin-bottom: 11px;
            margin-top: 4px;
        }
        .AgreementOptions {
            img.option {
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 9px;
            }
            label.option {
                display: inline-block;
                margin: 0;
                text-align: left;
                margin-top: 8px;
                margin-bottom: 16px;
                font-size: 12px;
                color: darkgray;
            }
        }
        .TermsButton {
            a {
                cursor: pointer;
            }
        }
    }
    .Buttons {
        margin: 15px 0px 40px 0;
        text-align: right;
        a {
            width: 120px;
            margin-left: 6px;
            cursor: pointer;
        }
    }
}
