.category-item {
    padding-top: 30px;
    padding-bottom: 30px;
}
.category-item-wrap {
    margin-bottom: $grid-spacing/2;
}
#categoryWrap {
    @include breakpoint(medium) {
        margin-bottom: $grid-spacing*3;
    }
}
.category-item_img {
    @include breakpoint(small only) {
        padding-right: 0;
    }
}
.category-item_content .dropdown.menu {
    > li > a {
        padding-left: 0;
    }
}
.category-item_pricing {
    @include breakpoint(medium) {
        max-width: 320px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    > li {
        display: block;
        @include breakpoint(medium) {
            display: table-cell;
        }
    }
}

.category-item_content .category-item_cart {
    @include breakpoint(medium) {
        display: flex;
        align-items: center;
    }
    > .qty {
        @include breakpoint(medium) {
            min-width: 122px;
            margin-right: 4rem;
            > .add-button, .input-group {
                   margin-bottom: 0;
            }
        }
    }
}