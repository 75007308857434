﻿#AccountSummaryContent {
    position: relative;
    border-radius: 3px;
    .ShadowBoxContent {
        width: 100%;
        padding: 5px;
        display: inline-block;
        box-sizing: border-box;
    }
    .ShadowBox {
        display: inline-block;
        padding: 1px 1px 14px 1px;
        width: 100%;
        background-image: url('/Content/images/Shared/ShadowBoxSmall.png');
        background-repeat: no-repeat;
        background-position: center bottom;
        box-sizing: border-box;
    }
    .maintitle {
        color: black;
        font-size: 20px;
        font-weight: bold;
        margin-left: 9px;
    }
    .subTitle {
        color: #009c39;
        font-size: 18px;
        text-align: right;
        font-weight: bold;
    }
    label {
        margin-right: 100px;
        display: inline-block;
        width: 218px;
        font-weight: bold;
    }
    table {
        width: 100%;
        padding-top: 5px;
        font-size: 16px;
        td:first-child {
            font-weight: bold;
            text-align: left;
        }
        /* Panel Bar Header Content */
        td {
            color: black;
            font-weight: normal;
            text-align: right;
            padding-right: 12px;
        }
        th {
            color: black;
            font-weight: bold;
            text-align: right;
            padding-right: 12px;
        }
        .total {
            text-align: right !important;
            font-weight: normal !important;
        }
        .panelbarHeading {
            font-size: 18px;
            padding-left: 19px;
        }
    }
    /* Remove borders */
    ul.k-panelbar,
    ul.k-panelbar .k-group,
    ul.k-panelbar .k-content,
    ul.k-panelbar .k-link {
        border-width: 0;
    }
    /* Panel Bar Header */
    .k-panelbar .k-header {
        background-color: #009c39;
    }
    .k-panelbar .k-item .k-link, .k-panel .k-item .k-link {
        line-height: 2em;
    }
    /* Hide Arrows in the left */
    .k-panelbar .k-panelbar-expand,
    .k-panelbar .k-panelbar-collapse {
        right: auto;
    }
    .k-panelbar .k-content {
        background-color: #fff;
    }
    table {
        margin-bottom: 0px;
    }
    table tbody td {
        padding: 3px;
    }
    table tbody {
        border: 0px;
    }
    table tbody tr {
        border-bottom: 0px;
    }
    @media (max-width: $screen-sm-max) {
        width: 100vw;
    }
}
