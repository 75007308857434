﻿#MyProfileContainer {
    .account-container {
        height: 615px;
        background-color: #fcfcfc;
        padding: 25px 0;
        border: 1px solid #D9D9D9;
        color: #000000;
        font-family: Roboto;
        font-style: normal;
        margin-top: 1px;

        #change-password{
            border: 2px solid #008545;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 20px;
            color: #008545;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            text-decoration: none;
        }

        strong, p{
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 7px;
            word-break: break-word;
        }

        strong{
            font-weight: bold;
        }

        .margin-bottom{
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 768px) {
            margin: 1px 24px 0;
            height: 60vh;
        }

        @media only screen and (max-width: 480px) {
            #change-password{
                width: 100%;
            }
        }

        /*iPad 9th gen*/
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
            height: 60vh;
            margin: 1px 25px 0;
            padding-left: 25px;;
        }
    }
    .tabContainer {
        margin-bottom: 0px;
        border-radius: 0px 4px 4px 4px;
        border-color: #ccc;
        border-style: solid;
        position: relative;
        z-index: 2;
        border-width: 1px;
        background-color: #fcfcfc;
        padding: 12px;
        margin-top: 1px;
    }
    #mpNavTabs {
        border-bottom: none;
        > li {
            margin-bottom: -2px;
        }
        li > a {
            margin-right: 0px;
        }
        li.active {
            > a {
                border: 1px solid $cc-border-color;
                border-bottom: 1px solid #D9D9D9;
                background-color: #fcfcfc;
                z-index: 3;
            }
        }
    }
}
div[name='ESubscriptions'] {
    padding: 25px;
    font-size: 12px;
    div {
        display: inline-block;
    }
    .ESubAgreementSection {
        display: flex;
        align-items: center;
        height: 50px;
        #ESubCheckBox {
            display: inline-block;
            margin: 0;
            min-width: 40px;
        }
    }
    .ESubscriptionGreenText {
        color: #009c39;
        font-size: 18px;
        width: 89%;
        margin: 0px 0px 20px 5px;
        font-weight: bold;
    }
    .elabel {
        margin-right: 20px;
        display: inline-block;
        width: 50px;
        color: #000000;
        font-size: 12px;
    }
    input[type='text'] {
        width: 300px;
        text-align: left;
        font-size: 10px;
        margin-bottom: 0px;
    }
    .textEmailAddressSeparator {
        font-size: .8em !important;
        line-height: 9px !important;
        margin: 0px 0px 25px 0px;
    }
}
.checkOff {
    background: no-repeat url(/Content/images/Shared/CheckOff.png);
    width: 27px;
    height: 27px;
    display: inline-block;
    margin: 0px 6px -3px -1px;
}
.checkOn {
    background: no-repeat url(/Content/images/Shared/CheckOn.png);
    width: 27px;
    height: 27px;
    display: inline-block;
    margin: 0px 6px -3px -1px;
}