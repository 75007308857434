﻿.list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-left: 0px;
}
.sb-mobile-view {
    color: #5d5d5d;
    @media only screen and (min-width: 420px) {
        display: none;
    }
}
.k-sb-grid {
    .sbgrid-mobile-template {
        li:first-child {
            font-weight: bold;
        }
    }
    ul {
        margin-bottom: 2px;
    }
    .GridSbHeaderContainer {
        margin-top: 4px;
        span {
            display: block;
            overflow: hidden;
            @media only screen and (min-width: 420px) {
                padding-right: 10px;
            }
            input {
                width: 100%;
                border-radius: 4px;
                padding: 5px 14px 4px;
                font-family: inherit;
                line-height: 1.72em;
            }
        }
        .GridSbButtonContainer {
            float: right;
            .GridPopupButton {
                padding-right: 8px;
                padding-left: 8px;
            }
        }
    }
    .GridFilterBreadCrumbsContainer {
        ul {
            margin: 5px 0px 5px 0px;
            padding-left: 2px;
            li {
                margin-bottom: 5px;
                list-style-type: none;
            }
            .filtervaluecontainer {
                padding-top: 1px;
                padding-bottom: 1px;
                padding-left: 3px;
                background-color: #FCFCFC;
                border-radius: 4px;
                margin-right: 3px;
                padding-right: 3px;
                white-space: nowrap;
                line-height: 17px;
                a.filterCloseCircle {
                    position: relative;
                    top: 1px;
                    margin-left: 3px;
                    img {
                        vertical-align: baseline;
                    }
                }
            }
        }
        img.filterOr {
            vertical-align: middle;
        }
    }
}
