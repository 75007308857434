.section_wrap {
    margin-top: $grid-spacing/2;
    @include breakpoint(medium) {
        margin-top: $grid-spacing;
    }
    @include breakpoint(large) {
        margin-top: $grid-spacing*1.5;
    }
}
.section_spacer {
    padding-top: $grid-spacing*.75;
    padding-bottom: $grid-spacing*.75;
    @include breakpoint(medium) {
        padding-top: $grid-spacing*1.5;
        padding-bottom: $grid-spacing*1.5;
    }
    @include breakpoint(large) {
        padding-top: $grid-spacing*2.5;
        padding-bottom: $grid-spacing*2.5;
    }
}
.hr-row {
    @include breakpoint(small only) {
        .row & {
            margin-left: -($grid-spacing);
            margin-right: -($grid-spacing);
        }
    }
}
