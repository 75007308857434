.breadcrumb-wrap {
    background-color: $off-white;
    padding-top: $grid-spacing/2;
    padding-bottom: $grid-spacing/2;
    &.clean {
        background-color: transparent;
    }
}
.breadcrumb-wrap .breadcrumbs {
    line-height: 2;
    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}
.breadcrumb-wrap .breadcrumbs li:not(:last-child)::after {
    content: ' ';
    display: inline-block;
    transform: rotate(45deg);
    width: 6px;
    height: 6px;
    border-top: 1px solid $dark-gray;
    border-right: 1px solid $dark-gray;   
    top: -1px;
    right: 4px; 
}
.jobsite_location {
    > p {
        margin-bottom: 0;
        > .raised {
            @include breakpoint(small only) {
                font-size: rem-calc(14);
            }
        }
        @include breakpoint(small only) {
            &:first-child {
                float: left;
            }
            &:last-child {
                float: right;
            }
        }
    }
    @include breakpoint(small only) {
        display: flex;
        justify-content: space-between;
    }
}
