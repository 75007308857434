﻿#EquipmentActionPageWrapper {
    .error {
        border-color: red !important;
        background-color: rgb(255, 221, 221) !important;
    }
    .subText {
        color: rgb(124, 124, 124);
    }
    .ddLabel {
        width: 57px;
        display: inline-block;
        height: 32px;
    }
    .equipmentactiongrid {
        margin-top: 8px;
        margin-bottom: 21px;
        input[type="text"] {
            -webkit-box-shadow: inset 2px 2px 8px 0px rgba(0, 0, 0, 0.09);
            box-shadow: inset 2px 2px 8px 0px rgba(0, 0, 0, 0.09);
            border-radius: 3px;
            border: 1px solid #C2C2C2;
            outline: 0px;
            padding: 5px;
            color: rgb(58, 58, 58);
        }
    }
    #EquipmentGridStep2 {
        margin-bottom: 22px;
    }
    .equipmentactionbottombuttoncontainer {
        text-align: right;
        padding-bottom: 10px;
        a.SBGreenButton {
            margin-left: 10px;
            margin-top: 10px;
        }
    }
    #ExtendEquipmentStep1FormBottomLeft {
        text-align: left;
        display: inline-block;
    }
    #ExtendEquipmentStep1FormBottomRight {
        display: inline-block;
        vertical-align: bottom;
    }
    #ExtendEquipmentStep1FormBottomRight2 {
        display: inline-block;
        vertical-align: bottom;
    }
    .EquipmentActionStepBottomRight {
        display: inline-block;
        vertical-align: top;
    }
    #ReturnEquipmentComments {
        height: 92px;
    }
    #ServiceEquipmentPartialWrapper {
        .ddLabel {
            width: 100px !important;
        }
        #ServiceEquipmentComments {
            display: block;
            height: 109px;
            margin-bottom: 2px;
        }
        #ServiceEquipmentStep2AdditionalEmailInput {
            display: block;
            margin-left: 0px;
            margin-bottom: 2px;
        }
    }
    #ReturnEquipmentPartialWrapper {
        #ReturnEquipmentStep2 {
            .equipmentactionbottombuttoncontainer {
                margin-top: 20px;
            }
        }
    }
    #JobSiteFormWrapper {
        textarea[name="Comments"] {
            width: 100%;
        }
        .statusLabel {
            font-size: 18px;
            display: inline-block;
            width: 185px;
            color: #a5a5a5;
        }
        .status {
            font-size: 18px;
            color: #5c5c5c;
        }
        #JobSiteFormAddressValidationContainer {
            overflow: hidden;
        }
    }
}
#ChangeContractDiv {
    .form-control-static {
        min-height: initial;
    }
}
#ChangeContractDiv .error-message {
    color: red;
}

#eor-details-container {
    font-size: 12px;
    h5 {
        margin-top: 8px;
        margin-bottom: 8px;
        font-weight: bold;
        margin-left:-15px;
    }
    .columns {
        padding-left:15px;
        padding-right:15px;
    }
    .form-group {
        margin-bottom: 2px;
        p {
            padding-top: 0px;
            padding-bottom: 0px;
            margin-bottom:0px;
        }
    }
    .form-control-static {
        min-height: initial;
    }
    .form-horizontal .control-label {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}
#EquipmentMaintenanceCloseButton {
    float: right;
    margin-top: 5px;
}
#MaintenanceHistoryLeftInfo {
    margin-bottom: 10px;
    font-size: large;
}
#MaintenanceHistoryGridTitle {
    font-weight: bold;
}
#MaintenanceHistoryTitle {
    font-weight: bold;
}
