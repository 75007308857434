﻿.cm-login-page-inner-wrapper {
    .inner-wrap-general {
        .columns {
            .label-headers {
                margin-bottom: 5px;
            }
        }

        #cm-lower-signin-group {
            > div {
                position: relative;
                float: left;
                width: 100%;
            }

            .cm-forgot-password-group {
                max-width: 210px;

                a {
                    font-weight: 600;
                    color: $cm-black;
                    text-decoration: none;

                    span {
                        color: $primary-color;
                    }
                }
            }

            .cm-submit-btn-group {
                max-width: 120px;
                float: right;

                .button {
                    float: right;
                }
            }
        }
    }

    .cm-input-wraps {
        margin-bottom: 20px;

        .cm-form-inputs {
            margin-bottom: 5px;
        }

        .help-block {
            display: block;
            color: $cm-red;
        }
    }

    .left-side-inner {
    }

    .right-side-inner {
    }

    .cm-need-an-account {
        clear: both;
    }

    #cclogo {
        display: none;
        height: 100px;
        position: relative;

        @media(min-width: 640px) {
            display: block;
        }

        @media(min-width: 640px) and (max-width: 1000px) {
            height: 90px;
        }

        img {
            position: absolute;
            display: block;
            right: 0;
            max-width: 152px;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    #login-video {
        max-width: 330px;
        height: 250px;
        padding-left: 33px;
        padding-top: 23px;
        padding-bottom: 50%;

        @media(min-width: 640px) and (max-width: 1000px) {
            height: 235px;
            padding-bottom: 0;
            padding-top: 0;
        }

        iframe {
            position: relative;
            height: 182px;
        }

        a {
            font-weight: 900;
            text-decoration: none;
            color: $primary-color;
        }
    }

    .cm-features-ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0 0 15px 0;
            background-image: url(/Content/img/check_icon.png);
            background-position: 0 0;
            background-repeat: no-repeat;
            padding-left: 33px;

            span {
                font-weight: 900;

                a {
                    text-decoration: none;
                    color: $primary-color;
                }
            }
        }
    }
}

#cm-submit-button {
    color: $cm-white;
    /*background-color: $cm-darkgreen;*/
}

.green-highlight {
    color: $primary-color;
}

#LoginPanel {
    .validation-summary-errors {
        margin-top: 20px;
        color: red;
        font-size: 20px;

        ul {
            list-style: none;
            margin-left: 0px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
    .column, .columns {
        padding-right: 0.14286rem;
        padding-left: 0.14286rem;
    }


    /** Ipad devices fix*/
    #IpadDeviceFix {
        padding-left: 2.3rem !important;
    }

    #CreditApplicationGetStarted1 {
        padding-left: 2.3rem !important;
    }
    .auth-content {
        margin-left: 10px !important;
        margin-right: 0px !important;
    }

    #okta-sign-in.auth-container.main-container {
        box-shadow: none !important;
    }

    .auth-container {
        width: 350px !important;
    }

    #login-video {
        height: 235px;
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 4px !important;
    }

    #login-video iframe {
        position: relative;
        height: 159px !important;
    }
}