.checkout-header {
    background: $primary-color;
    color: $white;
    overflow: hidden;
    margin-bottom: $grid-spacing/2;
    @include breakpoint(medium) {
        margin-bottom: $grid-spacing;
    }
    .step {
        @include grid-column(3);
        @include grid-row;
        @include breakpoint(small only) {
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 6px;
            padding-right: 6px;
        }
        @include breakpoint(medium) {
            padding-top: $grid-spacing/2;
            padding-bottom: $grid-spacing/2;
        }
        .step-number {
            @include grid-column(12,0);
            margin-bottom: 4px;
            @include breakpoint(medium) {
                @include grid-column(3,0);
                margin-bottom: 0;
                padding-right: 8px;
            }
        }
        .step-info {
            @include grid-column(12,0);
            .step-title {
                text-transform: uppercase;
                overflow-wrap: normal;
                margin-bottom: 0;
            }
            @include breakpoint(medium) {
                @include grid-column(9,0);
                .step-title {
                    margin-bottom: rem-calc(7);
                    text-align: left;
                    margin-top: 14px;
                }
            }
            .step-details {
                display: none;
                @include breakpoint(medium) {
                    display: block;
                    font-size: rem-calc(14);
                }
                @include breakpoint(medium) {
                    font-size: rem-calc(16);
                }
                @include breakpoint(xlarge) {
                    font-size: rem-calc(18);
                }
            }
        }
        .step-number,
        .step-title {
            color: $white;
            text-align: center;
            text-transform: none;
        }
        &.active {
            position: relative;
            &::after {
                display: block;
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                @include breakpoint(xlarge) {
                    left:37%;
                }
                transform: translateX(-50%);
                border: 7px solid transparent;
                border-bottom: 7px solid $white;
            }
        }
    }
}
