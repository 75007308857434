﻿#sbAccountSelector {
    .accountSelectorContainer {
        float: right;
        width: 400px;
    }
    .sbAccountSelectorDD {
        width: 320px;
    }
}
@media screen and (min-width: $screen-md-min) {
    #sbAccountSelectorMobile {
        display: none;
    }
}
#sbAccountSelectorMobile {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    .accountSelectorContainer {
        margin-bottom: 5px;
    }
    .sbAccountSelectorDD {
        max-width: 390px;
        width: 100%;
    }
}
.sbAccountSelector {
    .accountSelectorContainer {
        display: block;
        padding-bottom: 10px;
        position: relative;
        height: 50px;
    }
    .AccountSearch2 {
        background-color: #F3F3F3;
        maxWidth: 397px;
        width: 100%;
        color: #444;
        font-family: Helvetica;
        font-size: 1em;
        border: none;
        margin: 4px 0px 4px 0px;
        padding: 14px 74px 14px 12px;
        overflow: visible;
        behavior: url(/Content/css/Shared/PIE.htc);
        border-radius: 5px;
        position: absolute;
        height:48px;
    }
    .AccountSelectStarIcon {
        z-index: 0;
        position: absolute;
        right: 39px;
        top: 14px;
    }
    .AccountSelectFavsIcon {
        position: absolute;
        top: 7px;
        right: 3px;
        border-right: 9px solid transparent;
        border-bottom: 12px solid transparent;
        border-top: 13px solid transparent;
        border-left: 5px solid transparent;
    }
}
.acsel2favicon {
    height: 18px;
    cursor: pointer;
}
.achighlight {
    color: red;
}
.acs2optionicon {
    cursor: pointer;
}
.ac2container {
    height: auto;
    overflow: hidden;
    padding: 2px;
}
.ac2container:hover {
    background: #FFEFBB;
    border-radius: 4px;
}
.ac2right {
    width: 20px;
    float: right;
    height: 18px;
}
.ac2left {
    width: auto;
    overflow: hidden;
    height: 18px;
}
.caseperator {
    padding-left: 5px;
    color: #5a5a5a;
    border-bottom: solid #cecaca 1px;
    padding-bottom: 2px;
    margin-bottom: 3px;
    padding-top: 6px;
}
