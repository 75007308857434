a {
    transition: color 125ms cubic-bezier(0.4,0,0.2,1);
    li &, p &, label & {
        color: $black;
    }
}
a.button, 
.breadcrumbs a,
.top-bar a,
.tabs-title a,
.menu a {
    text-decoration: none;
}
.text-link {
    font-family: $header-font-family;
    font-weight: $global-weight-bold;
    color: $primary-color;
    text-transform: uppercase;
    letter-spacing: 0;
    &:hover {
        opacity: 0.5;
        text-decoration: none;
    }
    &.secondary {
        color: $medium-gray;
    }
}
.sunbelt-green {
    color: $primary-color;
    a, p, strong {
        color: $primary-color;
    }
}