﻿.pre-footer_cta-wrap, .find-equipment_wrap, .home-equipment-slider_wrap {
    padding-top: map_get($section-spacing-top, small);
    padding-bottom: map_get($section-spacing-bottom, small);
    @include breakpoint(large) {
        padding-top: map_get($section-spacing-top, large);
        padding-bottom: map_get($section-spacing-bottom, large);
    }
    .title {
        text-align: center;
    }
}
.cart-addition .qty .add-button,
.category-item_content p,
.category-item_content .dropdown.menu,
.category-item_pricing,
.product_detail_pricing {
    margin-bottom: $form-spacing/2;
    @include breakpoint(medium) {
        margin-bottom: $form-spacing + rem-calc(1);
    }
}