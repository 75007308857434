/*
 * Overlay Banners - content is tied to background image.
 * Image stays in background on mobile/tablet.
 *
 */
$banner-min-heights: (
  small: 360px,
  medium: 420px,
  xmedium: 420px,
  large: 567px,
  xlarge: 640px,
  xxlarge: 800px,
) !default;
$banner-padding: (
  small: 40px,
  medium: 60px,
  xmedium: 69px,
  large: 60px,
  xlarge: 80px,
  xxlarge: 80px,
) !default;
@mixin overlay-banner {
    > .row {
        @each $size in $breakpoint-classes {
            @include breakpoint($size) {
                height: map_get($banner-min-heights, $size);
            }
        }
        > .columns {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .image_container {
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
            .info {
                z-index: 1;
                position: inherit;
                > h1, > h2, > h3 {
                    line-height: 1;
                }
            }
        }
    }
    .image_container > img {
        display: none;
    }
}
@mixin banner-info-padding {
    @each $size in $breakpoint-classes {
        @include breakpoint($size) {
            min-height: map_get($banner-min-heights, $size);
            .info {
                padding-left: map_get($banner-padding, $size);
                padding-right: map_get($banner-padding, $size);
            }
        }
    }
}
@mixin banner-invert {
    h1,h2,h3,h4,h5,h6,p, a, input, .title, ul, cite, .info strong {
        color: $white;
    }
    a {
        text-decoration: none;
        &.link {
            text-decoration: underline;
        }
        &:hover:not(.text-link) {
            text-decoration: underline;
        }
    }
    input, input:focus {
        background-color: transparent;
    }
    input:focus {
        border-color: $white;
    }
    input::-webkit-input-placeholder {
       color: $white;
    }

    input:-moz-placeholder { /* Firefox 18- */
       color: $white;  
    }

    input::-moz-placeholder {  /* Firefox 19+ */
       color: $white;  
    }

    input:-ms-input-placeholder {  
       color: $white;  
    }

    .home_locations_bar.invert-colors input:focus {
        border-color: #0a0a0a;
    }
    .home_locations_bar.invert-colors input::-webkit-input-placeholder {
       color: #0a0a0a;
    }

    .home_locations_bar.invert-colors input:-moz-placeholder { /* Firefox 18- */
       color: #0a0a0a;  
    }

    .home_locations_bar.invert-colors input::-moz-placeholder {  /* Firefox 19+ */
       color: #0a0a0a;  
    }

    .home_locations_bar.invert-colors input:-ms-input-placeholder {  
       color: #0a0a0a;  
    }
}
.content-banner {
    &.full_width .row .columns {
        text-align: left;
    }
    .image_container {
        background-repeat: no-repeat;
        height: auto; 
        width: 100%;
    }
    .info {
        padding-top: $grid-spacing;
        padding-bottom: $grid-spacing;
    }
    @include banner-info-padding;
    p:last-child, a.button, a:last-child {
        margin-bottom: 0;   
    }
    @include breakpoint(large) {
        @include overlay-banner;
        .info {
            background-color: transparent !important;
            width: 50%;
            max-width: 720px;
        }
    }
    &.banner-auto {
        min-height: 0;
    }
}
@each $size in $breakpoint-classes {
    @include breakpoint($size) {
        @if $size != $-zf-zero-breakpoint {
            .#{$size}-overlay-banner {
                p:last-child {
                    margin-bottom: 0;   
                }
                @include overlay-banner;
                > .row {
                    @include banner-info-padding;
                }
            }
        }
        @else {
            .overlay-banner {
                p:last-child, a.button:last-child {
                    margin-bottom: 0;   
                }
                @include overlay-banner;
                > .row {
                    @include banner-info-padding;
                }
            }
        }
    }
}
.banner-auto {
    > .row {
        min-height: auto;
        height: auto;
        > .columns .info {
            @each $sz in $breakpoint-classes {
                @include breakpoint($sz) {
                    padding-top: 1.333*map_get($banner-padding, $sz);
                    padding-bottom: 1.333*map_get($banner-padding, $sz);
                }
            }
        }
        @include breakpoint(large) {
            min-height: auto;
        }
    }
}
@each $size in $breakpoint-classes {
    @include breakpoint($size) {
        @if $size != $-zf-zero-breakpoint {
            .#{$size}-banner-invert {
                @include banner-invert;
            }
        }
        @else {
            .banner-invert {
                @include banner-invert;
            }
        }
    }
}
// generate banner content alignment classes
@each $size in $breakpoint-classes {
    @include breakpoint($size) {
        @each $align in (left, right) {
            $jc: center;
            $center-align: left;
            @if $align == left { $jc: flex-start; }
            @if $align == right { $jc: flex-end; }
            @if $align == left { $center-align: right; }
            @if $align == right { $center-align: left; }
            @if $size != $-zf-zero-breakpoint {
                .#{$size}-banner-#{$align} > .row > .columns {
                    justify-content: $jc;
                    .info {
                        text-align: $align;
                        padding-#{$align}: map_get($banner-padding, $size);
                        @include breakpoint(xxlarge) {
                            margin-#{$align}: 10%;
                        }
                    }
                    cite {
                        text-align: $align;
                    }
                }
                .#{$size}-banner-center-#{$align} > .row > .columns {
                    justify-content: $jc;
                    .info {
                        text-align: $center-align;
                        padding-#{$center-align}: 0;
                        padding-#{$align}: map_get($banner-padding, $size);
                        @include breakpoint(xxlarge) {
                            margin-#{$align}: 10%;
                        }
                    }
                    cite {
                        text-align: $center-align;
                    }
                }
            }
            @else {
                .banner-#{$align} > .row > .columns {
                    justify-content: $jc;
                    @each $sz in $breakpoint-classes {
                        @include breakpoint($sz) {
                            .info {
                                padding-#{$align}: map_get($banner-padding, $sz);
                                @include breakpoint(xxlarge) {
                                    margin-#{$align}: 10%;
                                }
                            }
                        }
                    }
                    .info {
                        text-align: $align;
                    }
                    cite {
                        text-align: $align;
                    }
                }
                .banner-center-#{$align} > .row > .columns {
                    justify-content: $jc;
                    @each $sz in $breakpoint-classes {
                        @include breakpoint($sz) {
                            .info {
                                padding-#{$center-align}: 0;
                                padding-#{$align}: map_get($banner-padding, $sz);
                            }
                        }
                    }
                    .info {
                        text-align: $center-align;
                    }
                    cite {
                        text-align: $center-align;
                    }
                }
            }
        }
    }
}
.banner-bg-top {
    > .row {
        > .columns {
            > .image_container {
                background-position: top center;
            }
        }
    }
}
.banner-bg-bottom {
    > .row {
        > .columns {
            > .image_container {
                background-position: bottom center;
            }
        }
    }
}
.banner_dual_full-width {
    > .row {
        position: relative;
    }
}
.banner_dual_content {
    padding: 40px;
    @include breakpoint(large) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
    }
    @include breakpoint(xlarge) {
        padding: 60px;
    }
    > .row {
        padding-top: $grid-spacing/3;
        padding-bottom: $grid-spacing/2;
    }
    ul h6 {
        margin-bottom: 1rem;
    }
}
.banner_dual_image {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    z-index: 3;
    float: none;
    img { width: 100%; }
    @include breakpoint(large) {
        float: left;
    }
}
.banner_dual_invert.banner_dual_full-width {
    background-color: $black;
    .banner_dual_content {
        h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
            color: $white;
        }
        a {
            color: $white;
        }
        p {
            color: $medium-gray;
        }
    }
}
.banner_dual_left.banner_dual_full-width {
    .banner_dual_image {
        @extend .large-push-6;
    }
    .banner_dual_content {
        @extend .large-pull-6;
        @include breakpoint(large) {
            position: absolute;
            left: 0;
        }
    }
}
.contact-cta-wrap {
    display: flex;
    align-items: center;
    .phone-num {
        margin-left: 1rem;
    }
}
// No sweat
.sunbelt_nosweat > .row {
    max-height: 420px;
    min-height: initial;
}
.sunbelt_nosweat .button {
    background-color: transparent;
    border: 1px solid $white;
    &:hover {
        background-color: rgba(0,0,0,.15);
    }
}
.sunbelt_nosweat > .row > .columns .image_container {
    background-size: contain;
}
// Fixed CTA  (Contact Us)
.fixed_cta {
    position: absolute;
    background: $white;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
        display: block;
        width: auto;
        top: 10px;
        bottom: auto;
        right: 10px;
        padding: 15px 25px;
    }
    @include breakpoint(large) {       
        top: 33.33%;
        transform: translateY(-33.33%);
        right: $grid-spacing*1.5;
        padding: $grid-spacing/2 $grid-spacing*.75;
    }
    .button {
        margin-bottom: 0;
        margin-right: 1rem;
        display: inline-block;
        @include breakpoint(medium) {
            display: block;
            margin-right: 0;
            margin-bottom: 0.75rem;
        }
    }
    a.phone-num, .hd-5, p {
        display: inline-block;
        color: $body-font-color;
        margin-bottom: 0;
        @include breakpoint(medium) {
            display: block;
        }
    }
}
// Right Aligned CTA  (Contact Us)
.align_right_cta {
    position: absolute;
    background: $white;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
        display: block;
        width: auto;
        top: 10px;
        bottom: auto;
        right: 10px;
        padding: 15px 25px;
        white-space: nowrap;
    }
    @include breakpoint(large) {       
        top: 33.33%;
        padding: $grid-spacing/2 $grid-spacing*.75;
        transform: translate(-100%, -33.33%);
        left: calc(100% - #{$grid-spacing*1.5});
        display: inline-table;
        white-space: nowrap;
    }
    .button {
        margin-bottom: 0;
        margin-right: 1rem;
        display: inline-block;
        @include breakpoint(medium) {
            display: block;
            margin-right: 0;
            margin-bottom: 0.75rem;
        }
    }
    a.phone-num, .hd-5, p {
        display: inline-block;
        color: $body-font-color;
        margin-bottom: 0;
        @include breakpoint(medium) {
            display: block;
        }
    }
}
// Left Aligned CTA  (Contact Us)
.align_left_cta {
    position: absolute;
    background: $white;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
        display: inline-table;
        width: auto;
        top: 10px;
        bottom: auto;
        left: 10px;
        padding: 15px 25px;
        white-space: nowrap;
    }
    @include breakpoint(large) {       
        top: 33.33%;
        padding: $grid-spacing/2 $grid-spacing*.75;
        transform: translate(0, -33.33%);
        left:  $grid-spacing*1.5;
        display: inline-table;
        white-space: nowrap;
    }
    .button {
        margin-bottom: 0;
        margin-right: 1rem;
        display: inline-block;
        @include breakpoint(medium) {
            display: block;
            margin-right: 0;
            margin-bottom: 0.75rem;
        }
    }
    a.phone-num, .hd-5, p {
        display: inline-block;
        color: $body-font-color;
        margin-bottom: 0;
        @include breakpoint(medium) {
            display: block;
        }
    }
}
// Center Aligned CTA  (Contact Us)
.align_center_cta {
    position: absolute;
    background: $white;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
        display: inline-table;
        width: auto;
        top: 10px;
        bottom: auto;
        left: 50%;
        padding: 15px 25px;
        transform: translate(-50%);
        white-space: nowrap;
    }
    @include breakpoint(large) {       
        top: 33.33%;
        padding: $grid-spacing/2 $grid-spacing*.75;
        transform: translate(-50%, -33.33%);
        left:  50%;
        display: inline-table;
        white-space: nowrap;
    }
    .button {
        margin-bottom: 0;
        margin-right: 1rem;
        display: inline-block;
        @include breakpoint(medium) {
            display: block;
            margin-right: 0;
            margin-bottom: 0.75rem;
        }
    }
    a.phone-num, .hd-5, p {
        display: inline-block;
        color: $body-font-color;
        margin-bottom: 0;
        @include breakpoint(medium) {
            display: block;
        }
    }
}
// Contricted Banner Info
.paragraph_limiter {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
