label {
    font-family: $normal-font-family;
    font-stretch:normal;
}
[type='checkbox'], [type='radio'] {
    margin: 0 .65rem;
}
fieldset {
    margin-bottom: 1rem;
}
// Remove spinner from number input
input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}
.input-group-wrap {
    max-width: 705px;
    margin: 0 auto;
    .input-group-label {
      @extend .button;
      display: table-cell;
    }
}
.input-group-button {
    min-width: 40px;
}
.select-language {
    padding-left: 50px;
    & + .icon-globe {
        width: 0px;
        height: 0px;
        top: -51px;
        left: 10px;
        overflow: visible;
        position: relative;
        &:after {
            display: block;
            width: 27px;
            height: 27px;
            content: "";
            position: absolute;
            background-image: url('/content/img/region_selector_icon.svg');
        }
    }
}
.input-collapse {
    @include breakpoint(medium) {
        padding-left: $grid-spacing/4;
        padding-right: $grid-spacing/4;
    }
}
