﻿/*scss variables*/
$color-dark-charcoal: #333333; 

/*mixins*/
@mixin flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


/*styles form location component*/
.checkout-location-selector {
    /*styles for mobile view*/
    @media only screen and (max-width: 767px) {
        .info-text {
            > p {
                width: 90% !important;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }
        }

        .account-selector {
            > div {
                width: 90% !important;
            }
        }

        .jobsite-selector {
            margin: 0px 5% !important;

            > div {
                width: 95% !important;
                margin-left: 0px !important;
            }
        }

        .box-position {
            margin-left: unset;
        }
    }

    @media only screen and (min-width: 768px) {
        .box-position {
            margin-left: 20px;
        }
    }


    .radio-button-container {
        margin: 2px 0px;
    }

    .info-text {
        @include flex-row-center;

        > p {
            width: 43%;
            text-align: center;
            color: $color-dark-charcoal;
            margin-top: 4px;
            margin-bottom: 2px;
        }
    }

    .delivery-address-selector {
        margin-top: 100px;
        > .account-selector-wrapper {
            margin-top: 3em;
            height: 125vh; // this is done so that user gets enough space while selecting jobsite from the dropdown.
            overflow-x: hidden;
            > .account-selector {
                margin-top: 8px;
                margin-bottom: 18px !important;

                > div {
                    width: 43%;
                }
            }
        }
    }

    .jobsite-selector {
        @include flex-row-center;
        margin: 4em 20% 0 20%;
        margin-top:100px;

        > div {
            width: 72%;
            margin-left: 12px;
        }


        .align-jobsite-address {
            @include flex-row-center;
        }

        .action-container {
            @include flex-row-center;

            > a {
                font-size: 14px;
                //Fix for REFSL-528 added style to make Use different location text bold
                font-weight: 600;
                text-decoration: none;
            }

            > span {
                > a {
                    text-decoration: none;
                }
                &:first-child {
                    font-size: 14px;
                }

                &:nth-child(2) {
                    width: 8%;
                }
            }
        }
    }

    .disable-click {
        pointer-events: none;
        opacity: 0.4;
    }
}

.avs-error {
    display: flex;
    flex-direction: row;
    justify-content: center;

    > span {
        font-size: 14px;
        font-weight: normal;
    }
}
