﻿.jobsite-no-permission {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #333333;

    .call-support {
        a {
            color: #333333;
            font-weight: bold;
        }
    }
}

.default-width{
    width: 232px;
}

.jobsite-no-permission-action-button-width{
    width: 25%;
}
