.js-off-canvas-overlay {
    z-index: 99;
}
.disable-click{
    pointer-events:none;
}
#mini-cart,
.shopping {
    display: flex;
    flex-direction: column;
    color: $cm-darkgrey;

    h3,
    h5 {
        text-transform: none;
    }

    a {
        color: #3c76c3;
        text-decoration: none;

        &.yes {
            font-weight: 700;
        }

        &.no {
            color: $dark-gray;
        }
    }

    .d-flex {
        display: flex;
    }

    .renting-font{
        font-size:17px;
    }

    .mini-cart-top {
        .mini-cart-title {
            background-color: $cm-darkgreen;
            padding: 15px;

            h3 {
                color: $cm-white;
                margin: 0;
                font-size: 34px;
            }
        }

        .mini-cart-login {
            background-color: #f3f3f3;
            text-align: center;
            font-size: 11px;
            display: none;

            &.show {
                display: block;
            }

            a {
                line-height: 2;
            }
        }
    }

    .mini-cart-middle {
        overflow: auto;
        padding-bottom: 0px;
        margin-bottom: 277px;
        overflow-x: hidden;
        height: 100%;

        &.estimated-subtotal {
            margin-bottom: 140px;
        }

        @media (max-width: 640px) {
            margin-bottom: 190px;
        }

        .mini-cart-rental-duration {
            padding: 1.5em 0;
            flex: 1;

            > .columns {
                padding: 0 1.42857rem;

                .fix-aligment {
                    margin-left: -0.7rem;
                }

                .change-button {
                    margin-left: auto;
                    font-size: 12px;
                    cursor: pointer;
                    color: #3C76C3;
                }

                .rental-location {
                    line-height: 22px;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.4px;

                    .sub-title {
                        line-height: 25px;
                        font-size: 14px;
                        font-weight: 600;
                        padding-bottom: 5px;
                        font-style: normal;
                        letter-spacing: 0.2px;
                    }
                }

                > .row {
                    margin-right: -1.42857rem;
                    margin-left: -1.42857rem;

                    > .columns {
                        padding-right: 1.42857rem;
                        padding-left: 1.42857rem;
                    }
                }
            }

            [type="text"] {
                margin: 0 0 0.25rem;
            }
        }

        .mini-cart-equipment {
            z-index: 150;

            .columns {
                padding: 0 1.42857rem;

                .empty-cart {
                    font-size: 14px;

                    @media (max-width: 640px) {
                        font-size: 15px;
                    }
                }
            }

            &:before {
                content: none;
            }

            .no-space {
                &:after, &:before {
                    content: none;
                }
            }
        }

        .mini-cart-subtotal {
            padding: 1.5rem 0;

            .columns {
                padding: 0 1.42857rem;
            }
        }
    }

    .mini-cart-bottom {
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.08);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 175;

        .mini-cart-totals {
            background-color: #fff;
            padding: 13px 0 0;

            img.tool-tip {
                display: none;
            }

            div.hide-mobile-mini-cart {
                display: none;

                @include breakpoint(medium) {
                    display: block;
                }
            }

            span.hide-mobile-mini-cart {
                display: none;

                @include breakpoint(medium) {
                    display: inline;
                }
            }

            div.show-mobile-mini-cart-only {
                display: block;

                @include breakpoint(medium) {
                    display: none;
                }
            }

            span.show-mobile-mini-cart-only {
                display: inline;

                @include breakpoint(medium) {
                    display: none;
                }
            }

            div.flexdisplay {
                display: flex;
            }

            @include breakpoint(medium) {
                padding-top: 1em;
            }

            > .columns {
                padding-right: 1.42857rem;
                padding-left: 1.42857rem;

                > .cart-totals {
                    > .row {
                        margin-right: -1.42857rem;
                        margin-left: -1.42857rem;
                    }

                    > .row:not(.mini-cart-subtotals) {
                        padding: 3px 0 2px;
                    }
                }
            }

            button.expanded {
                font-size: 1.25rem;
                line-height: 1.5rem;
                margin-top: 16px;
            }

            button.checkout-disabled {
                background-color: dimgrey;
                letter-spacing: 0.25px;
                line-height: 28px;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
            }

            .iframe-button {
                margin-bottom: 1em !important;
            }
        }
    }

    .mini-cart-loading-screen {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        background-color: rgb(0, 156, 57);
        display: none;

        .inner-spinner-block {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            color: rgb(255, 255, 255);
            margin: 0px auto;
            width: 50px;
        }
    }

    .no-scrollbar {
        overflow-y: hidden !important;
    }

    .cart-totals {
        .footnotes {
            border-top: 1px solid #ccc;
            padding-top: 19px;
        }
    }
}

.takeover {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4000;
    display: none;
    pointer-events: none;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.takeover.takeover-active {
    display: block;
    pointer-events: auto;
}

.takeover .takeover-inner {
    width: 100%;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: none;
}

.takeover .takeover-body {
    width: 1000px;
    max-width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
    background: #fff;
    -webkit-box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.25);
    position: relative;
    margin: 0 10px;
}

@media (min-width: 768px) {
    .takeover .takeover-body {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        margin: 0;

        &.punchout {
            padding-top: 76px;
            padding-bottom: 76px;
        }
    }
}

.takeover-active.takeover .takeover-body {
    pointer-events: auto;
}

.takeover .takeover-header {
    text-align: center;
}

.takeover .takeover-header h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    font-size: 18px !important;
    line-height: 1;
    text-transform: none;
    margin: 0;
}

@media (min-width: 768px) {
    .takeover .takeover-header h1 {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 34px !important;
        font-weight: 700;
    }
}

@media (min-width: 1066px) {
    .takeover .takeover-header h1 {
        font-size: 55px;
    }
}

.takeover .takeover-header p {
    font-size: 14px;
    color: #333;
    margin: 1em 0;
}

@media (min-width: 768px) {
    .takeover .takeover-header p {
        font-weight: 500;
        font-size: 18px;
    }
}

.takeover .takeover-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(51, 51, 51, 0.44);
}

.takeover .takeover-close {
    width: 60px;
    height: 60px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}

.takeover .takeover-close:after,
.takeover .takeover-close:before {
    content: '';
    width: 28px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1.5px 0 0 -14px;
    background: #333;
    display: block;

    @media only screen and (max-width: 767px) {
        width: 18px;
        height: 2px;
        top: 40%;
        left: 65%;
    }
}

.takeover .takeover-close:before {
    -webkit-transform: rotate(-45deg) translateY(0);
    -ms-transform: rotate(-45deg) translateY(0);
    transform: rotate(-45deg) translateY(0);
}

.takeover .takeover-close:after {
    -webkit-transform: rotate(45deg) translateY(0);
    -ms-transform: rotate(45deg) translateY(0);
    transform: rotate(45deg) translateY(0);
}

.takeover-open .off-canvas-wrapper {
    -webkit-filter: none !important;
    filter: none !important;
}

@media (min-width: 992px) {
    .location-form-account {
        margin-top: -70px;
        margin-bottom: -50px;
    }
}

.location-form .field-flex {
    width: 100%;
    max-width: 438px;
    margin: 0 auto;
}


@media only screen and (max-width: 767px) {

    #locationBoxContainer {
        > div.input-container {
            flex-direction: column !important;

            > div.location-field-container {
                width: 100% !important;
                margin: 0% 0% !important;
            }

            div.current-location-selector {
                height: 56px !important;
                margin: 2% 0% !important;
            }

            .location-suggestion-container {
                width: 100% !important;
                margin: 0% !important;
                max-width: 100% !important;
                padding-right: 0px !important;
                position: absolute !important;
                top: 50px !important;
            }
        }

        > div.action-container {
            margin-top: 0% !important;

            > button.location-submit {
                width: 100% !important;
            }
        }
    }
}

#locationBoxContainer {
    display: flex;
    flex-direction: column;
    margin: 2% 0px;
    max-width: 100% !important;

    > div.input-container {
        display: flex;
        flex-direction: row;

        > div.location-field-container {
            width: 70%;
            margin: 0% 2% 0% 6%;
        }

        div.current-location-selector {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-height: 40px;

            img {
                width: 10px;
                height: 16px !important;
                cursor: pointer;
            }

            a{
                text-decoration:none;
            }

            span {
                font-size: 14px;
                color: #008545;
                cursor: pointer;
            }
        }
    }

    > div.action-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 4%;

        > button.location-submit {
            width: 30%;
        }
    }
}

.jobsite-selector-wrapper {
    margin: 2% 6%;

    .action-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        > button {
            width: 30%;
        }
    }
}

.account-selector {
    margin-bottom: 1% !important;
    border: none !important;

    div {
        max-width: 100% !important;
    }
}

.location-form .location-field-container {
    width: 100%;

    .form-error.is-visible {
        margin-top: 0.5rem;
    }
}

.location-form .location-field {
    border: 1px solid #CCCCCC !important;
    height: 48px;
}

.location-form .location-field:focus {
    border: 1px solid #999999 !important;
}

.location-form .location-submit {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .25px;
    text-align: center;
    width: 127px;
    min-width: 127px;
    border: 0;
    border-radius: 0;
    background: #008545;
    padding: 12px 12px;
    margin: 0 !important;
    cursor: pointer;
    height: 48px;

    &:disabled {
        background: #ccc;
    }
}

.diff {
    position:relative;

    .location-suggestion-container {
        width: 100%;
        margin: 0;
        max-width: 100%;
        padding-right: 0;
        position: absolute;
        top: 40px;

    }
}
    .location-suggestion-container {
        width: 80%;
        margin: 0% 2% 0% 6%;
        max-width: 100%;
        padding-right: 92px;
        position: absolute;
        top: 50px;

        &.is-error-visible {
            top: calc(50px + 2rem);
        }

        .default-suggestion {
            background-color: rgb(243, 243, 243);
            border: 1px solid rgb(153, 153, 153);
            padding-left: 10px;
            line-height: 45px;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .location-suggestion-container-account {
        width: 100%;
        max-width: 403px;
        margin: 0 auto;
        margin-top: -3px;
        position: absolute;
        top: 50px;

        &.is-error-visible {
            top: calc(50px + 2rem);
        }
    }

    .location-header-text {
        font-size: 34px;
    }

    .sub-header-text {
        font-size: 24px !important;
        text-align: center;
        color: #222222;
        font-weight: bold;
    }

    .location-header-body {
        font-size: 14px !important;
        font-weight: 500;
        text-align: center;
        color: #333333;
    }

    .vertical-seperator {
        border-left: 1px solid #333333;
        height: 64px;
        width: 1px;
        margin: auto;
    }

    @media (max-width: 991px) {
        .vertical-seperator {
            display: none;
        }
    }

    .location-with-account {
        max-width: 403px;
        margin: auto !important;
    }

    .seperator-text {
        font-weight: bold;
        font-size: 14px !important;
        padding-top: 14px;
        padding-bottom: 14px;
        margin: auto;
        text-align: center;
    }

    @media (max-width: 991px) {
        .seperator-text {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

.location-suggestion-dropdown {
    border: solid 1px;
    border-color: #999999;
    border-top: 2px solid #999999;
    padding-top: 13px;
    padding-left: 10px;
    padding-bottom: 8px;
    background: #FFFFFF;
}

    .suggestion-item {
        margin-bottom: 3px;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        .location-form .location-submit {
            padding: 18px 12px;
        }
    }

    .location-form .field-error {
        color: #C03A3A;
        text-align: center;
        margin-top: 1em;
    }

    .form-fields {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .form-fields .form-field {
        margin: 0;
        padding: 0;
        position: relative;
    }

    .form-fields .form-field#find-equipment-input-container {
        width: auto;
        float: none;
        padding: 0;
    }

    .form-fields .field-flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .form-fields input,
    .form-fields select,
    .form-fields textarea {
        color: #0a0a0a;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        height: auto;
        width: 100%;
        background: #fff;
        margin: 0;
        padding: 12px 12px;
        border: 1px solid #f3f3f3;
        border-radius: 0;
        display: block;
    }

    @media (min-width: 768px) {
        .form-fields input,
        .form-fields select,
        .form-fields textarea {
            padding: 12px 16px;
        }
    }

    .form-fields input:focus,
    .form-fields select:focus,
    .form-fields textarea:focus {
        outline: 0;
        border-color: #f3f3f3;
    }

    .form-fields input::input-placeholder,
    .form-fields select::input-placeholder,
    .form-fields textarea::input-placeholder {
        color: #bbb;
        opacity: 1;
    }

    .form-fields input:focus::input-placeholder,
    .form-fields select:focus::input-placeholder,
    .form-fields textarea:focus::input-placeholder {
        opacity: 0;
    }

    .form-fields input:-moz-placeholder,
    .form-fields select:-moz-placeholder,
    .form-fields textarea:-moz-placeholder {
        color: #bbb;
        opacity: 0;
    }

    .form-fields input:focus:-moz-placeholder,
    .form-fields select:focus:-moz-placeholder,
    .form-fields textarea:focus:-moz-placeholder {
        opacity: 0;
    }

    .form-fields input::-moz-placeholder,
    .form-fields select::-moz-placeholder,
    .form-fields textarea::-moz-placeholder {
        color: #bbb;
        opacity: 1;
    }

    .form-fields input:focus::-moz-placeholder,
    .form-fields select:focus::-moz-placeholder,
    .form-fields textarea:focus::-moz-placeholder {
        opacity: 0;
    }

    .form-fields input:-ms-input-placeholder,
    .form-fields select:-ms-input-placeholder,
    .form-fields textarea:-ms-input-placeholder {
        color: #bbb;
        opacity: 1;
    }

    .form-fields input:focus:-ms-input-placeholder,
    .form-fields select:focus:-ms-input-placeholder,
    .form-fields textarea:focus:-ms-input-placeholder {
        opacity: 0;
    }

    .form-fields input::-webkit-input-placeholder,
    .form-fields select::-webkit-input-placeholder,
    .form-fields textarea::-webkit-input-placeholder {
        color: #bbb;
        opacity: 1;
    }

    .form-fields input:focus::-webkit-input-placeholder,
    .form-fields select:focus::-webkit-input-placeholder,
    .form-fields textarea:focus::-webkit-input-placeholder {
        opacity: 0;
    }

    .form-fields input[type="search"] {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .form-fields textarea {
        overflow: auto;
        vertical-align: top;
        resize: vertical;
        height: auto;
    }

    .form-fields input[type="text"],
    .form-fields input[type="email"],
    .form-fields input[type="search"],
    .form-fields input[type="password"],
    .form-fields input[type="tel"],
    .form-fields textarea {
        -webkit-appearance: none;
    }

    .form-fields .form-dropdown > a {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #333;
        line-height: 43px;
        width: 170px;
        background: #F3F3F3;
        padding: 0 8px 0 0;
        display: block;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 170px;
        flex: 0 0 170px;
    }

    @media (min-width: 768px) {
        .form-fields .form-dropdown > a {
            line-height: 50px;
            padding: 0 8px 0 0;
        }
    }

    .form-fields .form-dropdown > a:after {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        border: 2px solid #333;
        border-width: 0 2px 2px 0;
        border-radius: 2px;
        float: right;
        position: relative;
        left: -16px;
        top: 15px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    @media (min-width: 768px) {
        .form-fields .form-dropdown > a:after {
            top: 18px;
        }
    }


.different-address-input {
    @media (min-width: 768px) {
    padding-right: 1%;
    padding-left: 1%;
    }
}

.different-address-input-zip {
    @media (max-width: 768px) {
        padding-left: 0;
    }
}

#different-address-button {
    margin-top: 25px !important;
    margin-bottom: 10px !important;
    width: 90vw;

    @media (min-width: 768px) {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
        width: 232px;
        margin-bottom: 0 !important;
    }
}
