﻿.snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    width: 360px;
    min-height: 74px;
    height: auto;
    border-radius: 8px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 99999; /* Add a z-index if needed */
    right: 2%; /* Center the snackbar */
    bottom: 70px; /* 70px from the bottom */
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    color: #1C1C1C !important;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.05);
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media only screen and (max-width: 480px) {
        width: 290px;
    }

    &.success {
        border: 2px solid #008545;
    }

    &.warning {
        border: 2px solid #F3A800;
    }

    &.error {
        border: 2px solid #D23B12;
    }

    &.info {
        border: 2px solid #595959;
    }
    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    &.show {
        visibility: visible; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar. However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 4.7s;
        animation: fadein 0.5s, fadeout 0.5s 4.7s;
    }

    .snack-icon {
        color: black;

        img.success {
            width: 37px;
            height: 37px;
        }

        img.info {
            width: 37px;
            height: 37px;
        }

        img.warning {
            width: 37px;
            height: 37px;
        }

        img.error {
            width: 37px;
            height: 37px;
        }
    }

    .snack-content {
        color: #1C1C1C;
        font-size: 14px;
        text-align: left;
        width: 90%;
        padding-left: 15px;
        /*padding-top: 5px;*/
        min-height: 38px;
        height: auto;
        font-family: 'Roboto';
        font-style: NORMAL;
        line-height: 20px;
    }

    .snack-close {
        height: 38px;
        display: flex;
        align-items: flex-start;
        padding-top: 3px;

        img {
            width: 10px;
            height: 10px;
            cursor: pointer;
        }
    }
    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 70px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 70px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 70px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 70px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @media all and (max-width:768px) {

        #snackbar {
            bottom: 100px;
        }
    }
}
