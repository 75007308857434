.mobilenav.off-canvas {
    background-color: $fake-white;
}
.mobile-nav-menu {
    padding-top: 30px;
    > li { // top level nav item
        margin-bottom: 1rem;
        > a {
            @extend .hd-6;
            margin-bottom: 0;
            padding-bottom: 3px;
        }
        &:nth-child(n+3) > a{
            font-size: rem-calc(16);
        }
        a, span {
            padding-left: 2.5rem;
        }
        .small {
            font-family: $body-font-family;
            color: $body-font-color;
            font-weight: $global-weight-normal;
            text-transform: none;
            font-size: $global-font-size;
        }
        > ul > li {
            font-family: $body-font-family;
            font-weight: $global-weight-normal;
            text-transform: none;
            a {
                color: $body-font-color;
            }
        }
        a:focus {
            outline: 0;
            text-decoration: underline;
        }
        .is-submenu-parent-item {
            @extend .hd-5;
            a {
                padding-top: 23px;
                padding-bottom: 15px;
            }
        }
        &.has-dropdown {
            position: relative;
            &:after {
                content: ' ';
                display: block;
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 10px;
                height: 10px;
                border: none;
                border-top: 2px solid $dark-gray;
                border-right: 2px solid $dark-gray;
                transform: rotate(135deg);
                margin-top: -7px;
            }
            &.is-open {
                &:after {
                    margin-top: 0;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    #mobile-nav-locations-link {
        > a {
            position: relative;
            &:before {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            left: 18px;
            height: 24px;
            background-repeat: no-repeat;
            width: 16px;
            margin-left: -9px;
            background-image: url(/content/img/pin-green.svg);
            }
        }
    }
    .js-drilldown-back {
        position: absolute;
        width: 100%;
        > a::before {
            margin-left: -20px;
            margin-top: 15px;
            width: 10px;
            content: "";
            display: block;
            height: 10px;
            border: none;
            border-top: 2px solid #000;
            border-left: 2px solid #000;
            transform: rotate(-45deg);
        }
        @extend .hd-6;
    }
    .mobile-nav-pane {
        display: none;
        background: $white;
        margin-top: 1rem;
        padding: 1rem;
        box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 10px 0;
        &.is-open {
            display: block;
        }
        a, span {
            padding-left: 0;
        }
        .dropdown-menu {
            margin-left: 0;
            margin-bottom: $global-margin;
            > li {
                padding: 7px 0;
                > a {
                    @extend .hd-6;
                }
            }
        }
    }
    li input {
        margin-bottom: $global-margin;
    }
}
.is-submenu-item.is-submenu-header{
    font-stretch: condensed;
    letter-spacing: -0.005em;
    text-transform: uppercase;
    overflow-wrap: break-word;
    font-size: 1.28571rem;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    margin-top:15px;
    a {
        color:#0a0a0a!important;
    }
}
.menu.mobile-nav-menu.drilldown p a.button{
    display:block;
    padding:0.7rem 1rem;
}
.menu.mobile-nav-menu.drilldown p a, .menu.mobile-nav-menu.drilldown .dropdown-menu li{
    line-height: inherit;
    display: inline;
    padding-top: 0;
    padding-bottom: 0;
}
.drilldown a {
    background: none;
}
.mobile-nav-menu.menu > li > a {
    padding-top: 0.85rem;
}