﻿#RolloverForm {
    .leftLabel {
        width: 90px;
        display: inline-block;
    }
    .modal-body {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    #belowGrid {
        padding-top: 5px;
        padding-bottom: 20px;
    }
}
.rollover-form {
    .k-widget {
        width: 100%;
    }
}
#EquipmentRolloverDialogContainer {
    h5 {
        margin-bottom: 2px;
        margin-top: 14px;
    }
    #RolloverForm {
        border-top: 1px solid #d0d0d0;
        padding-top: 5px;
        margin-top: 2px;
    }
    #RolloverGrid {
        margin-bottom: 10px;
    }
    .modal-footer {
        border-top: none;
        margin-top: 15px;
        text-align: right;
        .button {
            margin-bottom: 2px;
        }
    }
}
#WynneModalFooter {
    border-top: none;
    margin-top: 15px;
    text-align: right;
    .button {
        margin-bottom: 2px;
    }
}
#wynneRolloverGrid {
    margin-bottom: 10px;
}
.wynneRolloverLabel {
    width: 90px;
    display: inline-block;
}
.wynneRolloverDateColumn {
    margin-top: 25px;
}
.wynneRolloverSectionHeader {
    margin-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 2px;
}
.wynneRolloverSeperator {
    margin-top: 0px;
    border-bottom: 1px solid #000;
}
.wynneRolloverInput {
    margin-bottom: 0px;
    border-color: #e5e5e5 !important;
    background-color: #fff !important;
}
.wynneRolloverCustomInput {
    margin-bottom: 25px;
    .form-error {
        display: none !important;
    }
}
.wynneFormContainter {
    margin-bottom: 25px;
}
#Requestor-error {
    display: none !important;
}

#Approver-error {
    display: none !important;
}