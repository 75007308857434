﻿.ShadowBoxContent {
    display: block;
    border: 5px solid #ececec;
    padding: 4px 4px 0px 4px;
    overflow: auto;
}
.ShadowBox {
    display: inline-block;
    padding: 1px 1px 14px 1px;
    overflow: auto;
    background-image: url('/Content/images/Shared/ShadowBoxSmall.png');
    background-repeat: no-repeat;
    background-position: center bottom;
}