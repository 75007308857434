﻿#login-modal-container {
    .div-left {
        text-align: left
    }

    .login-options {
        margin-top: 22px;
    }

    .login-input {
        margin-bottom: 16px;
    }

    #cm-submit-button {
        width: 100%;

        @include breakpoint(medium) {
            max-width: 200px;
        }
    }

    .continue-as-guest-container {
        @include breakpoint(small only) {
            text-align: center;
        }

        a {
            text-decoration: none;
            line-height: 50px;
            font-weight: bold;
            font-size: 16px;
            color: #007a39;
        }
    }

    .alignment-text {
        text-align: center;
    }

    .sign-in-margin {
        margin-top: 55px;
    }

    .sign-in-style {
        height: 48px;
        padding-top: 15px;
    }

    .text-email-style {
        font-size: 14px;
        line-height: 23px;
    }
    .decorate-link{
        text-decoration:none;
        .textfont{
            font-size:12px;
        }
    }
    .disable-signin{
        pointer-events:none;
        background: #ccc;        
    }
}
