﻿.menu {
    .active > a {
        background: transparent;
    }
}
#CommandCenter {
    display: table;
    width: 100%;

    .k-tabstrip-top > .k-tabstrip-items {
        border-bottom: 0px;
    }

    .LeftNav {
        background-color: #f2f2f2;
        display: table-cell;
        height: 850px;
        position: relative;
        width: 189px;

        &:before {
            content: "";
            background: rgba(0,0,0,0);
            background: -moz-linear-gradient(left, rgba(0,0,0,0) 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.43) 100%);
            background: -webkit-gradient(left top, right top, color-stop(49%, rgba(0,0,0,0)), color-stop(50%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.43)));
            background: -webkit-linear-gradient(left, rgba(0,0,0,0) 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.43) 100%);
            background: -o-linear-gradient(left, rgba(0,0,0,0) 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.43) 100%);
            background: -ms-linear-gradient(left, rgba(0,0,0,0) 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.43) 100%);
            background: linear-gradient(to right, rgba(0,0,0,0) 49%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.43) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
            height: 100%;
            opacity: 0.06;
            position: absolute;
            right: 0;
            top: 0;
            width: 10px;
        }

        &:after {
            content: "";
            background: rgba(0,0,0,0);
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(50%, rgba(0,0,0,0)), color-stop(50%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1)));
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
            background: -o-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
            background: -ms-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
            height: 10px;
            opacity: 0.2;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 1;
        }

        @media screen and (max-width: $screen-sm-max) {
            display: none;
        }

        .LeftNavToggle {
            background-color: $cm-white;
            border: 1px solid $cc-nav-toggle-border-color;
            border-radius: 100%;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
            height: 32px;
            padding: 7px;
            top: 2rem;
            right: -16px;
            position: absolute;
            width: 32px;
            z-index: 1;

            &:hover {
                background-color: $button-green;
                border-color: transparent;
                box-shadow: none;

                svg {
                    fill: $cm-white;
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }
            }

            &.expanded {
                svg {
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 0;
            margin-top: 50%;
            list-style-type: none;
            padding-left: 0px;

            li {
                height: 40px;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;

                a {
                    align-items: center;
                    color: $cc-leftnav-color;
                    display: flex;
                    font-family: "Roboto";
                    font-size: 14px;
                    font-weight: 400;
                    height: 100%;
                    justify-content: flex-start;
                    line-height: 20px;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    text-decoration: none;

                    svg {
                        fill: $cc-leftnav-color;
                        margin-right: 0.7rem;
                        width: 30%;
                    }
                    span {
                        width: 70%;
                    }
                }

                &.icon-offset {
                    a {
                        span {
                            margin-left: 30%;
                        }
                    }
                }

                &:after {
                    display: block;
                    content: "";
                    height: 3px;
                }
                &.active {
                    a {
                        color: $side-nav-green;
                        border-left: 4px solid $side-nav-green;
                        padding-left: calc(2rem - 4px);

                        svg {
                            fill: $side-nav-green;
                        }
                    }
                }
                &:hover {
                    a {
                        color: $cm-darkgreen;
                        border-left-color: $cm-darkgreen;

                        svg {
                            fill: $cm-darkgreen;
                        }
                    }
                }
                &.active {
                    a {
                        font-weight: 600;
                    }
                }
            }
        }

        img {
            display: block;
            margin-top: 2px;
            margin-bottom: 2px;
        }

        &.collapsed {
            width: 80px;

            ul {
                li {
                    a {
                        justify-content: center;
                        svg {
                            margin-right: 0;
                            width: 70%;
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }

            .NavOnX {
                display: none;
            }

            .LeftNavToggle {
                svg {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
    }

    .Content {
        padding-bottom: 30px;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
        display: table-cell;
        vertical-align: top;

        @media screen and (min-width: $screen-md-min) {
            max-width: calc(100vw - 189px) !important;
        }

        @media screen and (max-width: $screen-sm-max) {
            display: block;
        }

        .MyPageHeader {


            #sbAccountSelector {
                display: none;
                overflow: auto;
                float: right;
                padding-bottom: 18px;

                @media screen and (min-width: $screen-md-min) {
                    display: block;
                }
            }

            h2 {
                display: block;
                float: left;
                padding-left: 0px;

                @media screen and (max-width: $screen-md-max) {
                    margin-bottom: 10px;
                    margin-left: 10px;
                }
            }

            ul.nav-pills {
                float: left;
                letter-spacing: -0.025em;
                font-stretch: condensed;
                text-transform: uppercase;
                font-family: "HelveticaNeue-CondensedBold","HelveticaNeueBoldCondensed","HelveticaNeue-Bold-Condensed","Helvetica Neue Bold Condensed","HelveticaNeueBold","HelveticaNeue-Bold","Helvetica Neue Bold","HelveticaNeue","Helvetica Neue","Roboto Condensed","Helvetica","Arial",sans-serif;
                font-style: normal;
                font-weight: bold;
                color: #0a0a0a;
                text-rendering: optimizeLegibility;
                margin-left: 0px;
            }
        }

        #jeNavDropdown {
            display: block;

            a {
                padding-top: 0px;
                text-decoration: none;
                -webkit-appearance: initial;
                padding-bottom: 5px;
                font-size: 1.14286rem;
            }

            > a {
                font-size: 26px;
            }

            @media screen and (min-width: $screen-lg-min) {
                display: none;
            }
        }

        #jeNavTabs {
            display: none;
            border-bottom: none;

            @media screen and (min-width: $screen-lg-min) {
                display: block;
            }

            > li {
                margin-bottom: -2px;
            }

            li > a {
                margin-right: 0px;
                text-decoration: none;
            }

            li.active {
                > a {
                    border: 1px solid $cc-border-color;
                    border-bottom: 1px solid $cc-grid-background-color;
                    background-color: $cc-grid-background-color;
                    z-index: 3;
                }
            }
        }

        @media screen and (min-width: $screen-md-min) {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 24px;
        }

        .k-grouping-header {
            display: none;

            @media screen and (min-width: $screen-md-min) {
                display: block;
            }
        }
    }

    .nav-tabs {
        border-bottom: 1px solid #ddd;
    }

    .cc-constrain-width {
        @media screen and (min-width: $screen-md-min) {
            max-width: 749px;
        }

        @media screen and (min-width: $screen-lg-min) {
            max-width: 1300px;
            padding-right: 250px;
        }

        @media screen and (min-width: 1600px) {
            max-width: 1400px;
            padding-right: 1px;
        }
    }

    li.telematics-portal {
        float: right;

        span {
            color: #008545;

            &:hover {
                border-color: #fff;
                background-color: #fff;
            }
        }

        &:hover {
            span {
                text-decoration: underline !important;
            }
        }
    }
}
.logo {
    @media screen and (min-width: $screen-md-min) {
        left:-29px;
    }
}
#ReturnEquipmentPartialWrapper {
	.returnqtytb {
		width: 40px;
	}

	#ReturnEquipmentStep2 {
		display: none;
	}

	.k-grid tbody td:first-child {

		-ms-text-overflow: clip;
		-o-text-overflow: clip;
		text-overflow: clip;

	}
}
#ServiceEquipmentPartialWrapper {
	#ServiceEquipmentStep2 {
		display: none;
	}
	#ServiceEquipmentStep3 {
		display: block;
		text-align: center;
		margin-top: 180px;
		#resultText {
			font-size: 18px;
			line-height: 30px;
		}
	}
}
#ExtendEquipmentPartialWrapper {
	#ExtendEquipmentStep2 {
		display: none;
	}
}
.k-window {
    .k-grouping-header {
        display: none;

        @media screen and (min-width: $screen-md-min) {
            display:block;
        }
    }
}
#AdminMenu {
    table {
        margin-bottom: 0px;
    }

    table tbody td {
        padding: 3px;
    }

    table tbody {
        border: 0px;
    }

    table tbody tr {
        border-bottom: 0px;
    }
}

#customer-location-suggestion-container {
    z-index: 1;
    margin-top: 0px;
    position: absolute;
    width: calc( 100% - 60px);
    margin-top: -1.25rem;
    padding-top: 5px;

    @media #{$mobile-wide-max} {
        width: 100%;
        max-width: calc( 100% - 40px);
    }

    .location-suggestion-dropdown {
        border: solid 1px #eee;
        padding-top: 13px;
        padding-left: 10px;
        padding-bottom: 8px;
        background: #FFFFFF;
        border-radius: 8px;
    }

    .suggestion-dropdown {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1C1C1C;
        margin-bottom: 18px;
    }
}