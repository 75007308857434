mark {
    color: $dark-gray;
    background: none;
}
strong {
    color: $dark-gray;
}
.text-small {
    font-size: rem-calc(12);
}
.raised {
    font-size: rem-calc(16);
    @include breakpoint(large) {
        font-size: rem-calc(18);
    }
}
// Blockquotes
blockquote {
    &.large {
        padding-top: $grid-spacing*.75;
        padding-bottom: $grid-spacing;
        @include breakpoint(medium) {
            padding-top: $grid-spacing*1.25;
            padding-bottom: $grid-spacing*1.5;
        }
        @include breakpoint(medium) {
            padding-top: $grid-spacing*2.25;
            padding-bottom: $grid-spacing*2.5;
        }
        margin-bottom: 0;
        p {
            font-size: rem-calc(20);
            line-height: 1.3;
            @include breakpoint(medium) {
                font-size: rem-calc(24);
            }
        }
        cite {
            font-size: rem-calc(16);
            text-align: right;
            @include breakpoint(large) {
                font-size: rem-calc(18);
            }
        }
    }
}
blockquote p {
  font-size: rem-calc(18);
  font-style: italic;
  margin-bottom: 0.65em;
}
blockquote cite {
    font-style: normal;
}
.blockquote_bg {
    background-color: $fake-white;
}

