// Remove firefox "spinner" on number field
input[type=number] {-moz-appearance: textfield;}

// Global Utilities -----------------------------
.sun-clear-all {
    display: block;
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
    clear: both;
}
