﻿.browser-not-allowed {
    img {
        margin: 0 auto;
        display: block;
        width: 208.85px;
        height: 168.46px;
    }
    h1 {
        color: #333333;
        font-family: Roboto;
        font-size: 34px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.25px;
        font-weight: bold;
        text-transform: none;
        width: 100%;
    }
    p {
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        line-height: 23px;
        margin: 0 auto;
        text-align: center;
        letter-spacing: 0.1px;
        width: 645px;
        width: 100%;
    }
}
#browser-modal-check {
    z-index: 9999;
}
 