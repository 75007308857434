.callout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $normal-font-family;
    @each $name, $color in $foundation-palette {
      &.#{$name} a {
        @include callout-style($color);
      }
    }
    p:last-of-type {
        margin-bottom: 0;
    }
    .button {
        margin-bottom: 0;
    }
    &.secondary a.button {
        background-color: $dark-gray;
    }
    > .close-button {
        position: static;
        float: right;
        height: 16px;
        margin-left: 0.75rem;
        &.white {
            color: $white;
        }
        span {
            display: block;
            height: 16px;
        }
    }
} 
