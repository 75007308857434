﻿#continueButtonDiv {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
#linkDiv {
    text-align: justify;
    position: absolute;
    bottom: 24px;
    right: 10%;
    @media (max-width: 800px) {
        display: none;
    }
    a {
        color: #008545;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 23px;
        text-decoration: none;
        margin-right: 40px;
    }
}