﻿/* The container */
.cbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: #fff;
        border: #868686 1px;
        border-style: solid;

        &.chk-disabled {
            background-color: #CCCCCC !important;
            border: 1.8px solid #FFF !important;
            cursor: default;
        }
    }
    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #b4e8cc;
    }
    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: $color-sbgreen;
    }
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }
    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 7px;
        top: 4px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
