﻿.tool-tip-icon{
    width: 16px;
    height: 16px;
    cursor:pointer;
}
.reservation-review-wrapper {
    font-family: Roboto;
    color: #333333;
    margin-top: 3%;

    > .row {
        > .columns {
            .review-header {
                font-family: Roboto Condensed;
                font-weight: bold;
                font-size: 40px;
                line-height: 40px;
                padding-bottom: 2%;
                text-align: left;

                @media only screen and (max-width: 767px) {
                    font-size: 30px;
                    line-height: 30px;
                    text-align: center;
                    margin: 10px 0;
                }
            }

            .subheader {
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
                letter-spacing: 0.2px;
                background-color: #fff;
                margin-bottom: 0;
                text-align: left;
                padding-top:24px;
            }

            .subtitle {
                font-weight: bold;
                font-size: 18px;
                line-height: 32px;
                letter-spacing: 0.2px;
                text-align: left;
            }

            .credit-subtitle {

                font-size: 16px;

            }

            .block-row {
                margin-left: 0;
                margin-right: 0;
                padding-bottom: 25px;

                .display-text {
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.1px;
                    text-align: left;

                    .small-header {
                        font-weight: bold;
                    }
                }

                .directions-text {
                    color: #3C76C3;
                    cursor: pointer;
                    font-size: 14px;
                }
            }
        }
    }

    a.edit-button {
        font-size: 14px;

        &:hover, &:focus {
            color: #3C76C3;
        }
    }
}

.pt-2{
    padding-top: 2rem;
}

.pt-1 {
    padding-top: 1rem;
}
.pt-05{
    padding-top: 0.5rem;
}

.item-image {
    text-align: right;
}

.item-text {
    padding-top: 25px;
}

#review-page-instance .cart-totals {
    .fee-review {
        display: block;
    }

    .fee-complete {
        display: none;
    }
}

#complete-page-instance .cart-totals {
    .fee-review {
        display: none;
    }

    .fee-complete {
        display: block;
    }
}

#complete-page-instance .cart-items {
    .item-complete {
        display: block;
    }

    .item-review {
        display: none;
    }
}

#review-page-instance .cart-totals, #complete-page-instance .cart-totals {
    font-size: 14px;
    line-height: 30px;
    margin-top: 24px;

    @include breakpoint(medium) {
        font-size: 16px;
        line-height: 28px;
    }

    .fee-options {
        margin-top: 16px;
    }

    .required {
        height: 17px;
        width: 59px;
        color: #C03A3A;
        font-family: Roboto;
        font-size: 11px;
        font-style: italic;
        letter-spacing: 0.2px;
        line-height: 18px;
        padding-top: 0px !important;
    }

    .fee-option {
        line-height: 16px;
        margin-bottom: 18px;

        .cbox-container {
            float: left;
        }

        > div {
            display: inline-block;
        }
    }

    .fee-option-info {
        max-width: 120px;

        @include breakpoint(370px) {
            max-width: 190px;
        }

        @include breakpoint(medium) {
            max-width: none;
        }
    }

    .fee-option-info-text {
        height: 18px;
        width: 202px;
        color: #333333;
        font-family: Roboto;
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 18px;
    }

    .blue-text {
        color: #3C76C3;
    }

    .prices-terms {
        height: 38px;
        width: 100%;
        color: #333333;
        font-family: Roboto;
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 18px;
    }

    .terms-text {
        height: 23px;
        width: 296px;
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 23px;

        @media (max-width:378px) {
            margin-top: 15px;
        }
    }

    .margin-top {
        padding-top: 50px !important;
        margin-bottom: 5px !important;
    }

    .small-margin-top {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .estimated-total {
        font-size: 16px;

        @include breakpoint(medium) {
            font-size: 18px;
        }
    }

    .estimated-total,
    .rental-subtotal {
        &::before {
            border-bottom: 1px solid #ccc;
            content: "";
            display: block;
            margin: 20px 0px 18px 0px;
        }
    }

    .loading-placeholder {
        &:nth-child(2) {
            width: 199px;
        }
    }
}

.rental-summary-wrapper {
    @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    }
    .cart-totals {
        padding-top:33px;
    }
}