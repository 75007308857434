﻿.placeholder-content {
    overflow: hidden;
    background: #000;
    position: relative;
    // Animation
    animation-duration: 1.9s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeholderAnimate;
    background: #f6f7f8; // Fallback
    background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
    background-size: 1300px; // Animation Area
    &_item {
        width: 100%;
        height: 20px;
        position: absolute;
        background: #fff;
        z-index: 2;
        &:after,
        &:before {
            width: inherit;
            height: inherit;
            content: '';
            position: absolute;
        }
    }
    &.rate-block {
        height: 18px;
        width: 104px;
        display: inline-block;
        margin-right: 20px;
    }
}
@keyframes placeholderAnimate {
    0% {
        background-position: -650px 0;
    }
    100% {
        background-position: 650px 0;
    }
}
.loading-placeholder {
    background-color: #444;
    opacity: 0.1;
    animation: fading 1.5s infinite;
    border-radius: 6px;
    &.text {
        display: inline-block;
        height: 12px;
        margin: 5px 0;
        min-width: 6px;
    }
}
@keyframes fading {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0.1;
    }
}
