﻿#LocationsSearchBar {
    margin-bottom: 0px;
    border-radius: 0px 4px 0px 0px;
    border-color: $cc-border-color;
    border-style: solid;
    position: relative;
    z-index: 2;
    border-width: 1px;
    background-color: $cc-grid-background-color;
    padding: 12px;
    #nearmetext {
        display: none;
        @media #{$tablet} {
            display: inline;
        }
    }
    #profitCenterMapSearch{
        margin-bottom:0px;
    }
}
