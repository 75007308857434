hr.dark {
    border-color: $dark-gray;
}
.checkout-contentwrap {
    background: linear-gradient(0deg, #f7f7f7, white 6.5%, white);
    padding-bottom: 2.5rem;
    overflow: hidden;
    > .row {
        margin-bottom: $grid-spacing/2;
    }
    fieldset {
        margin-bottom: $grid-spacing/2;
    }
    @include breakpoint(medium) {
        padding-bottom: $grid-spacing*2;
    }
    label > img {
        margin-left: 0.5rem;
        height: 18px;
    }
    label input, label select {
        margin-top: rem-calc(5);
    }
    &--flush {
        padding-bottom: 0;
    }
}
.btn-continue {
    padding-left: $grid-spacing*1.5;
    padding-right: $grid-spacing*1.5;
    &:after {
        display: inline-block;
        content: " ";
        width: 10px;
        height: 10px;
        border: none;
        border-top: 2px solid $white;
        border-right: 2px solid $white;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: relative;
        top: 0;
        right: 0;
        margin-left: 1rem;
    }
}
.checkout-order-summary {
    > .sticky {
        width: 100%;
        padding-top: 1px;
        padding-bottom: 10px;
        padding-left: $grid-spacing;
        padding-right: $grid-spacing;
        @include breakpoint(small only) {
            width: calc(100% + 40px);
            padding-left: $grid-spacing/2;
            padding-right: $grid-spacing/2;
            margin-left: -($grid-spacing/2);
            margin-right: -($grid-spacing/2);
            max-width: none !important;
        }
    }
    .cart-img-wrap {
        max-width: 81px;
        > img {
            max-width: 60px;
        }
    }
    .cart-item .columns:not(:last-child) {
        padding-right: 1rem;
    }
    .minicart-content p {
        color: $dark-gray;
        > a {
            font-size: rem-calc(14);
        }
    }
    margin-bottom: $grid-spacing/2;
}
.checkout-estimatedfees {
    margin-top: $grid-spacing/2;
    p {
        > a {
            font-size: rem-calc(14);
            color: $body-font-color;
            &.text-link {
                margin-left: 0.5rem;
            }
        }
    }
    .has-tip {
        margin-left: 0.25rem;
        > img {
            &:hover {
                opacity: .5;
            }
        }
    }
    @include breakpoint(large) {
        margin-top: $grid-spacing;
        p {
            font-size: rem-calc(18);
        }
    }
}
.pc-update-alert {
    background-color: #fffbeb;
    width: fit-content;
    padding: 20px;
     margin-left: -20px;
}
.pc-update-alert-notification {
   background-color: #fef4c6;
    width: 100%;
    display: block;
    text-align: center;
   padding: 6px;
}
#CheckoutWhen .sb-error-color {
    color: red;
    font-size: 10px;
}